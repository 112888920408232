import React, { useState } from 'react';
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { useEffect } from 'react';
import { useRef } from 'react';
import { PatientPortalPinInpContext } from '../../Context/Authentication/PatientPortalPinInpContext';
import { useContext } from 'react';
import { PatientPortalBaseUrlContext } from '../../Context/PatientPortalBaseUrlContext';
import Cookies from 'js-cookie';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { AuthContext } from '../../Context/Authentication/AuthContext';
import { SaveUserDataContext } from '../../Context/Authentication/SaveUserDataContext';
import { LocalizationContext } from '../../Context/LocalizationContext';
import Joi from 'joi';
import Form from 'react-bootstrap/Form';
import PatientPortalChangePasswordModale from '../../Context/Authentication/PatientPortalChangePasswordModale';
export default function Portal() {
    let { baseUrl, setBaseUrl } = useContext(PatientPortalBaseUrlContext)
    const { pinInp, setPinInp, getsummary, vitalSummary, setVitalSummary } = useContext(PatientPortalPinInpContext);
    const { userData, setUserData } = useContext(AuthContext)
    const { saveUserData } = useContext(SaveUserDataContext);
    /// To disable a button while waiting for an OTP (One-Time Password) to be sent to a phone number,
    // and then enable it once the message is sent, 
    const [isSending, setIsSending] = useState(false); // State to track whether the message is being sent
    function logout() {
        localStorage.removeItem("patientPortalToken");
        localStorage.removeItem("pinNumber");
        navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}`);
        closeNavbar();
    }
    const handleSendOTP = () => {
        setIsSending(true);
        checkNumber();

        // Simulating an asynchronous API call to send the OTP message
        setTimeout(() => {
            // After a delay, when the OTP message is sent
            setIsSending(false);

            // Additional logic for handling the OTP message being sent
        }, 60000); //only one minute
    };
    // end disable
    let input1Ref = useRef(null);
    let input2Ref = useRef(null);
    let input3Ref = useRef(null);
    let input4Ref = useRef(null);
    let combinedInputRef = useRef(null);
    const [combinedValue, setCombinedValue] = useState('');

    const handleKeyDown = (event, ref) => {
        if (event.key === 'Backspace' && ref && ref.current) {
            const previousRef = getPreviousRef(ref);
            if (previousRef) {
                event.preventDefault(); // Prevents the browser from navigating back
                previousRef.current.focus();
                // previousRef.current.value = '';
            }
        } else if (event.key !== 'Backspace' && event.key !== 'ArrowLeft' && ref && ref.current && ref.current.value.length >= 1) {
            const nextRef = getNextRef(ref);
            if (nextRef) {
                // event.preventDefault(); // Prevents the browser from moving the cursor
                nextRef.current.focus();
            }
        }
        // handleInputChange();
    };

    const getNextRef = (currentRef) => {
        if (currentRef === input1Ref) {
            return input2Ref;
        } else if (currentRef === input2Ref) {
            return input3Ref;
        } else if (currentRef === input3Ref) {
            return input4Ref;
        }
        return input4Ref; // No next input field
    };

    const getPreviousRef = (currentRef) => {
        if (currentRef === input2Ref) {
            input2Ref.current.value = '';
            return input1Ref;
        } else if (currentRef === input3Ref) {
            input3Ref.current.value = '';

            return input2Ref;
        } else if (currentRef === input4Ref) {
            input4Ref.current.value = '';

            return input3Ref;
        }
        input1Ref.current.value = '';
        return input1Ref; // No previous input field

    };
    let [phoneNumX5Inp, setPhoneNumX5Inp] = useState('')
    // const combinedInputRef = useRef(null);
    // const [combinedValue, setCombinedValue] = useState('');
    const handleInputChange = () => {

        // const { name, value } = e.target;
        // const mergedValue = phoneNumX5Inp + value;

        // setPhoneNumX5Inp(mergedValue);
        const input1Value = input1Ref.current.value;
        const input2Value = input2Ref.current.value;
        const input3Value = input3Ref.current.value;
        const input4Value = input4Ref.current.value;

        const phoneNumX5Inp = `${input1Value}${input2Value}${input3Value}${input4Value}`;
        setPhoneNumX5Inp(phoneNumX5Inp);

        if (combinedInputRef.current) {
            combinedInputRef.current.value = phoneNumX5Inp;
        }

        if (input1Value.length > 0) {
            // input2Ref.current.focus();
            return input2Ref;
        } else if (input2Value.length > 0) {
            // input3Ref.current.focus();
            return input3Ref;

        } else if (input3Value.length > 0) {
            // input4Ref.current.focus();
            return input4Ref;
        }

        // const myUserPhone = { ...formPhoneData };
        // myUserPhone.numX1 = e.target.value;
        // myUserPhone.numX2 = e.target.value;
        // myUserPhone.numX3 = e.target.value;
        // myUserPhone.numX4 = e.target.value;

        // setPinInp(myUserPhone.pin);

        // console.log(pinInp);
        // setPhoneNumX1Inp(myUserPhone.numX1);
        // setPhoneNumX2Inp(myUserPhone.numX2);
        // setPhoneNumX3Inp(myUserPhone.numX3);
        // setPhoneNumX4Inp(myUserPhone.numX4);
        // const allNum = phoneNumX1Inp + phoneNumX2Inp + phoneNumX3Inp + phoneNumX4Inp;
        // console.log(allNum);
        // setPhoneNumX5Inp(allNum);
        // setPhoneNumX1Inp(myUser.numX1)
        // setPhoneNumX2Inp(myUser.numX2)
        // setPhoneNumX3Inp(myUser.numX3)
        // setPhoneNumX4Inp(myUser.numX4)
        // console.log(phoneNumX1Inp);
        // console.log(phoneNumX2Inp);
        // console.log(phoneNumX3Inp);
        // console.log(phoneNumX4Inp);
        // console.log(phoneNumX5Inp);
        console.log(phoneNumX5Inp);
        // }
    };


    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({ pin: '' });
    const [formPhoneData, setFormPhoneData] = useState({ numX1: '', numX2: '', numX3: '', numX4: '' });
    const [formCodeData, setFormCodeData] = useState({ code: '' });


    const handleNextStep = () => {
        // if (pinError === true) {
        //     setStep(1);

        // } else {
        //     setStep(step + 1);

        if (pinError) {
            portals()
            // CheckPin();

        } else {
            portalsPin();
            CheckPin();

        }
    };

    const handlePreviousStep = () => {
        setStep(step - 1);
    };



    const typistRef = useRef(null);
    const navigate = useNavigate();
    let { lang } = useParams();
    const { pathname } = useLocation();
    // window.alert(`${lang}`);

    let { t, i18n, handleLanguageChange } = useContext(LocalizationContext)
    const changeLanguage = (lang, dir) => {
        document.getElementsByTagName("html")[0].setAttribute("lang", lang);
        document.getElementsByTagName("html")[0].setAttribute("dir", dir);
    }

    // let { lang } = useParams();
    // console.log(lang);


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalShow, setModalShow] = React.useState(false);

    const [complain, setComplains] = useState(false);
    const complainClose = () => setComplains(false);
    const complains = () => setComplains(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    // const history = useHistory();
    const [portal, setPortals] = useState(false);
    const portalClose = () => {
        setPortals(false);
        setPinError(false);
        // setStep(1);
        setFormData({ pin: '' });
        setPinInp('')
    }

    const portals = () => {
        if (localStorage.getItem("patientPortalToken") !== null) {
            navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal`); // Replace '/your-link' with the desired link
            getsummary();
        } else {
            navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}`); // Replace '/your-link' with the desired link

            setFormData({ pin: '' });
            setPinInp('')
            setPinError(false);
            setPortals(true)
        }




    };
    // const token = localStorage.getItem('patientPortalToken');

    const portalsNavigate = () => {

        // navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal`); // Replace '/your-link' with the desired link


    }

    const [portalPin, setPortalsPin] = useState(false);
    const portalPinClose = () => {
        setPortalsPin(false);
        setPhoneError(false);
        setFormPhoneData({ numX1: '', numX2: '', numX3: '', numX4: '' });
        setPhoneNumX5Inp('')
        // setStep(1);
        // setFormData({ pin: '', num1: '', num2: '', num3: '', num4: '', num5: '', num6: '', num7: '', numX1: '', numX2: '', numX3: '', numX4: ''});
    }

    const portalsPin = () => {
        setPhoneError(false);
        setFormPhoneData({ numX1: '', numX2: '', numX3: '', numX4: '' });
        setPhoneNumX5Inp('')
        setPortalsPin(true);

    }

    // const [codeError, setCodeError] = useState(false);

    const [portalPhone, setPortalsPhone] = useState(false);
    const portalPhoneClose = () => {
        setPortalsPhone(false);
        setCodeError(false);
        setFormData({ code: '' });
        setCodeInp('')

    }
    const portalsPhone = () => {
        setPortalsPhone(true);
        setCodeError(false);
        setFormPhoneData({ code: '' });
        setCodeInp('')
    }

    // const [portalModalShow, setPortalModalShow] = React.useState(false);
    const [startDelay, setStartDelay] = useState(1000);
    const [backDelay, setBackDelay] = useState(1000);
    let [textIndex, setTextIndex] = useState(0);
    const next = () => {
        setTextIndex(textIndex + 1);
    }

    // $(document).ready(function () {
    //     wordflick();
    // });
    function generalNavbar() {

        document.getElementById('normal-nav').classList.remove('d-none');
        document.getElementById('patient-portal-nav').classList.add('d-none');
        document.getElementById('alex-nav').classList.add('d-none');
        document.getElementById('cairo-nav').classList.add('d-none');

    }
    function navbar() {
        portalClose();
        document.getElementById('patientPortalModale').classList.add('d-none');
        document.getElementById('normal-nav').classList.add('d-none');
        document.getElementById('patient-portal-nav').classList.remove('d-none');
        document.getElementById('alex-nav').classList.add('d-none');
        document.getElementById('cairo-nav').classList.add('d-none');
        document.getElementById('referTo').classList.remove('d-none');
    }
    function referToHome() {
        navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}`)

        document.getElementById('normal-nav').classList.remove('d-none');
        document.getElementById('alex-nav').classList.add('d-none');
        document.getElementById('cairo-nav').classList.add('d-none');
        document.getElementById('referTo').classList.add('d-none');
        document.getElementById('patient-portal-nav').classList.add('d-none');

    }
    function cairoNavbar() {
        // <Navigate to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo`} />
        document.getElementById('normal-nav').classList.add('d-none');
        document.getElementById('alex-nav').classList.add('d-none');
        document.getElementById('cairo-nav').classList.remove('d-none');
        document.getElementById('referTo').classList.remove('d-none');
        document.getElementById('patient-portal-nav').classList.add('d-none');
        closeNavbar()
    }
    function alexNavbar() {
        document.getElementById('normal-nav').classList.add('d-none');
        document.getElementById('cairo-nav').classList.add('d-none');
        document.getElementById('alex-nav').classList.remove('d-none');
        document.getElementById('referTo').classList.remove('d-none');
        document.getElementById('patient-portal-nav').classList.add('d-none');
        closeNavbar()

    }

    useEffect(() => {


        if (i18n.language === 'ar') {

            changeLanguage('ar', 'rtl')
            Cookies.set('i18next', 'ar')

        } else {
            changeLanguage('en', 'ltr')
            Cookies.set('i18next', 'en')

        }

        // window.alert(`${lang}`)
        // if (pathname.includes('/en')) {
        //     // Cookies.set('i18next', 'en')


        //     // changeLanguage('en', 'ltr')
        //     document.getElementsByTagName("html")[0].setAttribute("lang", 'en');
        //     document.getElementsByTagName("html")[0].setAttribute("dir", 'ltr');
        //     // i18n.language = 'en';


        // } else {
        //     // Cookies.set('i18next', 'ar')
        //     // changeLanguage('ar', 'rtl')
        //     document.getElementsByTagName("html")[0].setAttribute("lang", 'ar');
        //     document.getElementsByTagName("html")[0].setAttribute("dir", 'rtl');
        //     // i18n.language = 'ar';

        // }


    }, [Navigate, navigate]);

    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        closeNavbar()
    }
    useEffect(() => {


        if (pathname.includes('alex')) {
            document.getElementById('normal-nav').classList.add('d-none');
            document.getElementById('cairo-nav').classList.add('d-none');
            document.getElementById('alex-nav').classList.remove('d-none');
            document.getElementById('referTo').classList.remove('d-none');
            document.getElementById('patient-portal-nav').classList.add('d-none');



        } else if (pathname.includes('cairo')) {
            document.getElementById('normal-nav').classList.add('d-none');
            document.getElementById('cairo-nav').classList.remove('d-none');
            document.getElementById('alex-nav').classList.add('d-none');
            document.getElementById('referTo').classList.remove('d-none');
            document.getElementById('patient-portal-nav').classList.add('d-none');



        } else if (pathname.includes('patient-portal')) {
            document.getElementById('normal-nav').classList.add('d-none');
            document.getElementById('cairo-nav').classList.add('d-none');
            document.getElementById('alex-nav').classList.add('d-none');
            document.getElementById('referTo').classList.remove('d-none');
            document.getElementById('patient-portal-nav').classList.remove('d-none');


        }
        else {
            document.getElementById('normal-nav').classList.remove('d-none');
            document.getElementById('cairo-nav').classList.add('d-none');
            document.getElementById('alex-nav').classList.add('d-none');
            document.getElementById('referTo').classList.add('d-none');
            document.getElementById('patient-portal-nav').classList.add('d-none');


        }




        // window.addEventListener("popstate", reloadPage); // add event listener for the popstate event
        // return () => {
        //     window.removeEventListener("popstate", reloadPage); // remove event listener when component unmounts
        // };


    }, [pathname]);

    // function reloadPage() {

    //     window.location.reload(); // reload the page
    // }
    // useEffect(() => {
    //     const handlePopstate = () => {
    //         window.location.reload();
    //         // i18n.language = Cookies.get('i18next');

    //     };

    //     window.addEventListener('popstate', handlePopstate);

    //     return () => {
    //         window.removeEventListener('popstate', handlePopstate);
    //     };
    // }, [])
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsHeaderFixed(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        if (pathname.includes('/en')) {
            // Cookies.set('i18next', 'en')

            // i18n.language = Cookies.get('i18next');

            // changeLanguage('en', 'ltr')
            document.getElementsByTagName("html")[0].setAttribute("lang", 'en');
            document.getElementsByTagName("html")[0].setAttribute("dir", 'ltr');


        } else {
            // i18n.language = Cookies.get('i18next');

            // Cookies.set('i18next', 'ar')
            // changeLanguage('ar', 'rtl')
            document.getElementsByTagName("html")[0].setAttribute("lang", 'ar');
            document.getElementsByTagName("html")[0].setAttribute("dir", 'rtl');


        }


        // fixed hedader when scroll down




    }, [])

    // axios for check pin
    const [patientPhoneFirst, setPatientPhoneFirst] = useState();
    const [patientPhoneLast, setPatientPhoneLast] = useState();
    const [pinError, setPinError] = useState(false);
    // const [pinInp, setPinInp] = useState({});
    const [phoneInp, setPhoneInp] = useState();
    //new 
    const [errorList, setErrorList] = useState([]);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [pin, setPin] = useState({
    //     pin: ''
    // });

    const [phoneNumX1Inp, setPhoneNumX1Inp] = useState('');
    const [phoneNumX2Inp, setPhoneNumX2Inp] = useState('');
    const [phoneNumX3Inp, setPhoneNumX3Inp] = useState('');
    const [phoneNumX4Inp, setPhoneNumX4Inp] = useState('');


    const handleChange = (e) => {

        let myUser = { ...formData };
        myUser.pin = e.target.value;

        setPinInp(myUser.pin);

        console.log(pinInp);

    };
    // const handlePhoneChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    //     setPhoneInp(value);
    //     console.log('phoooooone' + phoneInp);
    // };


    // validate pin number
    function validationPinSchema() {
        let scheme = Joi.object({
            pinInp: Joi.number().integer().positive().required().min(0).max(10)
        });
        return scheme.validate(pinInp, { abortEarly: false });
        //  /   console.log(pinInp);

    }
    async function CheckPin() {
        // Request body
        // const requestBody = {
        //     'pin': 472506
        // }

        const bodyFormData = new FormData();

        // Append data to the FormData object
        bodyFormData.append('pin', pinInp);
        console.log(pinInp);

        const headers = {
            'Content-Type': 'multipart/form-data',
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
        };
        // Make the API request with the request body

        // headers removed
        // {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //         'fingerprint': localStorage.getItem('fingerPrint'),

        //     },
        // }

        await axios.post(`${baseUrl}/checkpin`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    setPatientPhoneFirst(response.data.Edata.firstd)
                    setPatientPhoneLast(response.data.Edata.lastd)
                    console.log(response.data.Emsg);
                    console.log(response.data.Edata.firstd);
                    console.log(response.data.Edata.lastd);
                    setShowForgetPinModal(false);
                    setPinError(false);
                    setPortalsPhone(false);
                    setPortals(false);
                    setPortalsPin(true);
                    setIsLoading(false);
                    localStorage.setItem('pinNumber', pinInp);


                } else {
                    setShowForgetPinModal(false);
                    setIsLoading(true);
                    setPortalsPhone(false);
                    setPortalsPin(false);
                    setPortals(true);
                    setPinError(response.data.Emsg)
                    // pinInp()
                    setPinInp('')


                }
            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });
        // let { data } = await axios.post(`${baseUrl}/checkpin`);
        // callback(data.Edata);
        // // setLoading(false);
        // // console.log('videos by chars');

        // console.log(data.Edata);
    }

    // forget pin number
    const [userIdInp, setUserIdInp] = useState();
    const [userPhoneInp, setuserPhoneInp] = useState();
    const [pinForgetMsgError, setPinForgetMsgError] = useState();
    async function forgetPinNumber(e) {
        e.preventDefault();
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('pin', '0');
        bodyFormData.append('phone', userPhoneInp);
        bodyFormData.append('userid', userIdInp);
        // bodyFormData.append('branch', 'cairo');

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`http://130.7.1.27/mayo_test/public/patientportal/booking/checkpatientphone`, bodyFormData)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 202) {
                    // setCheckPhoneNum(response.data.Emsg);
                    // setPinForgetMsg(response.data.Emsg)
                    console.log('handleOpenForgetPinModal ' + response.data);
                    console.log('handleOpenForgetPinModal ' + response.data.Emsg);
                    setPinForgetMsg(response.data.Emsg)
                    setPortals(true);
                    setPinError(false);
                    setPortalsPhone(false);
                    setShowForgetPinModal(false);


                } else {
                    setPinForgetMsgError(response.data.Emsg)
                    setShowForgetPinModal(true);
                    setPortalsPhone(false);
                    setPortalsPin(false);
                    setPortals(false);
                    setPinForgetMsg('')
                    // pinInp()
                }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }
    const [pinForgetMsg, setPinForgetMsg] = useState();
    const [showForgetPinModal, setShowForgetPinModal] = useState(false);

    const handleForgetPin = () => {
        setPortals(false);

        setShowForgetPinModal(true)
    }
    function submitLoginForm(e) {
        e.preventDefault();
        CheckPin();

        // setIsLoading(true);

        // let validation = validationPinSchema();
        // if (pinError) {
        //     setIsLoading(false);
        //     setErrorList(validation.error.details);

        // } else {

        //     CheckPin();
        //     setIsLoading(false);

        // }
        // if (pinInp) {
        //     CheckPin();
        // }
    }
    const handleCloseForgetPinModal = () => {
        setShowForgetPinModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    // validate phone number
    function validationPhoneSchema() {
        let scheme = Joi.object({
            phoneNumX1Inp: Joi.number().integer().positive().required().min(1).max(1),
            phoneNumX2Inp: Joi.number().integer().positive().required().min(1).max(1),
            phoneNumX3Inp: Joi.number().integer().positive().required().min(1).max(1),
            phoneNumX4Inp: Joi.number().integer().positive().required().min(1).max(1),
        });
        console.log(phoneNumX5Inp);
        return scheme.validate(phoneNumX1Inp, phoneNumX2Inp, phoneNumX3Inp, phoneNumX4Inp, { abortEarly: false });


    }
    function submitPhoneNumberForm(e) {
        e.preventDefault();
        checkNumber();

        setIsLoading(true);

        // let validation = validationPhoneSchema();
        // if (phoneError) {
        //     setIsLoading(false);
        //     setPortalsPhone(false);
        //     setPortalsPin(true);
        //     setErrorList(validation.error.details);

        // } else {

        //     checkNumber();
        //     setIsLoading(false);

        // }
        // if (phoneNumX5Inp) {
        //     checkNumber();
        // }
    }
    let [phoneError, setPhoneError] = useState(false);
    async function checkNumber() {
        // Request body
        // event.preventDefault(); // Prevents the browser from navigating back

        // if (event.target.value) {
        const headers = {
            'Content-Type': 'multipart/form-data',
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
        };
        const bodyFormData = new FormData();

        // Append data to the FormData object
        bodyFormData.append('pin', pinInp);
        bodyFormData.append('mnumber', phoneNumX5Inp);

        await axios.post(`${baseUrl}/checknumber`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {

                    console.log(response.data.Emsg);
                    setPhoneError(false);
                    setPortalsPhone(true);
                    setPortals(false);
                    setPortalsPin(false);
                    setIsLoading(false);
                    setShowForgetPinModal(false);



                } else {
                    setPhoneError(response.data.Emsg);
                    setShowForgetPinModal(false);

                    setIsLoading(true);
                    setPortalsPhone(false);
                    setPortalsPin(true);
                    setPortals(false);
                    setPinError(response.data.Emsg)
                    // pinInp()
                    // setPinInp({})
                    setPhoneNumX5Inp('');



                }
                console.log('phoooooone' + phoneNumX5Inp);

            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });

        console.log('phoooooone' + phoneNumX5Inp);

        // }
    }

    //  code function 
    const [codeInp, setCodeInp] = useState({});

    const handleCodeChange = (e) => {

        let myUserCode = { ...formCodeData };
        myUserCode.code = e.target.value

        setCodeInp(myUserCode.code);

        console.log(codeInp);

    };
    // useEffect(() => {

    // }, []);
    const [codeError, setCodeError] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        checkCode();

        // if (codeError) {
        //     setIsLoading(false);
        //     setPortalsPin(false);
        //     setPortals(false);
        //     setPortalsPhone(true)
        // } else {

        //     checkCode();
        //     setIsLoading(false);

        // }
        // if (codeInp) {
        //     checkCode();
        // }
        // Handle form submission

        // <Navigate to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal/patient_portal_home`} />
    };
    async function checkCode() {
        const bodyFormData = new FormData();
        const phone = `${patientPhoneFirst}${phoneNumX5Inp}${patientPhoneLast}`;
        // Append data to the FormData object
        bodyFormData.append('pin', pinInp);
        bodyFormData.append('otpcode', codeInp);
        bodyFormData.append('phone', phone);
        console.log(phone);
        const headers = {
            'Content-Type': 'multipart/form-data',
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
        };
        await axios.post(`${baseUrl}/checkcode`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    if (response.data.Edata.user.password_reset === 1) {
                        changePassword();
                        localStorage.setItem("patientPortalToken", response.data.Edata.token);
                        console.log('userdata ' + userData);
                    }
                    else {
                        handlePortalChangePasswordClose();
                        localStorage.setItem("patientPortalToken", response.data.Edata.token);
                        console.log('userdata ' + userData);

                        navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal`)
                        getsummary();

                        console.log(response.data.message);
                        setCodeError(false);
                        setPortalsPhone(false);
                        setPortals(false);
                        setPortalsPin(false);
                        setIsLoading(false);
                        setShowForgetPinModal(false);

                        // portalClose();
                        // portalPhoneClose();
                        document.getElementById('patientPortalModale').classList.add('d-none');
                        document.getElementById('patientportalPinModale').classList.add('d-none');
                        document.getElementById('patientportalPhoneModale').classList.add('d-none');
                        document.getElementById('normal-nav').classList.add('d-none');
                        document.getElementById('patient-portal-nav').classList.remove('d-none');
                        document.getElementById('alex-nav').classList.add('d-none');
                        document.getElementById('cairo-nav').classList.add('d-none');
                        document.getElementById('referTo').classList.remove('d-none');
                        // console.log(formData);
                        // saveUserData();

                    }
                } else if (response.data.Ecode === 401) {
                    navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}`)

                } else {
                    setShowForgetPinModal(false);

                    setPhoneError(response.data.Emsg);
                    setCodeError(response.data.message);
                    setIsLoading(true);
                    setPortalsPhone(true);
                    setPortalsPin(false);
                    setPortals(false);
                    setPinError(response.data.Emsg)
                    // setCodeInp('');



                }
                // console.log('phoooooone' + phoneNumX5Inp);

            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });

        console.log('phoooooone' + phoneNumX5Inp);


    }


    // change password 

    const [portalChangePassword, setPortalChangePassword] = useState(false);
    const portalChangePasswordClose = () => {
        setPortalChangePassword(false);
        setCodeError(false);
        setFormData({ code: '' });
        setCodeInp('')

    }
    const portalChangePasswordOpen = () => {
        setPortalChangePassword(true);

    }
    const [changePasswordError, setChangePasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfimedPassword, setShowConfimedPassword] = useState(false);



    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleOldPasswordVisibility = () => {
        setShowOldPassword(!showOldPassword);
    };
    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };
    const toggleConfirmedPasswordVisibility = () => {
        setShowConfimedPassword(!showConfimedPassword);
    };
    // const [passwordError, setPasswordError] = useState('');/
    const isStrongPassword = (password) => {
        // Password rules: at least 8 characters, one uppercase letter, one lowercase letter, one digit, and one special character
        // const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])[A-Za-z0-9]{8,16}$/;
        const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[$!#%*()_\-@]).{8,}$/;

        return passwordRegex.test(password);
    };
    const handleChangePasswordSubmit = (e) => {
        e.preventDefault();
        if (newPasswordInp !== confirmationPasswordInp) {
            setChangePasswordError('New password and confirm password must match.');
        } else if (newPasswordInp === oldPasswordInp) {
            setChangePasswordError('New password must be different from the old password.');
        } else if (!isStrongPassword(newPasswordInp)) {
            setChangePasswordError('The password must be at least 8 characters long.,At least one digit (0-9).,At least one uppercase letter (A-Z) and At least one special character of the following [$, !, #, %, *, (, ), _, -, or @].');
        } else {
            changePassword()
        }
    }
    const [oldPasswordInp, setOldPasswordInp] = useState('');
    const [newPasswordInp, setNewPasswordInp] = useState('');
    const [confirmationPasswordInp, setConfirmationPasswordInp] = useState('');
    async function changePassword() {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('old_password', oldPasswordInp);
        bodyFormData.append('new_password', newPasswordInp);
        // bodyFormData.append('confirmation_password', confirmationPasswordInp);

        const headers = {
            'Content-Type': 'multipart/form-data',
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
            'token': localStorage.getItem('patientPortalToken'),
        };
        await axios.post(`${baseUrl}/changepassword`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {

                    // localStorage.setItem("patientPortalToken", response.data.Edata.token);
                    // console.log('userdata ' + userData);
                    handlePortalChangePasswordClose();
                    navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal`)
                    getsummary();

                    console.log(response.data.Emsg);
                    setChangePasswordError(false);
                    setCodeError(false);
                    setPortalsPhone(false);
                    setPortals(false);
                    setPortalsPin(false);
                    setIsLoading(false);
                    setShowForgetPinModal(false);

                    // portalClose();
                    // portalPhoneClose();
                    document.getElementById('patientPortalModale').classList.add('d-none');
                    document.getElementById('patientportalPinModale').classList.add('d-none');
                    document.getElementById('patientportalPhoneModale').classList.add('d-none');
                    document.getElementById('normal-nav').classList.add('d-none');
                    document.getElementById('patient-portal-nav').classList.remove('d-none');
                    document.getElementById('alex-nav').classList.add('d-none');
                    document.getElementById('cairo-nav').classList.add('d-none');
                    document.getElementById('referTo').classList.remove('d-none');





                } else {

                    setChangePasswordError(response.data.Emsg);
                    setCodeError(response.data.message);
                    setIsLoading(true);
                    setPortalsPhone(false);
                    setPortalChangePassword(true)
                    setPortalsPin(false);
                    setPortals(false);
                    setPinError(response.data.Emsg)
                    // setCodeInp('');
                    setShowForgetPinModal(false);



                }
                // console.log('phoooooone' + phoneNumX5Inp);

            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });

        console.log('phoooooone' + phoneNumX5Inp);


    }
    const handlePortalChangePasswordClose = () => {
        setPortalChangePassword(false);
        setOldPasswordInp('');
        setNewPasswordInp('');
        setConfirmationPasswordInp('');
    };
    // function confirmPhoneNumber() {
    //     setPortalsPhone(true)
    //     checkNumber();
    // }

    const token = localStorage.getItem('patientPortalToken');
    useEffect(() => {
        // CheckPin();
        // console.log(localStorage.getItem('fingerPrint'));

        // Remove data after one hour
        const timeoutId = setTimeout(() => {
            if (token) {
                localStorage.removeItem('patientPortalToken');
            }
        }, 60 * 60 * 1000); // 1 hour in milliseconds

        // Clean up the timeout when the component unmounts or when the data is removed manually
        return () => clearTimeout(timeoutId);

    }, [token])

    // profile portal

    // let [vitalSummary, setvitalSummary] = useState([]);


    // async function getsummary() {
    //     // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
    //     const bodyFormData = new FormData();
    //     // Append data to the FormData object
    //     bodyFormData.append('pin', pinInp);

    //     const headers = {
    //         'Content-Type': 'multipart/form-data',

    //         'fingerprint': localStorage.getItem('fingerPrint'),
    //         'token': localStorage.getItem('patientPortalToken'),
    //     };
    //     await axios.post(`${baseUrl}/patientportal/getsummery`, bodyFormData, {headers})
    //         .then(response => {
    //             // Handle the response
    //             setvitalSummary(response.data.Edata.vital);
    //             console.log(response.data.Edata.vital);
    //         })
    //         .catch(error => {

    //             // Handle any errors
    //             console.error(error);
    //         });

    //     // calcBmi();
    // }

    // useEffect(() => {
    //     if (portal === true) {
    //         getsummary();
    //     }
    // }, [portals])

    // close nav when click a link

    const [isNavbarOpen, setIsNavbarOpen] = useState(false);


    const toggleNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };
    const closeNavbar = () => {
        setIsNavbarOpen(false);
    };
    function navTo() {
        navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}`)
    }
    useEffect(() => {
        if (localStorage.getItem('patientPortalToken') !== null) {
            navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal`)

            getsummary();

        } else {
            // portals();

            // navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}`); // Replace '/your-link' with the desired line
            setPortals(true);

        }
    }, [])
    return <>
        {/* forget Pin MODAL*/}
        < Modal className='modale fade vertical-align-middle' centered id='showForgetPinModal' show={showForgetPinModal} onHide={handleCloseForgetPinModal} >
            <Modal.Header closeButton>
                <img src='../../images/cropped-9952-img-32x32.jpg' alt="sgh Icon" />

                <Modal.Title>                   {t('Patients Portal')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center my-5">
                    {pinForgetMsgError ? <div className="portal-error text-white  my-2">{t('ERROR!')} {pinForgetMsgError}</div> : ''}
                    <form id="popup-form" className="row justify-content-center text-center" onSubmit={forgetPinNumber} method="get" action="">
                        <div className="messages"></div>

                        <div className="form-group col-sm-6">
                            <input type="text" name="id" className="form-control" placeholder={t("Phone Number")}
                                required="required" data-error="Valid Phone Number is required." value={userPhoneInp} onChange={(e) => setuserPhoneInp(e.target.value)} />
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group col-sm-6">
                            <input type="number" name="id" className="form-control" placeholder={t("National ID")}
                                required="required" data-error="Valid National ID is required." minLength={14} maxLength={14} value={userIdInp} onChange={(e) => setUserIdInp(e.target.value)} />
                            <div className="help-block with-errors"></div>
                        </div>

                        <div className="col-sm-6 mt-xs-3">
                            <button className="btn btn-theme btn-radius" type='submit' ><span>{t('Confirm')}</span>
                            </button>
                        </div>


                    </form>
                </div>

            </Modal.Body >
            {/* <Modal.Footer>
    <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseForgetPinModal}>
        Close
    </Button>
</Modal.Footer> */}
        </Modal >
        <Modal show={portal} onHide={portalClose} id="patientPortalModale" backdrop="static">
            <Modal.Header closeButton className='d-flex justify-content-between align-items-center'>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title style={{ color: "#4ab242" }}>{t('Patients Portal')}</Modal.Title>

            </Modal.Header>
            <Modal.Body >

                {/* <Form className='row justify-content-center'>

                                                        <Form.Group
                                                            className="mb-3 col-md-6"
                                                            controlId="ControlText"
                                                        >

                                                            <Form.Control type="text" placeholder="PIN Number"
                                                            />
                                                        </Form.Group>
                                                    </Form> */}
                {pinError ? <div className="portal-error text-white my-2">{t('ERROR!')} {pinError}</div> : ''}
                {pinForgetMsg ? <div className="portal-success text-white  my-2"> {pinForgetMsg}</div> : ''}

                <Form className='row justify-content-center align-items-end flex-column' onSubmit={submitLoginForm}>
                    <Form.Group
                        className=" col-md-6 mx-auto ms-3 mb-5"
                        controlId="ControlText"
                    >

                        <Form.Control type="text" name="pin" value={pinInp} onChange={handleChange} placeholder={t("PIN Number")}
                        />
                        {/* <small className="text-danger">
                                                                {
                                                                    errorList.filter((error) => error.context.label === "pin")[0]
                                                                        ?.message
                                                                }
                                                            </small> */}
                    </Form.Group>
                    {/* {pinError ? <small className='text-danger mt-3 col-md-8 mx-auto text-center mb-3'>{pinError}</small> : ''} */}
                    <div className='me-3 d-flex flex-column justify-content-center align-items-center'>
                        <Button className='col-md-3 btn btn-theme btn-sm appoint-btn mt-5 mt-sm-0 rounded-3 ' type="submit" >
                            {t('Next')}
                        </Button>
                        <small className='col-md-5 text-theme small pt-2 text-center text-decoration-underline mt-5 mt-sm-0 rounded-3' type="button" onClick={handleForgetPin}>
                            {t('Forget My Pin')}

                        </small>
                    </div>
                </Form>



            </Modal.Body>
            {/* <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={portalClose}>
                                                        Close
                                                    </Button> */}
            {/* <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={navbar}>
                                                        Next
                                                    </Button> */}
            {/* </Modal.Footer> */}
        </Modal>

        <Modal show={portalPin} onHide={portalPinClose} id="patientportalPinModale" backdrop="static">
            <Modal.Header closeButton className='d-flex justify-content-between align-items-center'>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title style={{ color: "#4ab242" }}>{t('Patients Portal')}</Modal.Title>

            </Modal.Header>
            <Modal.Body >

                {/* <Form className='row justify-content-center'>

                                                        <Form.Group
                                                            className="mb-3 col-md-6"
                                                            controlId="ControlText"
                                                        >

                                                            <Form.Control type="text" placeholder="PIN Number"
                                                            />
                                                        </Form.Group>
                                                    </Form> */}
                {phoneError ? <div className="portal-error text-white my-2">{t('ERROR!')} {phoneError}</div> : ''}

                <Form className='row justify-content-center' onSubmit={submitPhoneNumberForm}>
                    <p className='text-center lead'>{t('please enter your phone number to Send SMS with Your Password')}</p>

                    <Form.Group
                        className="mb-5 col-md-12 otp-container" style={{ direction: 'ltr' }}
                        controlId="ControlText"
                    >
                        {/* <div className="otp-container"> */}
                        {/* <Form.Control className="otp-input" type="text" maxLength="1" name="num1" value='0' onChange={handleChange} />
                                                                <Form.Control className="otp-input" type="text" maxLength="1" name="num2" value='1' onChange={handleChange} />
                                                                <Form.Control className="otp-input" type="text" maxLength="1" name="num3" value='5' onChange={handleChange} />
                                                                <Form.Control className="otp-input" type="text" maxLength="1" name="num4" value='5' onChange={handleChange} /> */}
                        <span className="otp-input me-2">{patientPhoneFirst}</span>
                        <Form.Control className="otp-input border-bottom" placeholder='x' type="text" maxLength="1" defaultValue={formPhoneData.numX1} name="numX1" ref={input1Ref}
                            onKeyDown={(event) => handleKeyDown(event, input1Ref)}
                            onChange={handleInputChange} />
                        <Form.Control className="otp-input border-bottom" placeholder='x' type="text" maxLength="1" defaultValue={formPhoneData.numX2} name="numX2" ref={input2Ref}
                            onKeyDown={(event) => handleKeyDown(event, input2Ref)}
                            onChange={handleInputChange} />
                        <Form.Control className="otp-input border-bottom" placeholder='x' type="text" maxLength="1" defaultValue={formPhoneData.numX3} name="numX3" ref={input3Ref}
                            onKeyDown={(event) => handleKeyDown(event, input3Ref)}
                            onChange={handleInputChange} />
                        <Form.Control className="otp-input border-bottom" placeholder='x' type="text" maxLength="1" defaultValue={formPhoneData.numX4} name="numX4" ref={input4Ref}
                            onKeyDown={(event) => handleKeyDown(event, input4Ref)}
                            onChange={handleInputChange} />
                        <Form.Control type='hidden'
                            ref={combinedInputRef}
                            value={phoneNumX5Inp}
                            onChange={() => { }}
                        />
                        {/* <Form.Control className="otp-input border-bottom" placeholder='x' type="hidden" maxLength="4"
                                                                onKeyDown={(event) => handleKeyDown(event, input4Ref)}
                                                                onChange={(event) => handleInputChange(event, input4Ref)} /> */}

                        <span className="otp-input ms-2">{patientPhoneLast}</span>

                        {/* <Form.Control className="otp-input" type="text" maxLength="1" name="num5" value='2' onChange={handleChange} />
                                                                <Form.Control className="otp-input" type="text" maxLength="1" name="num6" value='8' onChange={handleChange} />
                                                                <Form.Control className="otp-input" type="text" maxLength="1" name="num7" value='5' onChange={handleChange} /> */}
                        {/* </div> */}
                        {/* <Form.Control type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder='Phone number'/> */}

                    </Form.Group>
                    <div className='me-3 d-flex justify-content-end'>


                        <Button className='col-md-3 btn btn-theme btn-sm appoint-btn mt-5 mt-sm-0 rounded-3' type="submit" >
                            {t('Confirm')}
                        </Button>

                    </div>

                </Form>


            </Modal.Body>
            {/* <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={portalClose}>
                                                        Close
                                                    </Button> */}
            {/* <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={navbar}>
                                                        Next
                                                    </Button> */}
            {/* </Modal.Footer> */}
        </Modal>

        <Modal show={portalPhone} onHide={portalPhoneClose} id="patientportalPhoneModale" backdrop="static">
            <Modal.Header closeButton className='d-flex justify-content-between align-items-center'>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title style={{ color: "#4ab242" }}>{t('Patients Portal')}</Modal.Title>

            </Modal.Header>
            <Modal.Body >

                {/* <Form className='row justify-content-center'>

                                                        <Form.Group
                                                            className="mb-3 col-md-6"
                                                            controlId="ControlText"
                                                        >

                                                            <Form.Control type="text" placeholder="PIN Number"
                                                            />
                                                        </Form.Group>
                                                    </Form> */}
                {codeError ? <div className="portal-error text-white my-2">{t('ERROR!')} {codeError}</div> : ''}

                <Form className='row justify-content-center align-items-center flex-column' onSubmit={handleSubmit}>
                    <p className='text-center lead'>{t('please enter your Password')}</p>

                    <Form.Group
                        className="mb-5 col-md-6 ms-3 position-relative"
                        controlId="ControlText"
                    >
                        <Form.Control type={showPassword ? 'text' : 'password'} minLength={8} maxLength={16} name="code" value={formData.code} onChange={handleCodeChange} placeholder={t('Password')} style={{ paddingRight: '2.5rem' }}
                        />

                        <div
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '20px',
                                cursor: 'pointer',
                            }}
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? '👁️' : '🔒'} {/* Eye and lock emoji icons */}
                        </div>
                    </Form.Group>
                    <div className='me-3 d-flex flex-column justify-content-center align-items-center'>
                        <Button className='col-md-5 btn btn-theme btn-sm appoint-btn mt-5 mt-sm-0 rounded-3' type="submit" >
                            {t('Confirm')}
                        </Button>
                        <small className='col-md-5 text-theme small pt-2 text-center text-decoration-underline mt-5 mt-sm-0 rounded-3' type="button" onClick={handleSendOTP} disabled={isSending}>
                            {isSending ? t('Sending...') : t('Forget Password')}

                        </small>

                    </div>

                </Form>


            </Modal.Body>
            {/* <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={portalClose}>
                                                        Close
                                                    </Button> */}
            {/* <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={navbar}>
                                                        Next
                                                    </Button> */}
            {/* </Modal.Footer> */}
        </Modal>
        <PatientPortalChangePasswordModale
            show={portalChangePassword}
            onHide={handlePortalChangePasswordClose}
            changePasswordError={changePasswordError} handleChangePasswordSubmit={handleChangePasswordSubmit} oldPasswordInp={oldPasswordInp} setOldPasswordInp={setOldPasswordInp} newPasswordInp={newPasswordInp} setNewPasswordInp={setNewPasswordInp} confirmationPasswordInp={confirmationPasswordInp} setConfirmationPasswordInp={setConfirmationPasswordInp}
            toggleOldPasswordVisibility={toggleOldPasswordVisibility} toggleNewPasswordVisibility={toggleNewPasswordVisibility} toggleConfirmedPasswordVisibility={toggleConfirmedPasswordVisibility} setShowPassword={setShowPassword} showOldPassword={showOldPassword} setShowOldPassword={setShowOldPassword} showNewPassword={showNewPassword} setShowNewPassword={setShowNewPassword} showConfimedPassword={showConfimedPassword} setShowConfimedPassword={setShowConfimedPassword}
        />
    </>
}

import Cookies from 'js-cookie';
import React, { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import PageTitle from '../../../Utilites/PageTitle';
import axios from 'axios';
import { useEffect } from 'react';
import Select from 'react-select';
import { Button, Modal } from 'react-bootstrap';
import { GetDepartmentsOptionsContext } from '../../../../Context/GetDepartmentsOptions';
import { GetDoctorsOptionsContext } from '../../../../Context/GetDoctorsOptions';
import Loader from '../../../Utilites/Loader';
import Alert from 'react-bootstrap/Alert';
// import Pagination from 'react-bootstrap/Pagination';
import Pagination from 'react-js-pagination';
import { PatientPortalPinInpContext } from '../../../../Context/Authentication/PatientPortalPinInpContext';
// import 'react-js-pagination/dist/Pagination.css';
// import img from '../../../../images/pattern/favorite.png';
import moment from 'moment';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../../../Context/LocalizationContext';
export default function Doctors() {

    // translation 
    const { t } = useTranslation("AlexDoctor");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation
    // fav icon
    const [favoriteDoctors, setFavoriteDoctors] = useState([]);
    // <Alert variant="success">
    //     <Alert.Heading>Favorite Doctors</Alert.Heading>
    //     <p>
    //         Added To Favorites.
    //     </p>
    //     <hr />
    //     <p className="mb-0">
    //         Whenever you need to show your Favorites Doctors <span className='bg-white border text-muted'>Click here </span>
    //     </p>
    // </Alert>
    const handleFavoriteClick = (doctor) => {
        // Check if the doctor is already in favorites
        const isFavorite = favoriteDoctors.some((favDoctor) => favDoctor.id === doctor.id);
        console.log('isFavorite ' + isFavorite);
        if (isFavorite) {
            // Remove the doctor from favorites
            const updatedFavorites = favoriteDoctors.filter((favDoctor) => favDoctor.id !== doctor.id);
            // localStorage.removeItem('',)
            setFavoriteDoctors(updatedFavorites);
            console.log('updatedFavorites ' + favoriteDoctors);

        } else {
            // Add the doctor to favorites
            setFavoriteDoctors([...favoriteDoctors, doctor]);
            localStorage.setItem('favoriteDoctors', JSON.stringify(favoriteDoctors));
            console.log('updatedFavorites ' + favoriteDoctors);



        }


    };
    const [displayDoctors, setDisplayDoctors] = useState(true);

    const handleFavDoc = () => {
        setDisplayDoctors(false);
    }
    useEffect(() => {
        // Get favorite doctors from local storage on component mount
        const favoriteDoctorsFromStorage = localStorage.getItem('favoriteDoctors');
        if (favoriteDoctorsFromStorage) {
            setFavoriteDoctors(JSON.parse(favoriteDoctorsFromStorage));
        }
    }, []);


    useEffect(() => {
        // Update local storage when favorite doctors change
        localStorage.setItem('favoriteDoctors', JSON.stringify(favoriteDoctors));
    }, [favoriteDoctors]);
    // fav icon
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    const { pathname } = useLocation();
    let [doctors, setDoctors] = useState([]);
    const [departments, setDepartments] = useState([]);
    let { GetDepartmentsSelectOptions, DepartmentsOptions } = useContext(GetDepartmentsOptionsContext);
    let { GetDoctorsSelectOptions, DoctorsOptions, selectedOption, setSelectedOption, setDoctorsOptions, selectedBranch, setSelectedBranch } = useContext(GetDoctorsOptionsContext);
    const [doctorSelectedOption, setDoctorSelectedOption] = useState('');
    const [selectedOrderOption, setSelectedOrderOption] = useState('');
    const OrderOptions = [
        { value: 'gender', label: t('Gender') },
        { value: 'age', label: t('Age') },
        { value: 'position', label: t('Position') },
        { value: 'name', label: t('Name') },
    ]
    const [selectedOrderByOption, setSelectedOrderByOption] = useState('');
    const OrderByOptions = [
        { value: 'Asc', label: t('Ascending') },
        { value: 'DESC', label: t('Descending') },
    ]

    // const [loading, setLoading] = useState(true);
    const [searchData, setSearchData] = useState([]);
    const [searchInp, setSearchInp] = useState('');
    const [ageInp, setAgeInp] = useState('');
    const [specialistTitleInp, setSpecialistTitleInp] = useState(false);
    const [registrarTitleInp, setRegistrarTitleInp] = useState(false);
    const [consultantTitleInp, setConsultantTitleInp] = useState(false);
    const [maleGenderInp, setMaleGenderInp] = useState(false);
    const [femaleGenderInp, setFemaleGenderInp] = useState(false);
    let search = [];
    const brancheOptions = [
        { value: '1', label: t('Cairo') },
        { value: '2', label: t('Alexandria') },
    ]

    const defaultValue = { value: '2', label: 'Alexandria' };
    const [showModal, setShowModal] = useState(false);
    const [showAvailableSlotsModal, setShowAvailableSlotsModal] = useState(false);
    const [showNextModal, setShowNextModal] = useState(false);
    const [showConfirmedModal, setShowConfirmedModal] = useState(false);
    const [showFreeSlotsModal, setShowFreeSlotsModal] = useState(false);
    const [showForgetPinModal, setShowForgetPinModal] = useState(false);
    const [showFirstTimeModal, setShowFirstTimeModal] = useState(false);
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [visible, setVisible] = useState(6);

    function loadMore() {
        setVisible(visible + 6);
    }


    let [doctorId, setDoctorId] = useState('');
    let [doctorsBooking, setDoctorsBooking] = useState([]);
    let [doctorData, setDoctorData] = useState({});
    let [doctorIdData, setDoctorIdData] = useState('');
    let [doctorDepartmentIdData, setDoctorDepartmentIdData] = useState('');
    let [doctorBranchData, setDoctorBranchData] = useState('');
    let [doctorNameData, setDoctorNameData] = useState('');
    // const [showAvailableSlotsModal, setShowAvailableSlotsModal] = useState(false);
    const handleOpenAvailableSlotsModal = (doc_id, dep_id, branch, doc_name) => {

        // const ButtonValue = event.target.value;
        const id = doc_id;
        const docBranch = branch;
        const dep = dep_id;
        const name = doc_name;
        setDoctorId(id);
        setDoctorBranchData(docBranch);
        setDoctorDepartmentIdData(dep);
        setDoctorNameData(name);
        console.log('doc id ' + doctorId);
        console.log('doc branch ' + doctorBranchData);
        console.log('doc dep-id ' + doctorDepartmentIdData);
        setShowAvailableSlotsModal(true);

        // if (id && docBranch && dep) {
        //     await axios.getFreeDays();
        //     // setShowAvailableSlotsModal(true);
        // }
        // setShowAvailableSlotsModal(true);
        // if (currentPage) {
        //     let { data } = await axios.get(`http://130.7.1.27/mayo/public/doctor/list/${Cookies.get('i18next')}?page=${currentPage}`);
        //     // const filteredDoctors = data.Edata.filter((doctor) => doctor.department_name === departments);
        //     console.log(data);
        //     setDoctorsBooking(data.Edata.data);
        //     console.log(doctorsBooking);
        //     if (Array.isArray(doctorsBooking)) {
        //         const doctorList = doctorsBooking.find((doctorBooking) => doctorBooking.doc_id === doctorId);
        //         setDoctorData(doctorList);
        //         setDoctorBranchData(doctorList.branch);
        //         setDoctorDepartmentIdData(doctorList.department_id);
        //         setDoctorIdData(doctorList.doc_id);
        //         console.log('selected dr ' + doctorData);
        //         console.log('selected dr ' + doctorData);
        //         console.log('selected dr ' + doctorDepartmentIdData);
        //         console.log('selected dr ' + doctorBranchData);
        //         console.log('selected dr ' + doctorIdData);
        //         getFreeDays();
        //         setShowAvailableSlotsModal(true);
        //     }
        //     // if (doctorList) {

        //     // if (doctorData.doc_id && doctorData.department_id && doctorData.branch) {

        //     // }

        //     // }
        // }
        // getDoctorsList();


    };


    // useEffect(() => {
    //     async function fetchData() {
    //         try {
    //             if (doctorId) {
    //                 const response = await axios.get(`http://130.7.1.27/mayo/public/doctor/list/${Cookies.get('i18next')}?page=${currentPage}`);
    //                 const data = response.data.Edata.data;
    //                 setDoctorsBooking(data);

    //                 const doctorList = data.find((doctorBooking) => doctorBooking.doc_id === doctorId);
    //                 setDoctorData(doctorList);
    //                 setDoctorBranchData(doctorList.branch);
    //                 setDoctorDepartmentIdData(doctorList.department_id);
    //                 setDoctorIdData(doctorList.doc_id);
    //                 getFreeDays();
    //             }
    //         } catch (error) {
    //             // Handle the error
    //             console.error(error);
    //         }
    //     }

    //     fetchData();
    // }, [doctorId]);

    // useEffect(() => {
    //     if (Object.keys(doctorData).length > 0) {
    //         getFreeDays();
    //     // }
    // }, [doctorData])
    const [selectedDate, setSelectedDate] = useState();
    const handleOpenModal = () => {
        setShowModal(true);
        // setSelectedDate(date);
        // setShowAvailableSlotsModal(false)
    };
    const handleOpenNextModal = (date) => {
        setShowModal(false);
        setShowNextModal(true);
        setShowAvailableSlotsModal(false)
        setShowConfirmedModal(false);
        setShowOTPModal(false);
        setShowFirstTimeModal(false);
        setShowForgetPinModal(false);
        setShowFreeSlotsModal(false);
        setSelectedDate(date);
    };
    let [selectedTime, setSelectedTime] = useState('');
    let [selectedTimeTO, setSelectedTimeTO] = useState('');
    function handleOpenConfirmedModal(time, timeTo) {
        // const timeFrom = time;
        // const timeTill = timeTo;
        setSelectedTimeTO(timeTo);
        setSelectedTime(time);
        setShowConfirmedModal(true);
        setShowModal(false);
        setShowNextModal(false);
        setShowAvailableSlotsModal(false)
        setShowForgetPinModal(false);
        setShowFirstTimeModal(false);
        setShowOTPModal(false);
        setShowFreeSlotsModal(false)
        // bookNow();

    };


    const handleOpenForgetPinModal = () => {
        setShowForgetPinModal(true);
        setShowNextModal(false);
        setShowAvailableSlotsModal(false);
        setShowConfirmedModal(false);
        setShowFirstTimeModal(false);
        setShowModal(false);
        setShowOTPModal(false);
        setShowFreeSlotsModal(false);
        // setShowConfirmedModal(false);

    };
    const handleOpenFirstTimeModal = () => {
        setShowFirstTimeModal(true);
        setShowNextModal(false);
        // setShowConfirmedModal(false);

    };
    const handleOpenOTPModal = () => {
        setShowOTPModal(true);
        // setShowNextModal(false);
        setShowFirstTimeModal(false);

    };

    const handleCloseAvailableSlotsModal = () => {
        setShowAvailableSlotsModal(false);
        setFreeDays([]);
        setFreeDaysMsg('');
        document.getElementById('showAvailableSlotsModal').classList.add('d-none');

    };
    const handleCloseModal = () => {
        setShowModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseNextModal = () => {
        setShowNextModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseFreeSlotsModal = () => {
        setShowFreeSlotsModal(false);
        // document.querySelector('.modale').classList.add('d-none');
    }
    const handleCloseConfirmedModal = () => {
        setShowConfirmedModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseForgetPinModal = () => {
        setShowForgetPinModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseFirstTimeModal = () => {
        setShowFirstTimeModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    const handleCloseOTPModal = () => {
        setShowOTPModal(false);
        // document.querySelector('.modale').classList.add('d-none');

    };
    // const handleSelectChange = (selectedOption) => {
    //     // doctorSearch()

    //     setSelectedOption(selectedOption);
    //     setCurrentPageForSearch(1)

    //     // setDoctorSelectedOption(doctorSelectedOption);

    // };

    // const handleSearchInpChange = (event) => {
    //     // doctorSearch()
    //     // const selectedSearch = event.target.value;
    //     // setSearchInp(selectedSearch);
    //     // setCurrentPageForSearch(1)

    //     // setDoctorSelectedOption(doctorSelectedOption);

    // };
    // const handleSelectChange = (selectedOption, doctorSelectedOption) => {

    //     setSelectedOption(selectedOption);
    //     setDoctorSelectedOption(doctorSelectedOption);

    // };
    const handleSelectedSearchInp = (event) => {
        // doctorSearch()

        const selectedEarchInp = event.target.value;
        setSearchInp(selectedEarchInp)
        setCurrentPageForSearch(1)

        // console.log(selectedOrderOption.value);
    }
    const handleSelectedOrder = (event) => {
        // doctorSearch()

        const selectedOrderValue = event.target.value;
        setSelectedOrderOption(selectedOrderValue)
        setCurrentPageForSearch(1)

        // console.log(selectedOrderOption.value);
    }
    const handleSelectedOrderBy = (event) => {
        // doctorSearch()

        const selectedOrderByValue = event.target.value;
        setSelectedOrderByOption(selectedOrderByValue)
        setCurrentPageForSearch(1)

    }
    const handleSelectChange = (selectedOption, doctorSelectedOption) => {

        setSelectedOption(selectedOption);
        setDoctorSelectedOption(doctorSelectedOption);
        setCurrentPageForSearch(1)

    };
    const handleSelectedBranch = (selectedBranch) => {

        setSelectedBranch(selectedBranch);
        setCurrentPageForSearch(1)

    };
    const handleDoctorSelectChange = (doctorSelectedOption) => {
        // setSelectedOption(selectedOption);

        setDoctorSelectedOption(doctorSelectedOption);
        console.log('doctor name ' + doctorSelectedOption.value);
        setCurrentPageForSearch(1)

    };
    // const handleDoctorSelectChange = doctorSelectedOption => {
    //     setDoctorSelectedOption(doctorSelectedOption);
    // };
    async function getDepartmentsList() {
        let { data } = await axios.get(`http://130.7.1.27/mayo/public/booking/department/1/${Cookies.get('i18next')}`);
        // const filteredDepartment = data.response
        // const depart = filteredDepartment.filter((department) => department = department.name);
        const filteredDepartments = data.filter(department =>
            doctors.some(doctor => doctor.department_name === department.label)
        );
        setDepartments(filteredDepartments);
        // setLoading(false);


    }
    let [lastPage, setLastPage] = useState();
    let [perPage, setPerPage] = useState();
    let [total, setTotal] = useState();
    // Calculate the start and end indices for the current page
    let [currentPage, setCurrentPage] = useState(1)


    async function getDoctorsList() {

        // const startIndex = (currentPage - 1) * perPage;
        // const endIndex = startIndex + perPage;
        if (currentPage) {
            let { data } = await axios.get(`http://130.7.1.27/mayo/public/doctor/list/${Cookies.get('i18next')}?search_hospital=2&page=${currentPage}`);
            // const filteredDoctors = data.Edata.filter((doctor) => doctor.department_name === departments);
            setDoctors(data.Edata.data);
            console.log('totot' + doctors);
            setLastPage(data.Edata.last_page)
            console.log('totot' + lastPage);

            setPerPage(data.Edata.per_page)
            console.log('totot' + perPage);

            setTotal(data.Edata.total)
            console.log('totot' + total);
        }
        // document.getElementById('pagination').classList.remove('d-none');
        // if (!total) {
        //     document.getElementById('pagination').classList.add('d-none');

        // } else {
        //     document.getElementById('pagination').classList.remove('d-none');

        // }
        // setLastPage(data.Edata.last_page)
        // setLoading(false);

    }
    let pageList = new Array(lastPage).fill("pages").map((ele, i) => i + 1);
    let [currentPageForSearch, setCurrentPageForSearch] = useState(1)
    let [lastPageOfSearch, setLastPageOfSearch] = useState()
    let [perPageOfSearch, setPerPageOfSearch] = useState()
    let [totalOfSearch, setTotalOfSearch] = useState()
    const location = useLocation();
    const navigate = useNavigate();

    // Parse query parameter `page` from URL
    const queryParams = new URLSearchParams(location.search);
    const pageFromUrl = queryParams.get('page');
    useEffect(() => {
        if (pageFromUrl) {
            setCurrentPage(Number(pageFromUrl)); // Update currentPage based on query parameter
            setCurrentPageForSearch(Number(pageFromUrl))
        }
    }, [location.search]); // Re-run the effect if the URL changes

    // console.log('ddddd' + pageList);
    async function doctorSearch() {
        // e.preventDefault();

        if (searchInp || selectedBranch || selectedOption || specialistTitleInp || registrarTitleInp || consultantTitleInp || maleGenderInp || femaleGenderInp || ageInp || DoctorsOptions || selectedOrderOption || selectedOrderByOption || currentPageForSearch) {
            let { data } = await axios.get(`http://130.7.1.27/mayo/public/doctor/list/${Cookies.get('i18next')}?search_hospital=${selectedBranch.value}&search_name=${searchInp}&search_title[]=${specialistTitleInp ? 'Specialist' : ''}&search_title[]=${registrarTitleInp ? 'Registrar' : ''}&search_title[]=${consultantTitleInp ? 'Consultant' : ''}&gender[]=${maleGenderInp ? '2' : ''}&gender[]=${femaleGenderInp ? '1' : ''}&search_department=${selectedOption.value}&doc_id=${DoctorsOptions.value}&page=${currentPageForSearch}&orderby=${selectedOrderOption}&order=${selectedOrderByOption}`);
            // if (data.Emsg === "Success") {
            setSearchData(data.Edata.data);
            console.log(searchData);
            console.log(searchInp);
            setPerPageOfSearch(data.Edata.per_page)
            setLastPageOfSearch(data.Edata.last_page)
            setTotalOfSearch(data.Edata.total);
            console.log('tototofsearch' + totalOfSearch);
            // }
            // setLoading(false);
            // document.getElementById('paginationForSearch').classList.remove('d-none');
            //     document.getElementById('pagination').classList.add('d-none');

            // } else {
            //     document.getElementById('pagination').classList.remove('d-none');

        }

    }
    const handlePageChange = (pageNumber) => {
        // const pageNumber = e;

        setCurrentPage(pageNumber);
        navigate(`?page=${pageNumber}`); // Update URL to reflect page change

        scrollToTop();
    };
    const handlePageChangeForSearch = (pageNum) => {
        // const pageNum = e;
        setCurrentPageForSearch(pageNum);
        navigate(`?page=${pageNum}`); // Update URL to reflect page change

        scrollToTop();

    };
    function onPaginate(page) {
        setCurrentPage(page);
        // getDoctorsList()
    }
    function nextPage() {
        setCurrentPage(currentPage + 1)
    }
    function prevPage() {
        setCurrentPage(currentPage - 1)

    }
    useEffect(() => {
        getDoctorsList()

    }, [currentPage])
    function preventDefault(e) {
        e.preventDefault();
    }
    useEffect(() => {
        GetDepartmentsSelectOptions(selectedBranch.value);

        // getDoctorsList();
        // setLoading(false);

    }, [selectedBranch])
    useEffect(() => {
        setSelectedBranch({ value: '2', label: t('Alexandria') })

    }, [])
    useEffect(() => {
        GetDoctorsSelectOptions();
        // setLoading(false);
    }, [selectedOption])

    // useEffect(() => {

    //     getDepartmentsList();
    // setLoading(false);
    // }, [doctors])
    useEffect(() => {
        // doctorSearch();
        if (searchInp) {
            // currentPageForSearch(1)
            search = searchData?.filter((doctorSearch) => searchInp.includes(doctorSearch.famous_name))
            // doctorSearch();

        } else if (maleGenderInp ? '2' : '') {

            search = searchData?.filter((doctorSearch) => (maleGenderInp ? '2' : '').includes(doctorSearch.Sex))
        } else if (femaleGenderInp ? '1' : '') {

            search = searchData?.filter((doctorSearch) => (femaleGenderInp ? '1' : '').includes(doctorSearch.Sex))
        }
        else if (specialistTitleInp ? 'Specialist' : '') {

            search = searchData?.filter((doctorSearch) => (specialistTitleInp ? 'Specialist' : '').includes(doctorSearch.position))

        } else if (registrarTitleInp ? 'Registrar' : '') {


            search = searchData?.filter((doctorSearch) => (registrarTitleInp ? 'Registrar' : '').includes(doctorSearch.position))

        } else if (consultantTitleInp ? 'Consultant' : '') {


            search = searchData?.filter((doctorSearch) => (consultantTitleInp ? 'Consultant' : '').includes(doctorSearch.position))

        } else if (selectedOption) {


            search = searchData?.filter((doctorSearch) => selectedOption === doctorSearch.department_id)

        } else if (selectedBranch) {
            search = searchData?.filter((doctorSearch) => selectedBranch === doctorSearch.branch)

        } else if (doctorSelectedOption) {


            search = searchData?.filter((doctorSearch) => doctorSelectedOption === doctorSearch.doc_id)

        } else if (DoctorsOptions) {
            search = searchData?.filter((doctorSearch) => DoctorsOptions === doctorSearch.doc_id)

        }

        else {

            // search = searchData?.map((doctorSearch))

        }

        // setLoading(false);
    }
        , [searchInp, DoctorsOptions, selectedOrderOption, currentPageForSearch, selectedOrderByOption, specialistTitleInp, registrarTitleInp, consultantTitleInp, maleGenderInp, femaleGenderInp, selectedOption, selectedBranch, doctorSelectedOption])
    useEffect(() => {
        doctorSearch();

    }, [selectedOption, selectedBranch, selectedOrderByOption, selectedOrderOption, currentPageForSearch, searchInp])

    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }

    // Booking

    //  Api for get free_days for Booking
    let [freeDays, setFreeDays] = useState([]);
    let [freeDaysMsg, setFreeDaysMsg] = useState();
    // let [firstFree, setFirstFree] = useState([]);
    let [freeDaysOptions, setFreeDaysOptions] = useState([]);

    const getFreeDays = async () => {

        const bodyFormData = new FormData();
        bodyFormData.append('doc_id', doctorId);
        bodyFormData.append('departmentid', doctorDepartmentIdData);
        // bodyFormData.append('lang',  Cookies.get('i18next'));

        if (pinInp.length > 0) {
            bodyFormData.append('pin', pinInp);
        } else {
            bodyFormData.append('pin', '0');
        }

        bodyFormData.append('branch', doctorBranchData);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),

        }


        try {

            const response = await axios.post('http://130.7.1.27/mayo_test/public/patientportal/booking/free_days', bodyFormData, { headers });

            if (response.data.Ecode === 200) {
                if (response.data.Edata.length === 0) {
                    setFreeDaysMsg('There Are No Available Slots');
                    setShowAvailableSlotsModal(true);

                } else {
                    setFreeDays(response.data.Edata);
                    // document.getElementById('showOTPModal').classList.add('d-none');
                    // // document.getElementById('ShowAvailableSlotsModal').classList.add('d-none');
                    // document.getElementById('showNextModal').classList.add('d-none');
                    // document.getElementById('showFreeSlotsModal').classList.add('d-none');
                    // document.getElementById('showConfirmedModal').classList.add('d-none');
                    // document.getElementById('showFirstTimeModal').classList.add('d-none');
                    // document.getElementById('showForgetPinModal').classList.add('d-none');
                    setShowAvailableSlotsModal(true);
                    console.log(freeDays);
                    setFreeDaysOptions(response.data.fselect);
                    console.log(response.data.fselect);
                    setFreeDaysMsg('');
                }
            } else {
                setFreeDays(response.data.Edata);

                setFreeDaysMsg(response.data.Emsg);
                console.log(freeDaysMsg);
                // document.getElementById('showOTPModal').classList.add('d-none');
                // // document.getElementById('ShowAvailableSlotsModal').classList.add('d-none');
                // document.getElementById('showNextModal').classList.add('d-none');
                // document.getElementById('showFreeSlotsModal').classList.add('d-none');
                // document.getElementById('showConfirmedModal').classList.add('d-none');
                // document.getElementById('showFirstTimeModal').classList.add('d-none');
                // document.getElementById('showForgetPinModal').classList.add('d-none');
                setShowAvailableSlotsModal(true);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const [formData, setFormData] = useState({ pin: '' });
    const [pinNumInp, setPinNumInp] = useState({});
    const handleChange = (e) => {

        let myUser = { ...formData };
        myUser.pin = e.target.value

        setPinNumInp(myUser.pin);

        console.log('setPinNumInp ' + pinNumInp);

    };

    // checkPatientPhone
    const [formPhoneNumData, setFormPhoneNumData] = useState({ phone: '' });
    const [phoneNumInp, setPhoneNumInp] = useState({});
    const [patienName, setPatienName] = useState();
    const [pinError, setPinError] = useState(false);

    const handlePhoneNumChange = (e) => {

        let myUserPhone = { ...formPhoneNumData };
        myUserPhone.phone = e.target.value;

        setPhoneNumInp(myUserPhone.phone);

        console.log('setPinNumInp ' + phoneNumInp);

    };

    async function checkPatientPhone() {
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('pin', pinNumInp);
        bodyFormData.append('phone', phoneNumInp);
        bodyFormData.append('branch', doctorBranchData);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),

        }

        const response = await axios.post(`http://130.7.1.27/mayo_test/public/patientportal/booking/checkpatientphone`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    // setCheckPhoneNum(response.data.Emsg);
                    setPatienName(response.data.Edata.name)
                    console.log('check phone ' + response.data.Edata);
                    setShowConfirmedModal(false);
                    setShowFreeSlotsModal(true);
                    setShowNextModal(false);
                    setShowForgetPinModal(false);
                    setShowFirstTimeModal(false);
                    setShowOTPModal(false);
                    setShowModal(false);
                    setShowAvailableSlotsModal(false);
                    setPinError(false);
                    getFreeSlots();

                } else {
                    handleOpenNextModal();
                    setPinError(response.data.Emsg);
                    setPinForgetMsg('');
                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }



    // free slots 
    const [freeSlots, setFreeSlots] = useState([]);
    const [date, setDate] = useState();
    const [FreeSlotsMsg, setFreeSlotsMsg] = useState();
    let [dateValue, setDateValue] = useState();
    const handleSelectedDate = (dateValue) => {

        setSelectedDate(dateValue.value);
        console.log(selectedDate);
        // setDoctorSelectedOption(doctorSelectedOption);
        // setCurrentPageForSearch(1)

    };

    async function getFreeSlots() {
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('doc_id', doctorId);
        bodyFormData.append('departmentid', doctorDepartmentIdData);
        bodyFormData.append('pin', pinNumInp);
        bodyFormData.append('branch', doctorBranchData);
        bodyFormData.append('date', selectedDate);
        console.log('selectedDate ' + selectedDate);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`http://130.7.1.27/mayo_test/public/patientportal/booking/freeslots`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    // setCheckPhoneNum(response.data.Emsg);
                    if (response.data.Edata === '') {
                        setFreeSlotsMsg(t('OOPs there is no available slots in'));
                    } else {
                        setDate(response.data.Edata.date);
                        setFreeSlots(response.data.Edata.slots);
                        setFreeSlotsMsg('');
                    }
                    // console.log('free slots ' + freeSlots.slots.map(slot => slot.time));

                } else {
                    setFreeSlotsMsg(response.data.Emsg);

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }

    // useEffect(() => {
    //     if (selectedDate) {
    //         getFreeSlots();

    //     }
    // }, [selectedDate])

    // forget pin number
    const [userIdInp, setUserIdInp] = useState();
    const [pinForgetMsg, setPinForgetMsg] = useState();
    const [userPhoneInp, setuserPhoneInp] = useState();
    const [pinForgetMsgError, setPinForgetMsgError] = useState();
    async function forgetPinNumber(e) {
        e.preventDefault();
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('pin', '0');
        bodyFormData.append('phone', userPhoneInp);
        bodyFormData.append('userid', userIdInp);
        bodyFormData.append('branch', doctorBranchData);

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`http://130.7.1.27/mayo_test/public/patientportal/booking/checkpatientphone`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 202) {
                    // setCheckPhoneNum(response.data.Emsg);
                    // setPinForgetMsg(response.data.Emsg)
                    console.log('handleOpenForgetPinModal ' + response.data);
                    console.log('handleOpenForgetPinModal ' + response.data.Emsg);
                    setPinForgetMsg(response.data.Emsg)
                    setShowNextModal(true);
                    setShowAvailableSlotsModal(false);
                    setShowFreeSlotsModal(false);
                    setShowConfirmedModal(false);
                    setShowFirstTimeModal(false);
                    setShowForgetPinModal(false);
                    setShowOTPModal(false);
                    setShowModal(false);
                    // checkPatientPhone();
                } else {
                    setPinForgetMsgError(response.data.Emsg)

                    handleOpenForgetPinModal();
                }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }


    // booking 
    const [bookingMsg, setBookingMsg] = useState('');
    async function bookNow() {
        const bodyFormData = new FormData();
        if (patientNewPin) {
            bodyFormData.append('pin', -(patientNewPin));

        } else {
            bodyFormData.append('pin', pinNumInp);

        }
        if (patientPhone) {
            bodyFormData.append('phone', patientPhone);

        } else {
            bodyFormData.append('phone', phoneNumInp);

        }
        bodyFormData.append('branch', doctorBranchData);
        bodyFormData.append('date', selectedDate);

        bodyFormData.append('time_from', selectedTime);
        bodyFormData.append('time_to', selectedTimeTO);
        bodyFormData.append('clinic_id', doctorDepartmentIdData);
        bodyFormData.append('doc_id', doctorId);

        console.log('selectedDate ' + selectedDate);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`http://130.7.1.27/mayo_test/public/patientportal/booking/book`, bodyFormData, { headers })
            .then(response => {
                if (response.data.Ecode === 200) {

                    // Handle the response
                    setBookingMsg(response.data.Emsg);
                    setShowConfirmedModal(true);
                    setShowModal(false);
                    setShowNextModal(false);
                    setShowAvailableSlotsModal(false)
                    setShowForgetPinModal(false);
                    setShowFirstTimeModal(false);
                    setShowOTPModal(false);
                    setShowFreeSlotsModal(false)
                } else {
                    setShowConfirmedModal(true);
                    setShowFreeSlotsModal(false);
                    setShowAvailableSlotsModal(false);
                    setShowNextModal(false);
                    setShowModal(false);
                    setShowForgetPinModal(false);
                    setShowFirstTimeModal(false);
                    setShowOTPModal(false);
                    setBookingMsg(response.data.Emsg);

                }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }



    // New Registration 

    const [patientNewPin, setPatientNewPin] = useState('');
    const [patientName, setPatientName] = useState();
    const [patientPhone, setPatientPhone] = useState('');
    const [patientBirthDate, setPatientBirthDate] = useState();
    const [patientGender, setPatientGender] = useState();
    const [patientRegistrationError, setPatientRegistrationError] = useState();
    async function registerNewPatient(e) {
        e.preventDefault();
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object

        bodyFormData.append('name', patientName);
        bodyFormData.append('phone', patientPhone);
        bodyFormData.append('birthday', patientBirthDate);
        bodyFormData.append('gender', patientGender);

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`http://130.7.1.27/mayo_test/public/patientportal/booking/RegisterNewPatient`, bodyFormData, { headers })
            .then(response => {
                if (response.data.Emsg === "Success") {
                    // Handle the response
                    console.log(response.data);
                    setPatientNewPin(response.data.Edata.pin);
                    handleOpenOTPModal();
                } else {
                    setPatientRegistrationError(response.data.Emsg);
                    setShowOTPModal(false);
                    setShowFirstTimeModal(true);
                }

                // setCheckPhoneNum(response.data.Emsg);
                // } else {
                //     setShowConfirmedModal(true);
                //     setShowFreeSlotsModal(false);
                //     setShowAvailableSlotsModal(false);
                //     setShowNextModal(false);
                //     setShowForgetPinModal(false);
                //     setShowFirstTimeModal(false);
                //     setShowOTPModal(false);
                //     setBookingMsg(response.data.Emsg);

                // }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }

    // check code for New patient registrtion
    const [patientCode, setPatientCode] = useState();
    const [patientCodeError, setPatientCodeError] = useState();
    async function checkNewPatientCode(e) {
        e.preventDefault();
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object

        bodyFormData.append('pin', patientNewPin);
        bodyFormData.append('phone', patientPhone);
        bodyFormData.append('birthday', patientBirthDate);
        bodyFormData.append('gender', patientGender);
        bodyFormData.append('otpcode', patientCode);
        bodyFormData.append('branch', doctorBranchData);

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint')
        }

        const response = await axios.post(`http://130.7.1.27/mayo_test/public/patientportal/booking/CheckCode`, bodyFormData, { headers })
            .then(response => {
                if (response.data.Ecode === 200) {
                    // Handle the response
                    // setPatientRegistration(response.data);
                    console.log(response.data);
                    // handleOpenOTPModal();
                    setShowOTPModal(false);
                    setShowFreeSlotsModal(true);

                    // freeSlots();

                    // setShowAvailableSlotsModal(true);

                } else {
                    setShowOTPModal(true);
                    setPatientCodeError(response.data.Emsg)
                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }
    useEffect(() => {
        if (selectedTime !== '' && selectedTimeTO !== '') {
            bookNow();
        }
    }, [selectedTime, selectedTimeTO]);

    // useEffect(() => {
    //     if (selectedTime.length > 0 && selectedTimeTO.length > 0 && Object.keys(pinNumInp).length > 0 && Object.keys(phoneNumInp).length > 0 && doctorDepartmentIdData.length > 0 && doctorId.length > 0) {
    //         bookNow();
    //         console.log('selectedTimeTO ' + selectedTimeTO);
    //         console.log('selectedTime ' + selectedTime);
    //     } else if (selectedTime.length > 0 && selectedTimeTO.length > 0 && patientPhone.length > 0 && patientNewPin.length > 0 && doctorDepartmentIdData.length > 0 && doctorId.length > 0) {
    //         bookNow();
    //     } else {


    //     }
    // }, [selectedTime, selectedTimeTO, pinNumInp, phoneNumInp, doctorDepartmentIdData, doctorId, patientPhone, patientNewPin]);
    let { pinInp, setPinInp } = useContext(PatientPortalPinInpContext);

    useEffect(() => {
        if (doctorId && doctorBranchData && doctorDepartmentIdData) {
            getFreeDays();
            setShowAvailableSlotsModal(true);
            // setShowOTPModal(false);

        }
    }, [doctorId, doctorBranchData, doctorDepartmentIdData, pinInp]);

    // useEffect(() => {
    //     getFreeDays();
    // }, [])
    function cairoNavbar() {
        // <Navigate to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo`} />
        document.getElementById('normal-nav').classList.add('d-none');
        document.getElementById('alex-nav').classList.add('d-none');
        document.getElementById('cairo-nav').classList.remove('d-none');
        document.getElementById('referTo').classList.remove('d-none');
        document.getElementById('patient-portal-nav').classList.add('d-none');
        scrollToTop()
    }
    function alexNavbar() {
        document.getElementById('normal-nav').classList.add('d-none');
        document.getElementById('cairo-nav').classList.add('d-none');
        document.getElementById('alex-nav').classList.remove('d-none');
        document.getElementById('referTo').classList.remove('d-none');
        document.getElementById('patient-portal-nav').classList.add('d-none');
        scrollToTop()

    }
    return <>
        {/* <!--page title start--> */}
        <PageTitle title={t('Doctors')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/doctors`} currentPage={t('Doctors')} />
        {/* <!--page title end--> */}
        {/* <!--page title end--> */}

        {/* <!--body content start--> */}
        <div className="page-content">

            {/* <!--blog start--> */}

            <section className='pt-2'>

                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mx-auto">
                            <div className="pb-3">
                                <div className="row justify-content-center">

                                    <div className="col-lg-10 p-0 col-md-12 teams-container">

                                        <div className="row justify-content-between w-100 m-0">

                                            <div className="d-sm-flex mb-3 teams justify-content-center">
                                                <div className="col-lg-4 col-md-12 mt-7 mt-lg-0" >

                                                    <div className="widget" >

                                                        <div className="widget-searchbox justify-content-center mb-0 clearfix d-flex align-items-center justify-content-between" style={{ zIndex: '1001' }}>
                                                            <i className="flaticon-paper-plane text-main px-2  border-end" style={{ fontSize: '18px' }}></i>

                                                            <Select placeholder={t("Select Branch")} value={selectedBranch} options={brancheOptions} onChange={handleSelectedBranch} className="w-100 ps-3" styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                                                    outline: state.isFocused ? ' none' : 'none',

                                                                }),
                                                                option: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    // borderColor: state.isFocused ? '#409639' : ' hsl(0, 0%, 80%)',
                                                                    backgroundColor: state.isFocused ? '#409639' : ' ',
                                                                    color: state.isFocused ? '#fff' : '',

                                                                }),

                                                            }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                                            </Select>

                                                        </div >

                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-12 mt-3 mt-lg-0" >

                                                    <div className="widget" >

                                                        <div className="widget-searchbox justify-content-center mb-0 clearfix d-flex align-items-center justify-content-between" style={{ zIndex: '1000' }}>
                                                            <i className="flaticon-stethoscope text-main px-2 border-end" style={{ fontSize: '18px' }}></i>

                                                            <Select placeholder={t("Select Department")} options={DepartmentsOptions} value={selectedOption} onChange={handleSelectChange} className="w-100 ps-3" styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                                                    outline: state.isFocused ? ' none' : 'none',
                                                                }),
                                                                option: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    // borderColor: state.isFocused ? '#409639' : ' hsl(0, 0%, 80%)',
                                                                    backgroundColor: state.isFocused ? '#409639' : ' ',
                                                                    color: state.isFocused ? '#fff' : '',

                                                                }),
                                                            }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                                            </Select>

                                                        </div >

                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-12 mt-3 mt-lg-0" >

                                                    <div className="widget" >

                                                        <div className="widget-searchbox " >
                                                            <div className="widget-searchbox mb-3 justify-content-center mb-0 clearfix d-flex align-items-center justify-content-between" style={{ zIndex: '999' }}>
                                                                <i className="flaticon-doctor text-main px-2  border-end" style={{ fontSize: '18px' }}></i>
                                                                <div className='d-flex justify-content-end w-100 ps-3'>
                                                                    <button type="submit" className="search-btn" > <i className="fas fa-search"></i>
                                                                    </button>
                                                                    <input type="text" value={searchInp} onChange={handleSelectedSearchInp} placeholder={t('Doctor Name')} className="form-control h-auto" />
                                                                </div>
                                                                {/* <Select placeholder="Select physician" options={DoctorsOptions} onChange={handleDoctorSelectChange} className="w-100 px-2" styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                                                        outline: state.isFocused ? ' none' : 'none',

                                                                    }),
                                                                    option: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        backgroundColor: state.isFocused ? '#409639' : ' ',
                                                                        color: state.isFocused ? '#fff' : '',

                                                                    }),
                                                                }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                                                </Select> */}
                                                            </div>
                                                        </div >

                                                    </div>
                                                </div>
                                                {/* <button className="btn btn-theme bt-sm  py-1 px-2  rounded-bottom rounded-top" onClick={doctorSearch}><span><i className='ti-search'></i></span>
                                                </button> */}
                                                {/* <div className="col-lg-1 col-md-12 mt-7 mt-lg-0" >

                                                    <div className="widget" >

                                                        <div className="widget-searchbox" >
                                                        

                                                        </div >

                                                    </div>

                                                </div > */}
                                            </div>



                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row justify-content-between mb-2">
                        <div className="col-xl-3 profile" onClick={handleFavDoc}>
                            {/* <Link className='btn btn-sm btn-border btn-radius mb-2'><i class="fa-solid fa-heart-circle-check me-2 border-end pe-2"></i>
                                <span> My Favourite Doctors</span>
                            </Link> */}

                            {/* <p>Selected option: {selectedOrderByOption}</p> */}

                        </div>
                        <div className="col-xl-4 row pe-0 pe-md-3 d-none d-md-flex">
                            {/* <h6 className='text-theme fw-bold d-block d-md-none'>Sort by options</h6> */}

                            <div className="col-xl-6 pe-0 pe-md-2">

                                <select className='w-100' value={selectedOrderOption} onChange={handleSelectedOrder}>
                                    <option value='name'>{t('Name')}</option>
                                    <option value='position'>{t('Position')} </option>
                                    <option value='gender'>{t('Gender')}</option>
                                    <option value='age'>{t('Age')}</option>
                                </select>
                                {/* <p>Selected option: {selectedOrderOption}</p> */}
                            </div>
                            <div className="col-xl-6 mt-2 mt-sm-0  pe-0 pe-md-2" value={selectedOrderByOption} onChange={handleSelectedOrderBy}>

                                <select className='w-100'>

                                    <option value='DESC'>{t('Descending')}</option>
                                    <option value='ASC'>{t('Ascending')}</option>
                                </select>
                                {/* <p>Selected option: {selectedOrderByOption}</p> */}

                            </div>
                        </div>

                        {/* <img src='../../../../images/pattern/favorite.png' className='img-fluid' /> */}



                        {/* <h2>Favorite Doctors</h2>
                        {favoriteDoctors.length === 0 ? (
                            <p>No favorite doctors selected.</p>
                        ) : (
                            favoriteDoctors.map((favDoctor) => (
                                <div key={favDoctor.id}>
                                    <span>{favDoctor.name}</span>
                                </div>
                            ))
                        )} */}




                    </div>

                    {/* <!--team start--> */}


                    {/* <div className="container"> */}

                    <div className="row gx-3 gy-3">

                        {/* {favoriteDoctors.map((favDoctor) => (
                            <div key={favDoctor.id}>
                                <span>{favDoctor.name}</span>
                            </div>
                        ))} */}

                        {searchInp.length > 0 || consultantTitleInp || specialistTitleInp || registrarTitleInp || maleGenderInp || femaleGenderInp || selectedOption || selectedBranch || selectedOrderOption || selectedOrderByOption || doctorSelectedOption ?

                            (
                                <>
                                    {search ?

                                        (
                                            <>

                                                {searchData.length > 0 ? searchData.map((doctorSearch, index) => (<>

                                                    {/* <div className="col-lg-4 col-md-12" key={index}>
                                                                        <div className="team-member text-center">
                                                                            <div className="team-images">
                                                                                {doctorSearch.image ? <img className="img-fluid" src={`${doctorSearch.image}`} alt={doctorSearch.famous_name} style={{ marginBottom: '-114px' }} /> : doctorSearch.Sex === 2 ? <img className="img-fluid" src='/images/team/doctor.png' alt={doctorSearch.famous_name} style={{ marginBottom: '11px' }} /> : <img className="img-fluid" src='/images/team/doctor-female.png' alt={doctorSearch.famous_name} style={{ marginBottom: '11px' }} />}

                                                                                <div className="team-about">
                                                                                    <p>{doctorSearch.pio}</p>

                                                                                </div>
                                                                            </div>
                                                                            <div className="team-description px-0 py-4">
                                                                                <h5><Link to="">{doctorSearch.famous_name}</Link></h5>
                                                                                <span><i className="fa-solid fa-graduation-cap pe-2 pt-3"></i> {doctorSearch.position}</span>
                                                                            </div>


                                                                            <div className="row justify-content-evenly">
                                                                                <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/${doctorSearch.branch}/doctors/${doctorSearch.doc_id}`} className="btn btn-sm btn-border btn-theme w-40 py-2 px-2 text-sm" style={{ marginLeft: '1px' }}>{t('Full profile')} </Link>

                                                                                <Link to="" onClick={handleOpenAvailableSlotsModal} className="btn btn-sm btn-border btn-theme w-40 py-2 px-2 text-sm" style={{ marginLeft: '1px' }}>{t('Book Now')}</Link>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}

                                                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 teams-container" key={index}>
                                                        <div className="d-sm-flex mb-3 teams">
                                                            <div className="flex-shrink-0 overflow-hidden rounded-3 text-center">
                                                                {doctorSearch.image ? <img className="img-fluid" src={`${doctorSearch.image}`} alt={doctorSearch.famous_name} style={{ width: ' 200px', marginBottom: '-35px' }} /> : doctorSearch.Sex === 1 ? <img src="/images/team/doctor-2.png" alt={doctorSearch.famous_name} style={{ width: ' 200px' }} /> : <img src="/images/team/doctor-1.png" alt={doctorSearch.famous_name} style={{ width: ' 200px' }} />}
                                                            </div>

                                                            <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                                                                <div className="comment-date mb-2 "> <span className="date me-2">{doctorSearch.department_name}</span>
                                                                    {/* <a className='fav-icon'>  <i className='far fa-solid fa-heart border-left'></i></a> */}

                                                                </div>

                                                                <h5 className="text-black font-w-4 m-0"> {doctorSearch.famous_name}</h5>
                                                                <p className='text-muted m-0 small lead'>{doctorSearch.position}</p>
                                                                <p className='text-capitalize m-0'>  <i className="fas fa-map-marker-alt text-muted small"></i> {doctorSearch.branch} -
                                                                    {doctorSearch.branch === 'alex' ? <a href='https://maps.app.goo.gl/L1jCR7fFtSdfBUGLA' target='_blank'>{t('Get Directions')}</a> : <a href='https://goo.gl/maps/hAbQWB8g7SAhJPNJ7' target='_blank'>{t('Get Directions')}</a>}</p>


                                                                <p className='text-muted fw-bolder small m-0'>{t('Languages')} : <span className="date">{doctorSearch.spoken_lang ? doctorSearch.spoken_lang : t('English,Arabic')}</span></p>


                                                                <div className="profile mt-3">
                                                                    {doctorSearch.id === null ? <Link to='' className="btn btn-sm btn-border btn-disabled"> {t('Full Profile')}</Link>
                                                                        : <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/${doctorSearch.branch === 'القاهره' ? 'cairo' : (doctorSearch.branch === 'الاسكندريه' ? 'alex' : doctorSearch.branch)}/doctors/${doctorSearch.doc_id}`} className="btn btn-sm btn-border" onClick={(doctorSearch.branch === 'القاهره' || doctorSearch.branch === 'cairo') ? cairoNavbar : alexNavbar}> {t('Full Profile')}</Link>}

                                                                    <Link onClick={() => handleOpenAvailableSlotsModal(doctorSearch.doc_id, doctorSearch.department_id, doctorSearch.branch, doctorSearch.famous_name)} className="ms-1 btn btn-sm btn-theme btn-border btn-radius">{t('Book Now')}</Link>
                                                                </div>
                                                                {/* <div className="replay me-3">  </div> */}

                                                            </div>

                                                        </div>
                                                    </div>




                                                </>)


                                                )


                                                    : (
                                                        <> <div className="col-lg-8 col-md-12 mx-auto py-4">
                                                            <h3 className='title text-theme fs-5 text-center fw-bolder'>{t('Result Not Found')}
                                                                {/* <span className='fw-bolder fs-6'> {t('You Can Change Some Of Your Choices')}</span> */}
                                                            </h3>
                                                            {/* <img src='/images/service/no-result-search.png' title='not found' width='100%' height='100%' /> */}
                                                        </div>
                                                        </>)}
                                            </>)
                                        : (
                                            <> <div className="col-lg-8 col-md-12 mx-auto py-4">
                                                <h3 className='title text-theme fs-5 text-center fw-bolder'>{t('Result Not Found')}</h3>
                                                {/* <img src='/images/service/no-result-search.png' title='not found' width='100%' height='100%' /> */}
                                            </div>
                                            </>)


                                    }



                                </>

                            ) :
                            displayDoctors ? (doctors?.map((doctor, index) => <>
                                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 teams-container" key={index}>
                                    <div className="d-sm-flex mb-3 teams">
                                        <div className="flex-shrink-0 overflow-hidden rounded-3 text-center">
                                            {doctor.image ? <img className="img-fluid" src={`${doctor.image}`} alt={doctor.famous_name} style={{
                                                width: ' 200px',
                                                marginBottom: '-35px'
                                            }} /> : doctor.Sex === 1 ? <img src="/images/team/doctor-2.png" alt={doctor.famous_name} style={{
                                                width: ' 200px',
                                            }} /> : <img src="/images/team/doctor-1.png" alt={doctor.famous_name} style={{
                                                width: ' 200px',
                                            }} />}
                                        </div>

                                        <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                                            <div className="comment-date mb-2 "> <span className="date me-2">{doctor.department_name}</span>
                                                {/* <a className='fav-icon' onClick={() => handleFavoriteClick(doctor)}>
                                                    {favoriteDoctors.some((favDoctor) => favDoctor.id === doctor.id)
                                                        ? <><i class="far fa-solid fa-heart remove" title='Remove From Favorites'></i>

                                                        </> : <i className='far fa-solid fa-heart border-left' title='Add to Favorites'></i>}
                                                </a> */}

                                            </div>

                                            <h5 className="text-black font-w-4 m-0"> {doctor.famous_name}</h5>
                                            <p className='text-muted m-0 small lead'>{doctor.position}</p>
                                            <p className='text-capitalize m-0'>  <i className="fas fa-map-marker-alt text-muted small"></i> {doctor.branch} -
                                                {doctor.branch === 'alex' ? <a href='https://maps.app.goo.gl/L1jCR7fFtSdfBUGLA' target='_blank'>{t('Get Directions')}</a> : <a href='https://goo.gl/maps/hAbQWB8g7SAhJPNJ7' target='_blank'>{t('Get Directions')}</a>}</p>


                                            <p className='text-muted fw-bolder small m-0'>{t('Languages')} : <span className="date">{doctor.spoken_lang ? doctor.spoken_lang : t('English,Arabic')}</span></p>


                                            <div className=" profile mt-3">
                                                {doctor.id === null ? <Link to='' className="btn btn-sm btn-border btn-disabled"> {t('Full Profile')}</Link>
                                                    : <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/${doctor.branch === 'القاهره' ? 'cairo' : (doctor.branch === 'الاسكندريه' ? 'alex' : doctor.branch)}/doctors/${doctor.doc_id}`} className="btn btn-sm btn-border" onClick={(doctor.branch === 'القاهره' || doctor.branch === 'cairo') ? cairoNavbar : alexNavbar}> {t('Full Profile')}</Link>}

                                                {/* <div onClick={() => handleOpenAvailableSlotsModal(doctor.doc_id, doctor.department_id, doctor.branch)} className="appointment-box theme-bg"><h6>{t('Book Now')}</h6></div> */}
                                                <Link onClick={() => handleOpenAvailableSlotsModal(doctor.doc_id, doctor.department_id, doctor.branch, doctor.famous_name)} className="ms-1 btn btn-sm btn-theme btn-border btn-radius" >{t('Book Now')}</Link>
                                            </div>
                                            {/* <div className="replay me-3"> </div> */}

                                        </div>

                                        {/* <div className="team-about">
                                                                    <p>{doctor.pio}</p>

                                                                </div> */}

                                        {/* <div className="team-description px-0 py-4">
                                                                    <h5><Link to="">{doctor.famous_name}</Link></h5>
                                                                    <span><i className="fa-solid fa-graduation-cap pe-2 pt-3"></i>{doctor.position}</span>

                                                                </div> */}


                                        {/* <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors/${doctor.doc_id}`} className="btn btn-sm btn-border btn-theme w-100 py-2 px-2 text-sm" style={{ marginLeft: '1px' }}>Personal Page</Link> */}
                                        {/* <div className="row justify-content-evenly">
                                                                    <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors/${doctor.doc_id}`} className="btn btn-sm btn-border btn-theme w-40 py-2 px-2 text-sm" style={{ marginLeft: '1px' }}>{t('Full Profile')}</Link>

                                                                    <Link to="" onClick={handleOpenAvailableSlotsModal} className="btn btn-sm btn-border btn-theme w-40 py-2 px-2 text-sm" style={{ marginLeft: '1px' }}>{t('Book Now')}</Link>
                                                                </div> */}
                                    </div>
                                </div>


                            </>



                            )) : <div className='row'>
                                {/* <h2>Favorite Doctors</h2> */}
                                {favoriteDoctors.length === 0 ? (

                                    <>
                                        <div className="text-center mt-5">
                                            <p className='fw-bolder fs-3'>{t('No favorite doctors selected !')}</p>
                                            <img src='/images/denial.png' className='img-fluid' />
                                        </div>
                                    </>
                                ) : (
                                    favoriteDoctors.map((favDoctor) => (
                                        <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 teams-container" key={favDoctor.id}>
                                            <div className="d-sm-flex mb-3 teams">
                                                <div className="flex-shrink-0 overflow-hidden rounded-3 text-center">
                                                    {favDoctor.image ? <img className="img-fluid" src={`${favDoctor.image}`} alt={favDoctor.famous_name} style={{
                                                        width: ' 200px',
                                                        marginBottom: '-35px'
                                                    }} /> : favDoctor.Sex === 1 ? <img src="/images/team/doctor-2.png" alt={favDoctor.famous_name} style={{
                                                        width: ' 200px',
                                                    }} /> : <img src="/images/team/doctor-1.png" alt={favDoctor.famous_name} style={{
                                                        width: ' 200px',
                                                    }} />}
                                                </div>

                                                <div className="flex-grow-1 ms-sm-5 mt-4 position-relative mt-sm-0">
                                                    <div className="comment-date mb-2 "> <span className="date me-2">{favDoctor.department_name}</span>
                                                        {/* <a className='fav-icon' onClick={() => handleFavoriteClick(favDoctor)}>

                                                            <i class="fa-solid fa-trash remove" title='Remove From Favorites'></i>

                                                        </a> */}

                                                    </div>

                                                    <h5 className="text-black font-w-4 m-0"> {favDoctor.famous_name}</h5>
                                                    <p className='text-muted m-0 small lead'>{favDoctor.position}</p>
                                                    <p className='text-capitalize m-0'>  <i className="fas fa-map-marker-alt text-muted small"></i> {favDoctor.branch} -
                                                        {favDoctor.branch === 'alex' ? <a href='https://maps.app.goo.gl/L1jCR7fFtSdfBUGLA' target='_blank'>{t('Get Directions')}</a> : <a href='https://goo.gl/maps/hAbQWB8g7SAhJPNJ7' target='_blank'>{t('Get Directions')}</a>}</p>


                                                    <p className='text-muted fw-bolder small m-0'>{t('Languages')} : <span className="date">{favDoctor.spoken_lang ? favDoctor.spoken_lang : t('English,Arabic')}</span></p>


                                                    <div className=" profile mt-3">
                                                        {favDoctor.id === null ? <Link to='' className="btn btn-sm btn-border btn-disabled"> {t('Full Profile')}</Link>
                                                            : <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/${favDoctor.branch === 'القاهره' ? 'cairo' : (favDoctor.branch === 'الاسكندريه' ? 'alex' : favDoctor.branch)}/doctors/${favDoctor.doc_id}`} className="btn btn-sm btn-border" onClick={(favDoctor.branch === 'القاهره' || favDoctor.branch === 'cairo') ? cairoNavbar : alexNavbar}> {t('Full Profile')}</Link>}

                                                        <Link onClick={() => handleOpenAvailableSlotsModal(favDoctor.doc_id, favDoctor.department_id, favDoctor.branch, favDoctor.famous_name)} className="ms-1 btn btn-sm btn-theme btn-border btn-radius" >{t('Book Now')}</Link>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>


                                    ))
                                )}
                            </div>



                        }
                        {searchInp.length > 0 || currentPageForSearch > 1 || consultantTitleInp || specialistTitleInp || registrarTitleInp || maleGenderInp || femaleGenderInp || selectedOption || selectedBranch || selectedOrderOption || selectedOrderByOption || doctorSelectedOption ?
                            lastPageOfSearch > 1 ?
                                <>
                                    < div className="row justify-content-center text-center" >
                                        < Pagination
                                            activePage={currentPageForSearch}
                                            itemsCountPerPage={perPageOfSearch}
                                            totalItemsCount={totalOfSearch}
                                            pageRangeDisplayed={5}
                                            hideLastPages={true}
                                            onChange={handlePageChangeForSearch}
                                            itemClass="page-item"
                                            linkClass="page-link"

                                        />
                                    </div>

                                </>
                                : '' : displayDoctors ?
                                <>
                                    < div className="row justify-content-center text-center" id='pagination'>
                                        < Pagination
                                            activePage={currentPage}
                                            itemsCountPerPage={perPage}
                                            totalItemsCount={total}
                                            pageRangeDisplayed={5}
                                            hideLastPages={true}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>

                                </> : ''}






                        {/* <div className="text-center col-lg-12 col-md-12">
                                        {visible < doctors.length && (
                                            <button
                                                onClick={loadMore}
                                                className="btn btn-sm btn-border btn-radius mb-2 mt-3"
                                            >
                                                See More
                                            </button>
                                        )}
                                    </div> */}


                    </div >
                    {/* {searchData ? <Pagination
                        activePage={currentPageForSearch}
                        itemsCountPerPage={6}
                        totalItemsCount={85}
                        pageRangeDisplayed={5}
                        onChange={handlePageChangeForSearch}
                        itemClass="page-item"
                        linkClass="page-link"
                    /> : ''

                    }
                    {!searchData ? <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={6}
                        totalItemsCount={85}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                    /> : ''} */}
                    {/* <div class="row justify-content-center text-center" id='pagination'>
                        < Pagination
                            activePage={currentPage}
                            itemsCountPerPage={perPage}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div> */}
                    {/* <div className='row justify-content-center text-center d-none' id='paginationForSearch'>
                        <Pagination
                            activePage={currentPageForSearch}
                            itemsCountPerPage={perPageOfSearch}
                            totalItemsCount={totalOfSearch}
                            pageRangeDisplayed={5}
                            onChange={handlePageChangeForSearch}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div> */}
                    {/* <div class="row justify-content-center text-center" id='paginationForSearch'>

                     
                        <Pagination>
                            <Pagination.First onClick={() => handlePageChangeOnSearch(1)} />
                            <Pagination.Prev
                                onClick={() => handlePageChangeOnSearch(currentPageForSearch - 1)}
                                disabled={currentPageForSearch === 1}
                            />

                            <Pagination.Item onClick={() => handlePageChangeOnSearch(1)}>{1}</Pagination.Item>
                            {currentPageForSearch > 4 && <Pagination.Ellipsis />}

                            {currentPageForSearch >= 4 && currentPageForSearch <= lastPageOfSearch - 3 && (
                                <>
                                    <Pagination.Item onClick={() => handlePageChangeOnSearch(currentPageForSearch - 2)}>
                                        {currentPageForSearch - 2}
                                    </Pagination.Item>
                                    <Pagination.Item onClick={() => handlePageChangeOnSearch(currentPageForSearch - 1)}>
                                        {currentPageForSearch - 1}
                                    </Pagination.Item>
                                    <Pagination.Item active>{currentPageForSearch}</Pagination.Item>
                                    <Pagination.Item onClick={() => handlePageChangeOnSearch(currentPageForSearch + 1)}>
                                        {currentPageForSearch + 1}
                                    </Pagination.Item>
                                    <Pagination.Item onClick={() => handlePageChangeOnSearch(currentPageForSearch + 2)}>
                                        {currentPageForSearch + 2}
                                    </Pagination.Item>
                                </>
                            )}

                            {currentPageForSearch <= lastPageOfSearch - 4 && <Pagination.Ellipsis />}

                            {lastPageOfSearch > 1 && (
                                <>
                                    <Pagination.Item onClick={() => handlePageChangeOnSearch(lastPageOfSearch)}>
                                        {lastPageOfSearch}
                                    </Pagination.Item>
                                </>
                            )}

                            <Pagination.Next
                                onClick={() => handlePageChangeOnSearch(currentPageForSearch + 1)}
                                disabled={currentPageForSearch === lastPageOfSearch}
                            />
                            <Pagination.Last onClick={() => handlePageChangeOnSearch(lastPageOfSearch)} />
                        </Pagination>
                       
                    </div> */}
                    {/* </div> */}


                    {/* <!--team end--> */}

                    {/* <div className="col-lg-3 col-md-12 mt-7 mt-lg-0">
                            <div className="widget">
                                <div className="widget-search">
                                    <div className="widget-searchbox mb-3">
                                        <button type="submit" className="search-btn"> <i className="fas fa-search"></i>
                                        </button>
                                        <input type="text" value={searchInp} onChange={(e) => setSearchInp(e.target.value)} placeholder='Doctor Name' className="form-control" />

                                    </div>
                                 
                                </div>

                            </div>

                            <div className="widget">

                                <Link to="" className="btn btn-lg btn-border btn-theme btn-radius mb-2 w-100 text-start"><i className="fa-solid fa-filter me-2"></i><span className='mx-1'>Filter</span></Link>

                                <div className='title mb-0'>
                                    <h5><span className='px-2'><i className="fa-solid fa-graduation-cap pe-2"></i></span>Doctor Title</h5>
                                </div>
                                <div className="widget-searchbox px-5 mb-3">

                                    <div className="mt-2">
                                        <input className="form-check-input me-2" type="checkbox" id="flexSwitchCheckDefault" value='Specialist' name='title' checked={specialistTitleInp} onChange={(event) => setSpecialistTitleInp(event.target.checked)} />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Specialist</label>
                                    </div>
                                    <div className="mt-2">
                                        <input className="form-check-input me-2" type="checkbox" id="flexSwitchCheckDefaultConsultant" value='Consultant' name='title' checked={consultantTitleInp} onChange={(event) => setConsultantTitleInp(event.target.checked)} />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefaultConsultant">Consultant</label>
                                    </div>
                                    <div className="mt-2">
                                        <input className="form-check-input me-2" type="checkbox" id="flexSwitchCheckDefaultProfessor" value='Registrar' name='title' checked={registrarTitleInp} onChange={(event) => setRegistrarTitleInp(event.target.checked)} />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefaultProfessor">Registrar</label>
                                    </div>

                                </div>
                            
                                <div className='title mb-0 d-flex align-items-center'>
                                    <span className='px-2'><i className="fa-solid fa-venus-mars pe-2"></i></span>
                                    <h5>Doctor Gender</h5>
                                </div>
                                <div className="widget-searchbox px-5">
                                    <div className='mt-2'>
                                        <input className="form-check-input me-2" type="checkbox" id="GenderMale" checked={maleGenderInp}
                                            onChange={(event) => setMaleGenderInp(event.target.checked)} name='gender' value='2' />
                                        <label className="form-check-label" htmlFor="GenderMale">Male</label>
                                    </div>
                                    <div className='mt-2'>
                                        <input className="form-check-input me-2" type="checkbox" id="GenderFemale" checked={femaleGenderInp}
                                            onChange={(event) => setFemaleGenderInp(event.target.checked)} value='1' name='gender' />
                                        <label className="form-check-label" htmlFor="GenderFemale">Female</label>
                                    </div>

                                </div>
                            
                            </div>

                        </div> */}

                </div>
            </section >

            {/* <!--blog end--> */}

        </div >

        {/* <!--body content end-->  */}


        {/* available slots */}
        {/*  11111111111111111111111111111111111111111 */}
        {/* slots Modal */}
        <Modal className='modale fade' id='showAvailableSlotsModal' size="lg" scrollable show={showAvailableSlotsModal} onHide={handleCloseAvailableSlotsModal}>
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title id="contained-modal-title-vcenter"><span className='text-black'> {t('Available Slots')} </span> <span className='text-theme fw-bolder'> - {doctorNameData}</span></Modal.Title>

            </Modal.Header>
            <Modal.Body>

                <div className="row calender-box text-center justify-content-center gy-3">
                    {freeDaysMsg ? <h6> {freeDaysMsg}</h6> : ''}
                    {freeDays.length > 0 ?
                        freeDays?.map(freeDay => <>
                            <div className="col-md-3">
                                {/* <h4> {freeDaysMsg}</h4> */}
                                <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={() => handleOpenNextModal(freeDay.day)}>
                                    <div className=" appointment-box bg-mute rounded-0"><p className='m-0 text-black'>
                                        <span className='d-block text-theme'>{format(new Date(freeDay.day), 'EEEE')}</span>
                                        {freeDay.day}</p>
                                    </div>
                                    <div className="inner-box m-3" style={{ border: '1px solid #409639' }} >
                                        <div className="date px-2">
                                            <h6 className='m-0'>
                                                <span>{(parseInt(freeDay.first_free.split(':')[0], 10) >= 8 && parseInt(freeDay.last_free.split(':')[0], 10) <= 16) ? t('Morining') : (parseInt(freeDay.first_free.split(':')[0], 10) >= 16 && parseInt(freeDay.last_free.split(':')[0], 10) <= 23) ? t('Evening') : t('Evening,Morning')}</span>
                                            </h6>

                                            {/* <h4><span className='pe-1'>{freeDay.first_free}</span> : <span>{freeDay.last_free}</span></h4> */}
                                        </div>
                                        <div className="appointment-box theme-bg"><h6>{t('Book Now')}</h6></div>
                                    </div >
                                </div >



                            </div >
                        </>) : ''}

                    {/* {freeDays?.map(freeDay => <>
                        <div className="col-md-3">
                           
                            <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={() => handleOpenNextModal(freeDay.day)}>
                                <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>{freeDay.day}</h6>
                                </div>
                                <div className="inner-box m-3" style={{ border: '1px solid #409639' }} >
                                    <div className="date px-1"><h4> <span className='pe-1'> {moment(freeDay.first_free, 'HH:mm:ss').format('h:mm A')}</span><span>  {moment(freeDay.last_free, 'HH:mm:ss').format('h:mm A')} </span></h4></div>
                                    <div className="appointment-box theme-bg"><h6>{t('Book Now')}</h6></div>
                                </div >
                            </div >

                        </div >
                    </>)} */}

                    {/* <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }
                            } onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>{t('Book Now')}</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>{t('Book Now')}</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>{t('Book Now')}</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>{t('Book Now')}</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>{t('Book Now')}</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>{t('Book Now')}</h6></div >
                            </div >
                        </div >
                    </div >
                    <div className="col-md-3">
                        <div className="inner-box bg-white" style={{ border: '1px solid #e9e9e9' }} onClick={handleOpenNextModal}>
                            <div className=" appointment-box bg-mute rounded-0"><h6 className=' text-black'>Friday</h6>
                            </div>
                            <div className="inner-box m-3" style={{ border: '1px solid #409639' }} onClick={handleOpenModal} >
                                <div className="date px-1" > <h4> <span>8:00 am </span>to <span>8:15 am</span></h4></div >
                                <div className="appointment-box theme-bg" > <h6>{t('Book Now')}</h6></div >
                            </div >
                        </div >
                    </div > */}

                </div >


            </Modal.Body >
            <Modal.Footer className='justify-content-between align-items-center ps-2'>
                {freeDays ? <>
                    <div className="text-start">

                        <ul>
                            <li><span className='text-theme fw-bold'>{t('Morning Shifts')} :</span> {t('from')} 8 am {t('to')} 4 pm </li>
                            <li><span className='text-theme fw-bold'>{t('Evening Shifts')} :</span> {t('from')} 4 pm {t('to')} 8 pm </li>

                        </ul></div></> : ''}
                {/* <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseAvailableSlotsModal}>
                    Close
                </Button> */}
            </Modal.Footer>
        </Modal >

        {/* < !--body content end-- >  */}
        {/* Nex Modal*/}
        <Modal className='modale fade vertical-align-middle' id="showNextModal" centered scrollable show={showNextModal} onHide={handleCloseNextModal}>
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title>

                    {t('Please Enter Your Data')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center text-center">
                    {pinError ? <div className="portal-error text-white  my-2">{t('ERROR!')} {pinError}</div> : ''}
                    {pinForgetMsg ? <div className="portal-success text-white  my-2"> {pinForgetMsg}</div> : ''}

                    <form className="row justify-content-center text-center" onSubmit={preventDefault} >
                        <div className="messages"></div>

                        <div className="form-group col-sm-6">
                            <input id="form_id1" type="text" name="pin" onChange={handleChange} className="form-control" placeholder={t("Pin Number")}
                                required="required" data-error="Valid Patient ID is required." />
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group col-sm-6 mt-xs-3">
                            <input id="form_id2" type="text" name="phone" className="form-control" onChange={handlePhoneNumChange} placeholder={t("Phone Number")}
                                required="required" data-error="Valid Phone Number is required." />
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="col-sm-12 mt-1  mb-3">
                            <span className='small'><Link onClick={handleOpenForgetPinModal}>{t('Forget My Pin Number')} </Link></span>
                        </div>
                        <div className="col-md-4 mb-3 mb-md-0">
                            <button className="btn btn-theme btn-radius" onClick={checkPatientPhone}><span>{t('Confirmed')}</span>
                            </button>
                        </div>

                        <div className="col-md-8">
                            <button className="btn btn-theme btn-radius w-100" onClick={handleOpenFirstTimeModal}><span>{t('This Is My First Time')}</span>
                            </button>
                        </div>
                    </form>

                </div>

            </Modal.Body>
            {/* <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseNextModal}>
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal >

        {/* free slots  */}

        < Modal className='modale fade' size="lg" scrollable id='showFreeSlotsModal' show={showFreeSlotsModal} onHide={handleCloseFreeSlotsModal} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title> <span className='text-black'> {t('Free Slots')}</span> <span className='text-theme fw-bolder'> - {doctorNameData}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center calender-box text-center gy-3">
                    {FreeSlotsMsg ? <h4>{FreeSlotsMsg}</h4> : <>
                        <div className="team-description text-center row flex-column align-items-center mb-3">
                            <h4 className='text-muted mt-2'>👋 {t('Hello')} {patienName}</h4>
                            <p className='mt-2 '> {t('Here is Your Available Slots and You can Change your choice for day')} </p>
                            <div className="col-md-5">

                                <div className="widget-searchbox justify-content-center mb-0 clearfix d-flex align-items-center justify-content-between" >
                                    <i className="fa-regular fa-calendar text-main px-2 border-end" style={{ fontSize: '18px' }}></i>

                                    <Select options={freeDaysOptions} placeholder={date} value={dateValue} className="w-100 ps-3" onChange={handleSelectedDate} styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                            outline: state.isFocused ? ' none' : 'none',
                                        }),
                                        option: (baseStyles, state) => ({
                                            ...baseStyles,
                                            // borderColor: state.isFocused ? '#409639' : ' hsl(0, 0%, 80%)',
                                            backgroundColor: state.isFocused ? '#409639' : ' ',
                                            color: state.isFocused ? '#fff' : '',

                                        }),
                                    }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                    </Select>
                                </div>
                            </div>
                        </div>
                    </>}
                    {/* <div className="team-description text-center row flex-column align-items-center mb-3">
                        <h4 className='text-muted mt-2'>👋 Hello {patienName}</h4>
                        <p className='mt-2 '> Here is Your Available Slots and You can Change your choice for day </p>
                        <div className="col-md-5">

                            <div className="widget-searchbox justify-content-center mb-0 clearfix d-flex align-items-center justify-content-between" >
                                <i className="fa-regular fa-calendar text-main px-2 border-end" style={{ fontSize: '18px' }}></i>

                                <Select options={freeDaysOptions} placeholder={date} value={dateValue} className="w-100 ps-3" onChange={handleSelectedDate} styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                        outline: state.isFocused ? ' none' : 'none',
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        // borderColor: state.isFocused ? '#409639' : ' hsl(0, 0%, 80%)',
                                        backgroundColor: state.isFocused ? '#409639' : ' ',
                                        color: state.isFocused ? '#fff' : '',

                                    }),
                                }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                </Select>
                            </div>
                        </div>
                    </div> */}
                    {freeSlots.map((freeSlot, index) => <>
                        <div className="col-md-3" key={index}>
                            {/* {freeDaysMsg ? <h4>{freeDaysMsg}</h4> : ''} */}
                            <div className="inner-box" style={{ width: '100%', border: '1px solid #409639' }} onClick={() => handleOpenConfirmedModal(freeSlot.time, freeSlot.time_to)} >

                                <div className="date px-1"><h4> <span>{moment(freeSlot.time, 'HH:mm:ss').format('h:mm A')} </span> : <span>{moment(freeSlot.time_to, 'HH:mm:ss').format('h:mm A')}</span></h4></div>
                                <div className="appointment-box theme-bg"><h6>{t('Book Now')}</h6></div>
                            </div >

                        </div >

                    </>
                    )}





                </div>

            </Modal.Body >
            <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseFreeSlotsModal}>
                    {t('Close')}
                </Button>
            </Modal.Footer>
        </Modal >
        {/* confirmed Modal */}
        < Modal className='modale fade vertical-align-middle' scrollable id='showConfirmedModal' show={showConfirmedModal} onHide={handleCloseConfirmedModal} >
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title>                    {t('Your Message')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row justify-content-center my-5">
                    <div className="team-description text-center">
                        <Link to='' className='h3'>{bookingMsg}</Link>
                        {/* {bookingMsg ? <p className='mt-5 '> If you need to change or cancel your booking, please call 16259.</p> : ''} */}

                    </div>

                </div>

            </Modal.Body >
            <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseConfirmedModal}>
                    {t('Close')}
                </Button>
            </Modal.Footer>
        </Modal >

        {/* forget Pin MODAL*/}
        < Modal className='modale fade vertical-align-middle' centered id='showForgetPinModal' show={showForgetPinModal} onHide={handleCloseForgetPinModal} >
            <Modal.Header closeButton>
                <img src='../../images/cropped-9952-img-32x32.jpg' alt="sgh Icon" />

                <Modal.Title>                   {t('Forget Your Pin')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center my-5">
                    {pinForgetMsgError ? <div className="portal-error text-white  my-2">{t('ERROR!')} {pinForgetMsgError}</div> : ''}
                    <form id="popup-form" className="row justify-content-center text-center" onSubmit={forgetPinNumber} method="get" action="">
                        <div className="messages"></div>

                        <div className="form-group col-sm-6">
                            <input type="text" name="id" className="form-control" placeholder={t("Phone Number")}
                                required="required" data-error="Valid Phone Number is required." value={userPhoneInp} onChange={(e) => setuserPhoneInp(e.target.value)} />
                            <div className="help-block with-errors"></div>
                        </div>
                        <div className="form-group col-sm-6">
                            <input type="number" name="id" className="form-control" placeholder={t("National ID")}
                                required="required" data-error="Valid National ID is required." minLength={14} maxLength={14} value={userIdInp} onChange={(e) => setUserIdInp(e.target.value)} />
                            <div className="help-block with-errors"></div>
                        </div>

                        <div className="col-sm-6 mt-xs-3">
                            <button className="btn btn-theme btn-radius" type='submit' ><span>{t('Confirmed')}</span>
                            </button>
                        </div>


                    </form>
                </div>

            </Modal.Body >
            {/* <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseForgetPinModal}>
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal >

        {/* my first time MODAL*/}
        <Modal className='modale fade vertical-align-middle' centered id='showFirstTimeModal' show={showFirstTimeModal} onHide={handleCloseFirstTimeModal} >
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title>   {t('Enter Your Data')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center my-5">
                    {patientRegistrationError ? <div className="portal-error text-white  my-2">{t('ERROR!')} {patientRegistrationError}</div> : ''}
                    <form className="row justify-content-center text-center" onSubmit={registerNewPatient} method='post'>
                        <div className="messages"></div>
                        <div className="form-group col-sm-6">
                            <input id="form_id1" type="text" name="Fullname" value={patientName} onChange={(e) => setPatientName(e.target.value)} className="form-control" placeholder={t("Full Name")}
                                required="required" data-error="Valid Full Name is required." />
                            <div className="help-block with-errors"></div>

                        </div>
                        <div className="form-group col-sm-6">
                            <select className="form-select form-control" required="required" value={patientGender} onChange={(e) => setPatientGender(e.target.value)} data-error="Valid Gender is required.">
                                <option value="- Select Gender">{t('- Select Gender')}</option>
                                <option value="1">{t('Male')}</option>
                                <option value="2">{t('Female')}</option>
                            </select>

                            <div className="help-block with-errors"></div>

                        </div>
                        <div className="form-group col-sm-6">
                            <input id="form_number1" type="text" name="id" className="form-control" value={patientPhone} onChange={(e) => setPatientPhone(e.target.value)} placeholder={t("Phone Number")}
                                required="required" data-error="Valid Phone Number is required." />
                            <div className="help-block with-errors"></div>
                        </div>

                        <div className="form-group col-sm-6">
                            <input type="date" className="form-control" value={patientBirthDate} onChange={(e) => setPatientBirthDate(e.target.value)} placeholder={t("Date")} id="datepicker2" />
                        </div>
                        <div className="col-sm-6 mt-xs-3">
                            <button className="btn btn-theme btn-radius" type='submit' ><span>{t('Register')}</span>
                            </button>
                        </div>


                    </form>
                </div >

            </Modal.Body >
            {/* <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseFirstTimeModal}>
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal >
        {/* OTP MODAL */}

        < Modal className='modale fade vertical-align-middle' centered id='showOTPModal' show={showOTPModal} onHide={handleCloseOTPModal} >
            <Modal.Header closeButton>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title>   {t('Enter Your OTP')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="row justify-content-center my-5">
                    {patientCodeError ? <div className="portal-error text-white  my-2">{t('ERROR!')} {patientCodeError}</div> : ''}
                    <form id="popup-form" className="row justify-content-center text-center" onSubmit={checkNewPatientCode} method="post">
                        <div className="messages"></div>


                        <div className="form-group col-sm-6">
                            <input type="text" name="id" className="form-control" placeholder={t("Code")}
                                required="required" data-error="Valid Code is required." onChange={(e) => setPatientCode(e.target.value)} value={patientCode} />
                            <div className="help-block with-errors"></div>
                        </div>


                        <div className="col-sm-6 mt-xs-3">
                            <button className="btn btn-theme btn-radius" type='submit' ><span>{t('Confirmed')}</span>
                            </button>
                        </div>


                    </form>
                </div>

            </Modal.Body >
            {/* <Modal.Footer>
                <Button className="btn btn-theme btn-sm appoint-btn mt-sm-0 rounded-top rounded-bottom" onClick={handleCloseOTPModal}>
                    Close
                </Button>
            </Modal.Footer> */}
        </Modal >
    </>
}

import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageTitle from '../../../Utilites/PageTitle';

import Carousel from "react-bootstrap/Carousel";
import axios from 'axios';
import { LocalizationContext } from '../../../../Context/LocalizationContext';
import { useTranslation } from 'react-i18next';

export default function ServicesDetails() {
    // translation 
    const { t } = useTranslation("CairoServicesDetails");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();
    const { service_id } = useParams();
    const [allServicesDetails, SetAllServicesDetails] = useState({});
    const [allServicesDetailsImages, SetAllServicesDetailsImages] = useState([]);
    async function getServicesDetails(hospital_id, serviceId) {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        // bodyFormData.append('pin', pinNumInp);
        // bodyFormData.append('phone', phoneNumInp);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
        }

        const response = await axios.get(`http://130.7.1.27/mayo/public/service/details/${hospital_id}/${serviceId}/${Cookies.get('i18next')}`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    SetAllServicesDetails(response.data.Edata);
                    SetAllServicesDetailsImages(response.data.Edata.images);
                    // allServicesDetails.images?.map((imgService) => console.log(imgService))
                    console.log('all services details ' + response.data.Edata);
                    console.log(allServicesDetailsImages);

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    useEffect(() => {
        getServicesDetails('1', service_id);

    }, [])
    return <>
        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={allServicesDetails.famous_name} anotherTitle={t('Department')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/services`} prevPage={t('Our Services')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/services/${allServicesDetails.famous_name}/${allServicesDetails.id}`} currentPage={allServicesDetails.famous_name} />
            : <PageTitle title={t('Department')} anotherTitle={allServicesDetails.famous_name} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/services`} prevPage={t('Our Services')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/services/${allServicesDetails.famous_name}/${allServicesDetails.id}`} currentPage={allServicesDetails.famous_name} />
        }
        {/* <!--page title end--> */}

        <div className="page-content">

            {/* <!--service start--> */}

            <section>
                <div class="container">
                    <div className="row">

                        <div className="col-lg-7 order-2 col-md-12 mt-5 mt-lg-0">
                            <div className="section-title mb-4">
                                {/* <h2 className="title"> Our Services (Allied Health)
                                </h2> */}
                                <h2 className="title "> <span className='fw-bold'>{t('Overview')}</span>
                                </h2>
                                <p className="mb-0" dangerouslySetInnerHTML={{ __html: allServicesDetails.shortnote }}>
                                </p>
                            </div>


                        </div>
                        <div className="col-lg-5 order-1 order-md-3 col-md-12">
                            {/* <Carousel indicators={false} slide={true}>
                                <Carousel.Item className='item'>
                                

                                    <img
                                        src="/images/gallery/04.jpg"
                                        className="d-block w-100"
                                        alt="screenshot"
                                    />
                                
                                </Carousel.Item>

                                <Carousel.Item>

                                    <img
                                        src="/images//gallery/05.jpg"
                                        className="d-block w-100"
                                        alt="screenshot"
                                    />

                                </Carousel.Item>
                                <Carousel.Item>

                                    <img
                                        src="/images//gallery/06.jpg"
                                        className="d-block w-100"
                                        alt="screenshot"
                                    />

                                </Carousel.Item>


                            </Carousel> */}
                            {allServicesDetails.image ? <img
                                src={allServicesDetails.image}
                                className="d-block w-100"
                                alt="screenshot"
                            /> : ''}
                        </div>
                    </div>

                    <div className="row">

                        <div className="section-title mt-3 mb-3">

                            <h6 className='h3 text-white'><span className='theme-bg p-2 rounded-1'> {t('Scope Of Services')} </span></h6>
                        </div>
                        <div className="footer-list footer-list-services ps-5 ms-5" dangerouslySetInnerHTML={{ __html: allServicesDetails.services }} >
                            {/* <h5>Scope of  <span className="text-theme">service</span></h5> */}
                            {/* <div class="featured-item left-icon mt-5 ps-2" dangerouslySetInnerHTML={{ __html: allServicesDetails.services }}>

                </div> */}


                        </div>

                    </div>
                </div>

            </section>


        </div>
    </>
}

import React, { useContext, useRef } from 'react'
import logo from '../../images/logos/Health logo of egypt-min.webp';
import logoCairo from '../../images/logos/SGH-cairo-logo-02-min.webp';
import logoAlex from '../../images/logos/logos_alex_branding-min.webp';
import MayoClinicLogo from '../../images/right-footer-logo.png';
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { GetDepartmentsOptionsContext } from '../../Context/GetDepartmentsOptions';
import Select from 'react-select';
import { GetDoctorsOptionsContext } from '../../Context/GetDoctorsOptions';
import { useState } from 'react';
import axios from 'axios';
import Joi, { func } from 'joi';
import { PatientPortalBaseUrlContext } from '../../Context/PatientPortalBaseUrlContext';
import { PatientPortalPinInpContext } from '../../Context/Authentication/PatientPortalPinInpContext';
import { AuthContext } from '../../Context/Authentication/AuthContext';
import { SaveUserDataContext } from '../../Context/Authentication/SaveUserDataContext';
import { LocalizationContext } from '../../Context/LocalizationContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Subscribe from '../Utilites/Subscribe';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
export default function Footer() {
    // translation 
    const { t } = useTranslation("Footer");
    // let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 

    let { GetDepartmentsSelectOptions, DepartmentsOptions } = useContext(GetDepartmentsOptionsContext);
    let { GetDoctorsSelectOptions, DoctorsOptions, selectedOption, setSelectedOption, setDoctorsOptions, selectedBranch, setSelectedBranch } = useContext(GetDoctorsOptionsContext);

    const { pathname } = useLocation();

    const client = {
        autoplay: true,
        loop: true,
        margin: 30,
        responsiveClass: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 3,
            },
            600: {
                items: 4,
            },

            1000: {
                items: 5,

            }
        },
    }


    const brancheOptions = [
        { value: '1', label: t('Cairo') },
        { value: '2', label: t('Alexandria') },
    ]
    const defaultValue = { value: 'Cairo', label: 'Cairo' };
    const handleSelectBranchChange = (selectedBranch) => {

        setSelectedBranch(selectedBranch);
        console.log('selectedBranch ' + selectedBranch.value);

    };
    // const [DoctorsOptions, setDoctorsOptions] = useState('');

    const handleSelectChange = (selectedOption, DoctorsOptions) => {

        setSelectedOption(selectedOption);
        setDoctorsOptions(DoctorsOptions);

    };
    const handleDoctorSelectChange = (DoctorsOptions, { action }) => {
        // setSelectedOption(selectedOption);

        if (action === 'select-option') {
            setDoctorsOptions(DoctorsOptions);
        } else if (action === 'clear') {
            setDoctorsOptions(null);
        }
    };

    function preventDefault(e) {
        e.preventDefault();

    }
    // const navigate = useNavigate();
    const [searchData, setSearchData] = useState([]);
    let search = []
    function bookingResult() {

        navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`)
    }
    let [currentPageForSearch, setCurrentPageForSearch] = useState(1)

    async function doctorSearch(e) {
        if (e) {
            e.preventDefault();

            // e.preventDefault();
            // navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`)
            if (selectedOption || selectedBranch || DoctorsOptions) {
                let { data } = await axios.get(`http://130.7.1.27/mayo/public/doctor/list/${Cookies.get('i18next')}?search_hospital=${selectedBranch.value}&search_name=&search_title[]=&search_title[]=&search_title[]=&gender[]=&gender[]=&search_department=${selectedOption.value}&doc_id=${DoctorsOptions.value}&page=${currentPageForSearch}&orderby=&order=`);
                setSearchData(data.Edata.data);
                // <Navigate to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`} />

                // navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`)
            }
        }
    }
    useEffect(() => {
        GetDepartmentsSelectOptions(selectedBranch.value);


    }, [selectedBranch])

    useEffect(() => {
        GetDoctorsSelectOptions();
    }, [selectedOption])


    useEffect(() => {

        if (selectedOption) {
            search = searchData?.filter((doctorSearch) => selectedOption === doctorSearch.department_id)

        } else if (DoctorsOptions) {
            search = searchData?.filter((doctorSearch) => DoctorsOptions === doctorSearch.doc_id)

        } else if (selectedBranch) {
            search = searchData?.filter((doctorSearch) => selectedBranch === doctorSearch.branch)

        } else {
            search = searchData?.map((doctorSearch))
        }
        doctorSearch();
    }
        , [selectedOption, selectedBranch, DoctorsOptions])
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }











    // patient portal

    let { baseUrl, setBaseUrl } = useContext(PatientPortalBaseUrlContext)
    const { pinInp, setPinInp } = useContext(PatientPortalPinInpContext);
    const { userData, setUserData } = useContext(AuthContext)
    const { saveUserData } = useContext(SaveUserDataContext);
    /// To disable a button while waiting for an OTP (One-Time Password) to be sent to a phone number,
    // and then enable it once the message is sent, 
    const [isSending, setIsSending] = useState(false); // State to track whether the message is being sent

    const handleSendOTP = () => {
        setIsSending(true);
        checkNumber();

        // Simulating an asynchronous API call to send the OTP message
        setTimeout(() => {
            // After a delay, when the OTP message is sent
            setIsSending(false);

            // Additional logic for handling the OTP message being sent
        }, 60000); //only one minute
    };
    // end disable
    let input1Ref = useRef(null);
    let input2Ref = useRef(null);
    let input3Ref = useRef(null);
    let input4Ref = useRef(null);
    let combinedInputRef = useRef(null);
    const [combinedValue, setCombinedValue] = useState('');

    const handleKeyDown = (event, ref) => {
        if (event.key === 'Backspace' && ref && ref.current) {
            const previousRef = getPreviousRef(ref);
            if (previousRef) {
                event.preventDefault(); // Prevents the browser from navigating back
                previousRef.current.focus();
                // previousRef.current.value = '';
            }
        } else if (event.key !== 'Backspace' && event.key !== 'ArrowLeft' && ref && ref.current && ref.current.value.length >= 1) {
            const nextRef = getNextRef(ref);
            if (nextRef) {
                // event.preventDefault(); // Prevents the browser from moving the cursor
                nextRef.current.focus();
            }
        }
        // handleInputChange();
    };

    const getNextRef = (currentRef) => {
        if (currentRef === input1Ref) {
            return input2Ref;
        } else if (currentRef === input2Ref) {
            return input3Ref;
        } else if (currentRef === input3Ref) {
            return input4Ref;
        }
        return input4Ref; // No next input field
    };

    const getPreviousRef = (currentRef) => {
        if (currentRef === input2Ref) {
            input2Ref.current.value = '';
            return input1Ref;
        } else if (currentRef === input3Ref) {
            input3Ref.current.value = '';

            return input2Ref;
        } else if (currentRef === input4Ref) {
            input4Ref.current.value = '';

            return input3Ref;
        }
        input1Ref.current.value = '';
        return input1Ref; // No previous input field

    };
    let [phoneNumX5Inp, setPhoneNumX5Inp] = useState('')
    // const combinedInputRef = useRef(null);
    // const [combinedValue, setCombinedValue] = useState('');
    const handleInputChange = () => {

        // const { name, value } = e.target;
        // const mergedValue = phoneNumX5Inp + value;

        // setPhoneNumX5Inp(mergedValue);
        const input1Value = input1Ref.current.value;
        const input2Value = input2Ref.current.value;
        const input3Value = input3Ref.current.value;
        const input4Value = input4Ref.current.value;

        const phoneNumX5Inp = `${input1Value}${input2Value}${input3Value}${input4Value}`;
        setPhoneNumX5Inp(phoneNumX5Inp);

        if (combinedInputRef.current) {
            combinedInputRef.current.value = phoneNumX5Inp;
        }

        if (input1Value.length > 0) {
            // input2Ref.current.focus();
            return input2Ref;
        } else if (input2Value.length > 0) {
            // input3Ref.current.focus();
            return input3Ref;

        } else if (input3Value.length > 0) {
            // input4Ref.current.focus();
            return input4Ref;
        }

        // const myUserPhone = { ...formPhoneData };
        // myUserPhone.numX1 = e.target.value;
        // myUserPhone.numX2 = e.target.value;
        // myUserPhone.numX3 = e.target.value;
        // myUserPhone.numX4 = e.target.value;

        // setPinInp(myUserPhone.pin);

        // console.log(pinInp);
        // setPhoneNumX1Inp(myUserPhone.numX1);
        // setPhoneNumX2Inp(myUserPhone.numX2);
        // setPhoneNumX3Inp(myUserPhone.numX3);
        // setPhoneNumX4Inp(myUserPhone.numX4);
        // const allNum = phoneNumX1Inp + phoneNumX2Inp + phoneNumX3Inp + phoneNumX4Inp;
        // console.log(allNum);
        // setPhoneNumX5Inp(allNum);
        // setPhoneNumX1Inp(myUser.numX1)
        // setPhoneNumX2Inp(myUser.numX2)
        // setPhoneNumX3Inp(myUser.numX3)
        // setPhoneNumX4Inp(myUser.numX4)
        // console.log(phoneNumX1Inp);
        // console.log(phoneNumX2Inp);
        // console.log(phoneNumX3Inp);
        // console.log(phoneNumX4Inp);
        // console.log(phoneNumX5Inp);
        console.log(phoneNumX5Inp);
        // }
    };


    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({ pin: '' });
    const [formPhoneData, setFormPhoneData] = useState({ numX1: '', numX2: '', numX3: '', numX4: '' });
    const [formCodeData, setFormCodeData] = useState({ code: '' });


    const handleNextStep = () => {
        // if (pinError === true) {
        //     setStep(1);

        // } else {
        //     setStep(step + 1);

        if (pinError) {
            portals()
            // CheckPin();

        } else {
            portalsPin();
            CheckPin();

        }
    };

    const handlePreviousStep = () => {
        setStep(step - 1);
    };



    const typistRef = useRef(null);
    const navigate = useNavigate();
    let { lang } = useParams();
    // const { pathname } = useLocation();
    // window.alert(`${lang}`);

    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    const changeLanguage = (lang, dir) => {
        document.getElementsByTagName("html")[0].setAttribute("lang", lang);
        document.getElementsByTagName("html")[0].setAttribute("dir", dir);
    }

    // let { lang } = useParams();
    // console.log(lang);


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalShow, setModalShow] = React.useState(false);

    const [complain, setComplains] = useState(false);
    const complainClose = () => setComplains(false);
    const complains = () => setComplains(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    // const history = useHistory();
    const [portal, setPortals] = useState(false);
    const portalClose = () => {
        setPortals(false);
        setPinError(false);
        // setStep(1);
        setFormData({ pin: '' });
        setPinInp('')
    }

    const portals = () => {
        // if (localStorage.getItem("patientPortalToken") != null) {
        //     navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal`); // Replace '/your-link' with the desired link

        // } else {
        setFormData({ pin: '' });
        setPinInp('')
        setPinError(false);
        setPortals(true)
        // }




    };
    // const token = localStorage.getItem('patientPortalToken');

    const portalsNavigate = () => {

        // navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal`); // Replace '/your-link' with the desired link


    }

    const [portalPin, setPortalsPin] = useState(false);
    const portalPinClose = () => {
        setPortalsPin(false);
        setPhoneError(false);
        setFormPhoneData({ numX1: '', numX2: '', numX3: '', numX4: '' });
        setPhoneNumX5Inp('')
        // setStep(1);
        // setFormData({ pin: '', num1: '', num2: '', num3: '', num4: '', num5: '', num6: '', num7: '', numX1: '', numX2: '', numX3: '', numX4: ''});
    }

    const portalsPin = () => {
        setPhoneError(false);
        setFormPhoneData({ numX1: '', numX2: '', numX3: '', numX4: '' });
        setPhoneNumX5Inp('')
        setPortalsPin(true);

    }

    // const [codeError, setCodeError] = useState(false);

    const [portalPhone, setPortalsPhone] = useState(false);
    const portalPhoneClose = () => {
        setPortalsPhone(false);
        setCodeError(false);
        setFormData({ code: '' });
        setCodeInp('')

    }
    const portalsPhone = () => {
        setPortalsPhone(true);
        setCodeError(false);
        setFormPhoneData({ code: '' });
        setCodeInp('')
    }
    // axios for check pin
    const [patientPhoneFirst, setPatientPhoneFirst] = useState();
    const [patientPhoneLast, setPatientPhoneLast] = useState();
    const [pinError, setPinError] = useState(false);
    // const [pinInp, setPinInp] = useState({});
    const [phoneInp, setPhoneInp] = useState();
    //new 
    const [errorList, setErrorList] = useState([]);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [pin, setPin] = useState({
    //     pin: ''
    // });

    const [phoneNumX1Inp, setPhoneNumX1Inp] = useState('');
    const [phoneNumX2Inp, setPhoneNumX2Inp] = useState('');
    const [phoneNumX3Inp, setPhoneNumX3Inp] = useState('');
    const [phoneNumX4Inp, setPhoneNumX4Inp] = useState('');


    const handleChange = (e) => {
        // const { name, value } = e.target;

        // setFormData((prevData) => ({
        //    ...prevData,

        // }));
        let myUser = { ...formData };
        myUser.pin = e.target.value

        setPinInp(myUser.pin);

        console.log(pinInp);

    };
    // const handlePhoneChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    //     setPhoneInp(value);
    //     console.log('phoooooone' + phoneInp);
    // };


    // validate pin number
    function validationPinSchema() {
        let scheme = Joi.object({
            pinInp: Joi.number().integer().positive().required().min(0).max(10)
        });
        return scheme.validate(pinInp, { abortEarly: false });
        //  /   console.log(pinInp);

    }
    async function CheckPin() {
        // Request body
        // const requestBody = {
        //     'pin': 472506
        // }

        const bodyFormData = new FormData();

        // Append data to the FormData object
        bodyFormData.append('pin', pinInp);
        console.log(pinInp);

        const headers = {
            'Content-Type': 'multipart/form-data',

            'fingerprint': localStorage.getItem('fingerPrint'),
        };
        // Make the API request with the request body

        // headers removed
        // {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //         'fingerprint': localStorage.getItem('fingerPrint'),

        //     },
        // }

        await axios.post(`${baseUrl}/checkpin`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Emsg === 'Success') {
                    setPatientPhoneFirst(response.data.Edata.firstd)
                    setPatientPhoneLast(response.data.Edata.lastd)
                    console.log(response.data.Emsg);
                    console.log(response.data.Edata.firstd);
                    console.log(response.data.Edata.lastd);
                    setPinError(false);
                    setPortalsPhone(false);
                    setPortals(false);
                    setPortalsPin(true);
                    setIsLoading(false);


                } else {
                    setIsLoading(true);
                    setPortalsPhone(false);
                    setPortalsPin(false);
                    setPortals(true);
                    setPinError(response.data.Emsg)
                    // pinInp()
                    setPinInp({})


                }
            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });
        // let { data } = await axios.post(`${baseUrl}/checkpin`);
        // callback(data.Edata);
        // // setLoading(false);
        // // console.log('videos by chars');

        // console.log(data.Edata);
    }


    function submitLoginForm(e) {
        e.preventDefault();

        setIsLoading(true);

        let validation = validationPinSchema();
        if (pinError) {
            setIsLoading(false);
            setErrorList(validation.error.details);

        } else {

            CheckPin();
            setIsLoading(false);

        }
        // if (pinInp) {
        //     CheckPin();
        // }
    }

    // validate phone number
    function validationPhoneSchema() {
        let scheme = Joi.object({
            phoneNumX1Inp: Joi.number().integer().positive().required().min(1).max(1),
            phoneNumX2Inp: Joi.number().integer().positive().required().min(1).max(1),
            phoneNumX3Inp: Joi.number().integer().positive().required().min(1).max(1),
            phoneNumX4Inp: Joi.number().integer().positive().required().min(1).max(1),
        });
        console.log(phoneNumX5Inp);
        return scheme.validate(phoneNumX1Inp, phoneNumX2Inp, phoneNumX3Inp, phoneNumX4Inp, { abortEarly: false });


    }
    function submitPhoneNumberForm(e) {
        e.preventDefault();

        setIsLoading(true);

        let validation = validationPhoneSchema();
        if (phoneError) {
            setIsLoading(false);
            setPortalsPhone(false);
            setPortalsPin(true);
            setErrorList(validation.error.details);

        } else {

            checkNumber();
            setIsLoading(false);

        }
        // if (phoneNumX5Inp) {
        //     checkNumber();
        // }
    }
    let [phoneError, setPhoneError] = useState(false);
    async function checkNumber() {
        // Request body
        // event.preventDefault(); // Prevents the browser from navigating back

        // if (event.target.value) {
        const headers = {
            'Content-Type': 'multipart/form-data',

            'fingerprint': localStorage.getItem('fingerPrint'),
        };
        const bodyFormData = new FormData();

        // Append data to the FormData object
        bodyFormData.append('pin', pinInp);
        bodyFormData.append('mnumber', phoneNumX5Inp);

        await axios.post(`${baseUrl}/checknumber`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Emsg === 'Success') {

                    console.log(response.data.Emsg);
                    setPhoneError(false);
                    setPortalsPhone(true);
                    setPortals(false);
                    setPortalsPin(false);
                    setIsLoading(false);



                } else {
                    setPhoneError(response.data.Emsg);

                    setIsLoading(true);
                    setPortalsPhone(false);
                    setPortalsPin(true);
                    setPortals(false);
                    setPinError(response.data.Emsg)
                    setPhoneNumX5Inp('');



                }
                console.log('phoooooone' + phoneNumX5Inp);

            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });

        console.log('phoooooone' + phoneNumX5Inp);

        // }
    }

    //  code function 
    const [codeInp, setCodeInp] = useState({});

    const handleCodeChange = (e) => {
        // const { name, value } = e.target;

        // setFormData((prevData) => ({
        //    ...prevData,

        // }));
        let myUserCode = { ...formCodeData };
        myUserCode.code = e.target.value

        setCodeInp(myUserCode.code);

        console.log(codeInp);

    };
    // useEffect(() => {

    // }, []);
    const [codeError, setCodeError] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (codeError) {
            setIsLoading(false);
            setPortalsPin(false);
            setPortals(false);
            setPortalsPhone(true);
            // setErrorList(validation.error.details);

        } else {

            checkCode();
            setIsLoading(false);

        }
        // if (codeInp) {
        //     checkCode();
        // }
        // Handle form submission

        // <Navigate to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal/patient_portal_home`} />
    };
    async function checkCode() {
        const bodyFormData = new FormData();
        const phone = `${patientPhoneFirst}${phoneNumX5Inp}${patientPhoneLast}`;
        // Append data to the FormData object
        bodyFormData.append('pin', pinInp);
        bodyFormData.append('otpcode', codeInp);
        bodyFormData.append('phone', phone);
        console.log(phone);
        const headers = {
            'Content-Type': 'multipart/form-data',

            'fingerprint': localStorage.getItem('fingerPrint'),
        };
        await axios.post(`${baseUrl}/checkcode`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Emsg === 'success') {
                    localStorage.setItem("patientPortalToken", response.data.Edata.token);
                    console.log('userdata ' + userData);


                    navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal`)

                    console.log(response.data.Emsg);
                    setCodeError(false);
                    setPortalsPhone(false);
                    setPortals(false);
                    setPortalsPin(false);
                    setIsLoading(false);
                    // portalClose();
                    // portalPhoneClose();
                    document.getElementById('patientPortalModale').classList.add('d-none');
                    document.getElementById('patientportalPinModale').classList.add('d-none');
                    document.getElementById('patientportalPhoneModale').classList.add('d-none');
                    document.getElementById('normal-nav').classList.add('d-none');
                    document.getElementById('patient-portal-nav').classList.remove('d-none');
                    document.getElementById('alex-nav').classList.add('d-none');
                    document.getElementById('cairo-nav').classList.add('d-none');
                    document.getElementById('referTo').classList.remove('d-none');
                    // console.log(formData);
                    // saveUserData();


                } else {
                    setPhoneError(response.data.Emsg);

                    setIsLoading(true);
                    setPortalsPhone(true);
                    setPortalsPin(false);
                    setPortals(false);
                    setPinError(response.data.Emsg)
                    // pinInp()
                    // setPinInp({})
                    setCodeInp('');



                }
                // console.log('phoooooone' + phoneNumX5Inp);

            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });

        console.log('phoooooone' + phoneNumX5Inp);


    }

    // function confirmPhoneNumber() {
    //     setPortalsPhone(true)
    //     checkNumber();
    // }

    const token = localStorage.getItem('patientPortalToken');
    useEffect(() => {
        // CheckPin();
        // console.log(localStorage.getItem('fingerPrint'));

        // Remove data after one hour
        const timeoutId = setTimeout(() => {
            if (token) {
                localStorage.removeItem('patientPortalToken');
            }
        }, 60 * 60 * 1000); // 1 hour in milliseconds

        // Clean up the timeout when the component unmounts or when the data is removed manually
        return () => clearTimeout(timeoutId);

    }, [token])

    // profile portal

    let [vitalSummary, setvitalSummary] = useState([]);


    async function getsummary() {
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('pin', pinInp);

        const headers = {
            'Content-Type': 'multipart/form-data',

            'fingerprint': localStorage.getItem('fingerPrint'),
            'token': localStorage.getItem('patientPortalToken'),
        };
        await axios.post(`${baseUrl}/patientportal/getsummery`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                setvitalSummary(response.data.Edata.vital);
                console.log(response.data.Edata.vital);
            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });

        // calcBmi();
    }

    useEffect(() => {
        if (portal === true) {
            getsummary();
        }
    }, [portals])

    useEffect(() => {
        // document.querySelector('.opened').style.color = '';
    }, [])




    return <>

        {/* <!--footer start--> */}

        <footer className="footer grey-bg">
            <div className="primary-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-logo mb-3">
                                {pathname.includes('cairo') ? <img id="footer-logo-img" className="img-fluid" src='/images/logos/SGH-cairo-logo-02-min.webp' alt="" /> : pathname.includes('alex') ? <img id="footer-logo-img" className="img-fluid" src='/images/logos/logos_alex_branding-min.webp' alt="" /> : <img id="footer-logo-img" className="img-fluid" src='/images/logos/Health logo of egypt-min.webp' alt="" />}
                            </div>
                            {pathname.includes('cairo') ? <p >{t("Saudi German Hospital Cairo, part of the Middle East's leading healthcare group, is a landmark tertiary care hospital in Africa.")} </p>
                                : pathname.includes('alex') ? <p >{t('Saudi German Hospital Alexandria, the second branch of Saudi German Hospitals Group in Egypt and the 14th branch in the MENA Region.')}</p>
                                    : <p >{t('Saudi German Health is a prominent healthcare organization dedicated to providing high-quality medical services in the Kingdom of Saudi Arabia and beyond.')}</p>}

                            <Link className="btn-simple" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about`} onClick={scrollToTop}><span>{t('Read More')}
                                {i18n.language === 'en' ? <i className="ms-2 fas fa-long-arrow-alt-right"></i> : <i className="ms-2 fas fa-long-arrow-alt-left"></i>}
                            </span></Link>

                            <div className="footer-logo mt-3">
                                {pathname.includes('cairo') ? <img id="footer-logo-img" className="img-fluid" src="/images/about/partners-section/Mayo-clinic-min.webp" alt="" /> : ''}
                            </div>
                            {pathname.includes('alex') ? <img id="gahar-logo-img" className="img-fluid h-0" src="/images/logos/GAHAR Alex-min.webp" alt="" /> : ''}

                        </div>
                        <div className="col-lg-3 col-md-6 mt-5 mt-md-0 footer-list ps-lg-5">
                            <h5>{t('ACTIONS')} <span className="text-theme"></span></h5>
                            <ul className="list-unstyled">
                                {/* <li onClick={scrollToTop}><Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/media-center`}>Media Center</Link>
                                </li> */}
                                <li onClick={scrollToTop}><Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-and-visitors/request-an-appointment`}>{t('Book Appointment')}</Link>
                                </li>
                                <li ><Link onClick={portals} to=''>{t('Patient Portal')}</Link>
                                </li>
                                <li onClick={scrollToTop}><Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/be-our-partner`}>{t('Be Our Partner')}</Link>
                                </li>
                                <li onClick={scrollToTop}><Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/join-us`}>{t('Join Us')}</Link>
                                </li>
                                <li onClick={scrollToTop}><Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/contact`}>{t('Contact Us')}</Link>
                                </li>


                                {/* <li onClick={scrollToTop}><Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/refer-patient`}>Refer a patient</Link>
                                </li> */}
                                {/* <li><Link to="diagnostic-depertment.html">Diagnostic Depertment</Link>
                                </li> */}
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-12 mt-5 mt-lg-0 ps-lg-5">
                            <div className="row">
                                <div className="col-sm-5 footer-list">
                                    {i18n.language === 'en' ? <h5>{t('QUICK')} <span className="text-theme">{t('LINKS')}</span></h5> : <h5>{t('QUICK LINKS')}</h5>}
                                    <ul className="list-unstyled">
                                        <li onClick={scrollToTop}><Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about`} >{t('About Us')}</Link>
                                        </li>
                                        <li onClick={scrollToTop}><Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/faq`}>{t('FAQs')}</Link>
                                        </li>
                                        <li onClick={scrollToTop}><Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/policy`}>{t('Privacy Policy')}</Link>
                                        </li>
                                        <li onClick={scrollToTop}><Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/terms-conditions`}>{t('Terms & Condition')}</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-7 mt-5 mt-sm-0">
                                    {i18next.language === 'en' ? <h5>{t('Get In')} <span className="text-theme"> {t('Touch')}</span></h5> : <h5>{t('Touch')} <span className="text-theme">{t('Get In')} </span></h5>}
                                    <ul className="media-icon list-unstyled">
                                        <a href='https://goo.gl/maps/hAbQWB8g7SAhJPNJ7' target='_blank'>   <li className="mb-4"> <i className="flaticon-paper-plane"></i>

                                            <p className="mb-0">{t('Josef Teto street Nozha, Heliopolis, Cairo, Egypt')}</p>
                                        </li></a>
                                        <a href='https://maps.app.goo.gl/L1jCR7fFtSdfBUGLA' target='_blank'>  <li className="mb-4">
                                            <i className="flaticon-paper-plane"></i>
                                            <p className="mb-0">{t('Kilo 23 Alex Matrouh coastal Road, Compound Alex West')}</p>
                                        </li></a>
                                        <li className="mb-4"> <i className="flaticon-email fa-fade"></i>
                                            <Link to="mailto:info.cai@sghgroup.net">{t('info.egypt@sghgroup.net')}</Link>
                                        </li>
                                        <li> <i class="flaticon-phone-call shake"></i>
                                            <Link to="tel:+16259">{t('16259')}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col">

                                    <Subscribe />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <div className="social-icons social-colored social-fullwidth">
                                <ul className="list-inline mb-0">
                                    <li onClick={scrollToTop} className="social-facebook"><Link to="https://www.facebook.com/SaudiGermanHospitalEgypt"><i className="fab fa-facebook-f"></i></Link>
                                    </li>
                                    <li onClick={scrollToTop} className="social-twitter"><Link to="https://www.instagram.com/sgh.egypt/"><i class="fab fa-instagram" aria-hidden="true"></i>
                                    </Link>
                                    </li>
                                    <li onClick={scrollToTop} className="social-gplus"><Link to="https://www.youtube.com/@SaudiGermanHospitalEgypt"><i class="fab fa fa-youtube" aria-hidden="true"></i></Link>
                                    </li>
                                    <li onClick={scrollToTop} className="social-linkedin"><Link to="https://www.linkedin.com/company/saudi-german-hospital-egypt"><i className="fab fa-linkedin-in"></i></Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="secondary-footer text-center">
                <div className="container">
                    <div className="copyright">
                        <div className="row">
                            <div className="col"> <span>{t('Copyright 2023 by')} <Link target="_blank" to="/"> {t('SAUDI GERMAN HOSPITALS')} </Link>  | {t('All Rights Reserved')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        {/* <!--footer end--> */}


        {/* <!--page wrapper end-- > */}


        {/* <!--color-customizer start--> */}

        <div className="color-customizer closed d-none d-md-block">
            <div className="color-button">
                <Link className="opener" to="">{t('Book Now')}
                </Link>
            </div>


            <div className="clearfix color-chooser text-center">

                <div className="text-center mt-4">

                    {/* <!-- Modal start --> */}

                    <div className="" id="appointment-modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content" style={{ border: "none" }}>
                                <div className="modal-header">
                                    <h5 className="modal-title title" id="exampleModalLabel" style={{ fontSize: "20px" }}>{t('Make Appointment')} <span>{t('NOW!')}</span></h5>
                                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                </div>
                                <div className="modal-body px-0">
                                    <form className="row" method="post" onSubmit={doctorSearch}>
                                        <div className="messages"></div>
                                        <div className="form-group justify-content-center mb-4 clearfix col-md-12 d-flex align-items-center justify-content-between" >
                                            <i className="flaticon-paper-plane text-main" style={{ fontSize: '18px' }}></i>
                                            <Select placeholder={t("Select Branch")} options={brancheOptions} onChange={handleSelectBranchChange} value={selectedBranch} className="w-100 px-2" styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',


                                                    outline: state.isFocused ? ' none' : 'none',

                                                }),
                                                option: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: state.isFocused ? '#409639' : ' ',

                                                    color: state.isFocused ? '#fff' : '',

                                                }),

                                            }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none" }}>

                                            </Select>
                                        </div>
                                        <div className="form-group justify-content-center clearfix col-md-12 d-flex align-items-center justify-content-between">
                                            <i className="flaticon-stethoscope text-main" style={{ fontSize: '18px' }}></i>
                                            <Select style={{ borderTop: "none", borderRight: "none", borderLeft: "none" }} placeholder={t("Select Department")} options={DepartmentsOptions}
                                                onChange={handleSelectChange} value={selectedOption} className="w-100 px-2" styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                                        outline: state.isFocused ? ' none' : 'none',
                                                    }),
                                                    option: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        // borderColor: state.isFocused ? '#409639' : ' hsl(0, 0%, 80%)',
                                                        backgroundColor: state.isFocused ? '#409639' : ' ',


                                                        color: state.isFocused ? '#fff' : '',

                                                    }),
                                                }} >

                                            </Select>
                                        </div>
                                        <div className="form-group justify-content-center clearfix col-md-12 d-flex align-items-center  justify-content-between">
                                            <i className="flaticon-doctor text-main" style={{ fontSize: '18px' }}></i>
                                            <Select placeholder={t("Select physician")} options={DoctorsOptions} onChange={handleDoctorSelectChange} className="w-100 px-2" styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? ' #409639' : ' hsl(0, 0%, 80%)',

                                                    outline: state.isFocused ? ' none' : 'none',

                                                }),
                                                option: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: state.isFocused ? '#409639' : ' ',
                                                    color: state.isFocused ? '#fff' : '',

                                                }),
                                            }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none" }}>

                                            </Select>
                                        </div>

                                        <div className="col-sm-12 mt-3">
                                            {selectedBranch.value === '1' ? <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`} className='p-0'> <button className="btn btn-theme btn-radius" style={{ zIndex: '0' }} type='submit'><span>{t('Make Appointment')}</span>
                                            </button>
                                            </Link> : <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/doctors`} className='p-0'> <button className="btn btn-theme btn-radius" style={{ zIndex: '0' }} type='submit'><span>{t('Make Appointment')}</span>
                                            </button>
                                            </Link>}
                                            {/* <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/doctors`} className='p-0'><button className="btn btn-theme btn-radius" style={{ zIndex: '0' }} type='submit'><span>Make Appointment</span>
                                            </button></Link> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Modal end --> */}
                </div>


            </div >
        </div >

        {/* <i className="floating-icon fa-solid fa-up-right-from-square" id='redirect'>

        <div id="onhoverfb">Saudi German Health</div>
          

        </i> */}

        {/* <!--color-customizer end--> */}


        {/* <!--back-to-top start--> */}
        {/* <div className="color-customizer closed"> */}

        {/* </div> */}
        {/* <!--back-to-top end--> */}

        <Modal show={portal} onHide={portalClose} id="patientPortalModale" backdrop="static">
            <Modal.Header closeButton className='d-flex justify-content-between align-items-center'>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title style={{ color: "#4ab242" }}>{t('Patients Portal')}</Modal.Title>

            </Modal.Header>
            <Modal.Body >

                {/* <Form className='row justify-content-center'>

                                                        <Form.Group
                                                            className="mb-3 col-md-6"
                                                            controlId="ControlText"
                                                        >

                                                            <Form.Control type="text" placeholder="PIN Number"
                                                            />
                                                        </Form.Group>
                                                    </Form> */}
                {pinError ? <div className="portal-error text-white my-2">{t('ERROR!')} {pinError}</div> : ''}

                <Form className='row justify-content-center align-items-end flex-column' onSubmit={submitLoginForm}>
                    <Form.Group
                        className=" col-md-6 mx-auto ms-3 mb-5"
                        controlId="ControlText"
                    >

                        <Form.Control type="text" name="pin" onChange={handleChange} placeholder={t("PIN Number")}
                        />
                        {/* <small className="text-danger">
                                                                {
                                                                    errorList.filter((error) => error.context.label === "pin")[0]
                                                                        ?.message
                                                                }
                                                            </small> */}
                    </Form.Group>
                    {/* {pinError ? <small className='text-danger mt-3 col-md-8 mx-auto text-center mb-3'>{pinError}</small> : ''} */}

                    <Button className='col-md-3 me-3 btn btn-theme btn-sm appoint-btn mt-5 mt-sm-0 rounded-3 ' type="submit" >
                        {t('Next')}
                    </Button>


                </Form>



            </Modal.Body>
            {/* <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={portalClose}>
                                                        Close
                                                    </Button> */}
            {/* <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={navbar}>
                                                        Next
                                                    </Button> */}
            {/* </Modal.Footer> */}
        </Modal>

        <Modal show={portalPin} onHide={portalPinClose} id="patientportalPinModale" backdrop="static">
            <Modal.Header closeButton className='d-flex justify-content-between align-items-center'>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title style={{ color: "#4ab242" }}>{t('Patients Portal')}</Modal.Title>

            </Modal.Header>
            <Modal.Body >

                {/* <Form className='row justify-content-center'>

                                                        <Form.Group
                                                            className="mb-3 col-md-6"
                                                            controlId="ControlText"
                                                        >

                                                            <Form.Control type="text" placeholder="PIN Number"
                                                            />
                                                        </Form.Group>
                                                    </Form> */}
                {phoneError ? <div className="portal-error text-white my-2">{t('ERROR!')} {phoneError}</div> : ''}

                <Form className='row justify-content-center' onSubmit={submitPhoneNumberForm}>
                    <p className='text-center lead'>{t('please enter your phone number to Send SMS with Activation Code')}</p>

                    <Form.Group
                        className="mb-5 col-md-12 otp-container"
                        controlId="ControlText"
                    >
                        {/* <div className="otp-container"> */}
                        {/* <Form.Control className="otp-input" type="text" maxLength="1" name="num1" value='0' onChange={handleChange} />
                                                                <Form.Control className="otp-input" type="text" maxLength="1" name="num2" value='1' onChange={handleChange} />
                                                                <Form.Control className="otp-input" type="text" maxLength="1" name="num3" value='5' onChange={handleChange} />
                                                                <Form.Control className="otp-input" type="text" maxLength="1" name="num4" value='5' onChange={handleChange} /> */}
                        <span className="otp-input me-2">{patientPhoneFirst}</span>
                        <Form.Control className="otp-input border-bottom" placeholder='x' type="text" maxLength="1" defaultValue={formPhoneData.numX1} name="numX1" ref={input1Ref}
                            onKeyDown={(event) => handleKeyDown(event, input1Ref)}
                            onChange={handleInputChange} />
                        <Form.Control className="otp-input border-bottom" placeholder='x' type="text" maxLength="1" defaultValue={formPhoneData.numX2} name="numX2" ref={input2Ref}
                            onKeyDown={(event) => handleKeyDown(event, input2Ref)}
                            onChange={handleInputChange} />
                        <Form.Control className="otp-input border-bottom" placeholder='x' type="text" maxLength="1" defaultValue={formPhoneData.numX3} name="numX3" ref={input3Ref}
                            onKeyDown={(event) => handleKeyDown(event, input3Ref)}
                            onChange={handleInputChange} />
                        <Form.Control className="otp-input border-bottom" placeholder='x' type="text" maxLength="1" defaultValue={formPhoneData.numX4} name="numX4" ref={input4Ref}
                            onKeyDown={(event) => handleKeyDown(event, input4Ref)}
                            onChange={handleInputChange} />
                        <Form.Control type='hidden'
                            ref={combinedInputRef}
                            value={phoneNumX5Inp}
                            onChange={() => { }}
                        />
                        {/* <Form.Control className="otp-input border-bottom" placeholder='x' type="hidden" maxLength="4"
                                                                onKeyDown={(event) => handleKeyDown(event, input4Ref)}
                                                                onChange={(event) => handleInputChange(event, input4Ref)} /> */}

                        <span className="otp-input ms-2">{patientPhoneLast}</span>

                        {/* <Form.Control className="otp-input" type="text" maxLength="1" name="num5" value='2' onChange={handleChange} />
                                                                <Form.Control className="otp-input" type="text" maxLength="1" name="num6" value='8' onChange={handleChange} />
                                                                <Form.Control className="otp-input" type="text" maxLength="1" name="num7" value='5' onChange={handleChange} /> */}
                        {/* </div> */}
                        {/* <Form.Control type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder='Phone number'/> */}

                    </Form.Group>
                    <div className='me-3 d-flex justify-content-end'>


                        <Button className='col-md-3 btn btn-theme btn-sm appoint-btn mt-5 mt-sm-0 rounded-3' type="submit" >
                            {t('Confirm')}
                        </Button>

                    </div>

                </Form>


            </Modal.Body>
            {/* <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={portalClose}>
                                                        Close
                                                    </Button> */}
            {/* <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={navbar}>
                                                        Next
                                                    </Button> */}
            {/* </Modal.Footer> */}
        </Modal>

        <Modal show={portalPhone} onHide={portalPhoneClose} id="patientportalPhoneModale" backdrop="static">
            <Modal.Header closeButton className='d-flex justify-content-between align-items-center'>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title style={{ color: "#4ab242" }}>{t('Patients Portal')}</Modal.Title>

            </Modal.Header>
            <Modal.Body >

                {/* <Form className='row justify-content-center'>

                                                        <Form.Group
                                                            className="mb-3 col-md-6"
                                                            controlId="ControlText"
                                                        >

                                                            <Form.Control type="text" placeholder="PIN Number"
                                                            />
                                                        </Form.Group>
                                                    </Form> */}
                {codeError ? <div className="portal-error text-white my-2">{t('ERROR!')} {codeError}</div> : ''}

                <Form className='row justify-content-center align-items-center flex-column' onSubmit={handleSubmit}>
                    <p className='text-center lead'>{t('please enter your Activation Code')}</p>

                    <Form.Group
                        className="mb-5 col-md-6 ms-3"
                        controlId="ControlText"
                    >

                        <Form.Control type='text' maxLength={6} name="code" value={formData.code} onChange={handleCodeChange} placeholder={t('Code')}
                        />
                    </Form.Group>
                    <div className='me-3 d-flex justify-content-end'>
                        <Button className='col-md-3 btn btn-theme btn-sm appoint-btn mt-5 mt-sm-0 rounded-3' type="button" onClick={handleSendOTP} disabled={isSending}>
                            {isSending ? t('Sending...') : t('Resend')}

                        </Button>
                        <Button className='col-md-3 btn btn-theme btn-sm appoint-btn mt-5 mt-sm-0 rounded-3' type="submit" >
                            {t('Confirm')}
                        </Button>
                    </div>

                </Form>


            </Modal.Body>
            {/* <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={portalClose}>
                                                        Close
                                                    </Button> */}
            {/* <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={navbar}>
                                                        Next
                                                    </Button> */}
            {/* </Modal.Footer> */}
        </Modal>
    </>
}

import axios from "axios";
import React, { createContext, useState } from "react";
// import { LocalizationContext } from "./LocalizationContext";

export let GetCategoriesContext = createContext('');

function GetCategoriesContextProvider(props) {
    // let { i18n } = useContext(LocalizationContext);

    // let [t, i18n] = useTranslation(0);
    // const [trendingMovies, setTrendingMovies] = useState([]);
    // const [trendingTv, setTrendingTv] = useState([]);
    const [symptom, setSymptom] = useState([]);
    const [article, setArticle] = useState([]);
    const [disease, setDisease] = useState([]);
    const [firstAid, setFirstAid] = useState([]);
    const [recipe, setRecipe] = useState([]);
    const [testProcedure, setTestProcedure] = useState([]);
    const [slideShow, setSlideShow] = useState([]);

    const [videos, setVideos] = useState([]);
    async function getCategoryByChars(category, callback) {
        let { data } = await axios.get(`http://130.7.1.27/mayo/public/get_cat/${category}/${document.getElementsByTagName("html")[0].getAttribute("lang")}`);
        callback(data.Edata);
        // setLoading(false);
        // console.log('videos by chars');

        // console.log(data.Edata);
    }

    // useEffect(() => {
    //     getCategoryByChars("Video", setVideos);
    //     getCategoryByChars("Symptom", setSymptom);
    //     getCategoryByChars("Article", setArticle);
    //     getCategoryByChars("Disease", setDisease);
    //     getCategoryByChars("FirstAid", setFirstAid);
    //     getCategoryByChars("Recipe", setRecipe);
    //     getCategoryByChars("TestProcedure", setTestProcedure);
    //     getCategoryByChars("SlideShow", setSlideShow);

    // }, []);
    return <GetCategoriesContext.Provider value={{
        videos,
        setVideos,
        symptom,
        setSymptom,
        article,
        setArticle,
        firstAid,
        setFirstAid,
        disease,
        setDisease,
        recipe,
        setRecipe,
        testProcedure,
        setTestProcedure,
        slideShow,
        setSlideShow,
        getCategoryByChars,
    }}>
        {props.children}
    </GetCategoriesContext.Provider>

}
export default GetCategoriesContextProvider;
import React, { useContext } from 'react'
import PageTitle from '../../Utilites/PageTitle'
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../../Context/LocalizationContext';

export default function OurPartners() {
    // translation 
    const { t } = useTranslation("About");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    return <>
        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={t('Our')} anotherTitle={t('Partners')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about`} prevPage={t('About Us')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners`} currentPage={t('Our Prtners')} /> : <PageTitle title={t('Our Prtners')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about`} prevPage={t('About Us')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners`} currentPage={t('Our Prtners')} />}        {/* <!--page title end--> */}
        <div class="page-content">

            {/* <!--blog style1 start--> */}

            <section>
                <div class="container">
                    <div class="row align-items-center gx-3 text-justify">
                        {/* <div className="section-title mb-4">
                            <h2 className="title">Our Partners and Affiliates</h2>

                        </div> */}
                        <div class="col-lg-10">
                            <div class="post style-2">
                                <div class="row g-0 align-items-center">
                                    <div class="col-md-3">
                                        <div class="post-image h-100">
                                            <img class="img-fluid h-50 w-100" src="/images/about/partners and Afillities/Mayo-clinic-min.webp" alt="Mayo Clinic" />
                                        </div>
                                    </div>
                                    <div class="col-md-9 align-item-middle white-bg">
                                        <div>
                                            <div class="post-desc">
                                                {/* <div class="post-meta">
                                                    <ul class="list-inline">
                                                        <li>23 April, 2019</li>
                                                        <li>Comment</li>
                                                    </ul>
                                                </div> */}
                                                <div class="post-title">
                                                    <h5><Link to="">{t('Mayo Clinic Care Network MCCN')}</Link></h5>
                                                </div>
                                                <p>{t('Pioneering healthcare across continents, Saudi German Health Egypt stands tall as the first hospital in Africa and the 3rd in the Middle East to join the prestigious Mayo Clinic Care Network. This exclusive partnership brings the renowned expertise and cutting-edge practices of Mayo Clinic directly to patients in Egypt, offering unparalleled access to world-class medical care for all.')}
                                                </p>
                                                {/* <Link class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/mayo-clinic`} onClick={scrollToTop}>Read More<i class="ms-2 fas fa-long-arrow-alt-right"></i></Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-10">
                            <div class="post style-2 mt-4">
                                <div class="row g-0 align-items-center">
                                    <div class="col-md-3">
                                        <div class="post-image h-100">
                                            <img class="img-fluid h-50 w-100" src="/images/about/partners and Afillities/ACHS-international--min.webp" alt="ACHS" />
                                        </div>
                                    </div>
                                    <div class="col-md-9 align-item-middle white-bg">
                                        <div>
                                            <div class="post-desc">
                                                {/* <div class="post-meta">
                                                    <ul class="list-inline">
                                                        <li>23 April, 2019</li>
                                                        <li>Comment</li>
                                                    </ul>
                                                </div> */}
                                                <div class="post-title">
                                                    <h5><Link to="">{t('Australian Council for Healthcare Standards ACHS')}</Link></h5>
                                                </div>
                                                <p>{t('Elevating care with global partnership: Saudi German Health Egypt proudly holds its place as a key partner with the prestigious Australian Council for Healthcare Standards International (ACHSI). This collaboration signifies a commitment to exceeding international benchmarks in healthcare excellence, ensuring patients receive world-class treatment delivered with the utmost professionalism and care. Through this partnership, Saudi German Health Egypt continues to raise the bar for healthcare in Egypt and across the region .')}
                                                </p>
                                                {/* <Link class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/australian-council-on-health-care-standards`} onClick={scrollToTop}>Read More<i class="ms-2 fas fa-long-arrow-alt-right"></i></Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-10">
                            <div class="post style-2  mt-4">
                                <div class="row g-0 align-items-center">
                                    <div class="col-md-3">
                                        <div class="post-image h-100">
                                            <img class="img-fluid h-50 w-100" src="/images/about/partners and Afillities/IHF--min.webp" alt="IHF" />
                                        </div>
                                    </div>
                                    <div class="col-md-9 align-item-middle white-bg">
                                        <div>
                                            <div class="post-desc">
                                                {/* <div class="post-meta">
                                                    <ul class="list-inline">
                                                        <li>23 April, 2019</li>
                                                        <li>Comment</li>
                                                    </ul>
                                                </div> */}
                                                <div class="post-title">
                                                    <h5><Link to="">{t('International Hospital Federation IHF')}</Link></h5>
                                                </div>
                                                <p>{t('Championing global healthcare collaboration, Saudi German Health Egypt stands as a proud partner of the International Hospital Federation. This prestigious alliance allows them to exchange knowledge, share best practices, and advance healthcare standards – not just in Egypt, but across the globe. Through this dedicated partnership, Saudi German Health Egypt contributes to shaping a brighter future for healthcare for all.')}
                                                </p>
                                                {/* <Link class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/international-hospital-federation`} onClick={scrollToTop}>Read More<i class="ms-2 fas fa-long-arrow-alt-right"></i></Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-10">
                            <div class="post style-2 mt-4">
                                <div class="row g-0 align-items-center">
                                    <div class="col-md-3">
                                        <div class="post-image h-100">
                                            <img class="img-fluid h-50 w-100" src="/images/about/partners and Afillities/geneva-sustainbility-centre-min.webp" alt="Geneva Sustainbility Centre" />
                                        </div>
                                    </div>
                                    <div class="col-md-9 align-item-middle white-bg">
                                        <div>
                                            <div class="post-desc">
                                                {/* <div class="post-meta">
                                                    <ul class="list-inline">
                                                        <li>23 April, 2019</li>
                                                        <li>Comment</li>
                                                    </ul>
                                                </div> */}
                                                <div class="post-title">
                                                    <h5><Link to="">{t('Geneva Sustainability Center GSC')}</Link></h5>
                                                </div>
                                                <p>{t('Leading the way in sustainable healthcare, Saudi German Health Egypt proudly collaborates with the Geneva Sustainability Center. This dynamic partnership fosters green initiatives, reduces their environmental footprint, and ensures a healthier future for patients and the planet. From energy-efficient practices to waste reduction programs, Saudi German Health sets a new standard for environmentally responsible healthcare in Egypt.')} </p>
                                                {/* <Link class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/gsc`} onClick={scrollToTop}>Read More<i class="ms-2 fas fa-long-arrow-alt-right"></i></Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-10">

                            <div class="post style-2 mt-4">
                                <div class="row g-0 align-items-center">
                                    <div class="col-md-3">
                                        <div class="post-image h-100">
                                            <img class="img-fluid h-50 w-100" src="/images/about/partners and Afillities/american-university--min.webp" alt="American university" />
                                        </div>
                                    </div>
                                    <div class="col-md-9 align-item-middle white-bg">
                                        <div>
                                            <div class="post-desc">
                                                {/* <div class="post-meta">
                        <ul class="list-inline">
                            <li>23 April, 2019</li>
                            <li>Comment</li>
                        </ul>
                    </div> */}
                                                <div class="post-title">
                                                    <h5><Link to="">{t('American University in Cairo AUC')}</Link></h5>
                                                </div>
                                                <p>{t('Saudi German Health Egypt joins hands with the esteemed American University in Cairo (AUC). This dynamic partnership fosters academic excellence with Practical Training in the Executive Management Diplomas.')}
                                                </p>
                                                {/* <Link class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/american-university-in-cairo`} onClick={scrollToTop}>Read More<i class="ms-2 fas fa-long-arrow-alt-right"></i></Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-10">

                            <div class="post style-2 mt-4">
                                <div class="row g-0 align-items-center">
                                    <div class="col-md-3">
                                        <div class="post-image h-100">
                                            <img class="img-fluid h-50 w-100" src="/images/about/partners and Afillities/GUC--min.webp" alt="GUC" />
                                        </div>
                                    </div>
                                    <div class="col-md-9 align-item-middle white-bg">
                                        <div>
                                            <div class="post-desc">
                                                {/* <div class="post-meta">
                        <ul class="list-inline">
                            <li>23 April, 2019</li>
                            <li>Comment</li>
                        </ul>
                    </div> */}
                                                <div class="post-title">
                                                    <h5><Link to="">{t('German University in Cairo GUC')}</Link></h5>
                                                </div>
                                                <p>{t("Saudi German Health Egypt and the German University in Cairo's Faculty of Pharmacy forge a powerful partnership. Undergraduate pharmacists gain invaluable hands-on training within the hospital pharmacy, immersing themselves in the inner workings of inpatient, outpatient pharmacy, IV Admixture, medication safety, and clinical Pharmacy. This dynamic collaboration nurtures the next generation of pharmacy professionals, equipping them with the skills and knowledge to excel in tomorrow's healthcare landscape.")} </p>
                                                {/* <Link class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/guc`} onClick={scrollToTop}>Read More<i class="ms-2 fas fa-long-arrow-alt-right"></i></Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-10">

                            <div class="post style-2 mt-4">
                                <div class="row g-0 align-items-center">
                                    <div class="col-md-3">
                                        <div class="post-image h-100">
                                            <img class="img-fluid h-50 w-100" src="/images/about/partners-section/Arab-Academy-_full-colour-min.webp" alt="Arab Academy" />
                                        </div>
                                    </div>
                                    <div class="col-md-9 align-item-middle white-bg">
                                        <div>
                                            <div class="post-desc">
                                                {/* <div class="post-meta">
                                                    <ul class="list-inline">
                                                        <li>23 April, 2019</li>
                                                        <li>Comment</li>
                                                    </ul>
                                                </div> */}
                                                <div class="post-title">
                                                    <h5><Link to="">{t('Arab Academy for Science & Technology AAST')}</Link></h5>
                                                </div>
                                                <p>{t('Saudi German Health Egypt joins hands with the prestigious Arab Academy for Science and Technology. This dynamic partnership fosters a Master in Business Administration in Hospital Management.')}</p>
                                                {/* <Link class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/about/our-partners/aast`} onClick={scrollToTop}>Read More<i class="ms-2 fas fa-long-arrow-alt-right"></i></Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>







                    </div>
                </div>
            </section >

            {/* <!--blog style1 end--> */}
        </div >
    </>
}

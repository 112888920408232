
import axios from "axios";
import React, { createContext, useState } from "react";
import Cookies from 'js-cookie';

export let GetDepartmentsOptionsContext = createContext('');

export default function GetDepartmentsOptionsContextProvider(props) {
    let [DepartmentsOptions, setDepartmentsOptions] = useState([])
    async function GetDepartmentsSelectOptions(hospital_id) {
        // e.preventDefault()
        // if (e.target.value) {
        let { data } = await axios.get(`http://130.7.1.27/mayo/public/booking/department/${hospital_id}/${Cookies.get('i18next')}`);
        setDepartmentsOptions(data);

        // console.log('DETAILS DepartmentsOptions>>> ' + DepartmentsOptions);
        // console.log(data);
        // }
    }
    // useEffect(() => { GetDepartmentsSelectOptions() }, []) 

    return <>
        <GetDepartmentsOptionsContext.Provider value={{
            GetDepartmentsSelectOptions, DepartmentsOptions

        }}>
            {props.children}
        </GetDepartmentsOptionsContext.Provider>
    </>
}

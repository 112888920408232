import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { Link, useLocation, useParams } from 'react-router-dom'
import PageTitle from '../../../Utilites/PageTitle';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../../../Context/LocalizationContext';
export default function FacilitiesEvenrtsDetails() {
    // translation 
    const { t } = useTranslation("CairoEventDetails");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();
    // const history = useHistory();
    const [eventModal, setEventModal] = useState(false);
    const eventModalClose = () => {
        setEventModal(false);
    }
    function openModal(event_id) {
        setEventId(event_id);
        setEventModal(true);
    }

    const [showForm, setShowForm] = useState(false);

    const handleFormClose = () => setShowForm(false);
    const handleFormShow = () => setShowForm(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };



    // submit registartion
    const [submitMsg, setSubmitMsg] = useState('');
    const [fullName, setFullName] = useState('');
    const [phoneNum, setPhoneNum] = useState('');
    const [eventId, setEventId] = useState('');
    const [email, setEmail] = useState('');

    async function submitForm(e) {
        e.preventDefault();
        const bodyFormData = new FormData();

        bodyFormData.append('fullName', fullName);
        bodyFormData.append('PhoneNumber', phoneNum);
        bodyFormData.append('email', email);
        bodyFormData.append('eventId', eventId);

        const response = await axios.post(`https://sgheg.com/Sadm25/api/mwforms/cairoEvents`, bodyFormData)
            .then(response => {

                if (response.data.Ecode === 200) {
                    console.log(response.data);
                    if (response.data.Edata.request_id > 0) {
                        eventModalClose();
                        handleFormShow()
                        setSubmitMsg(t('Thank you for your submition!'))
                    } else {
                        handleFormClose();
                        openModal();
                        setSubmitMsg('Error!! please Check Your Email ')

                    }

                    // handleFormShow();

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    const { event_id } = useParams();
    const [allEventsDetails, SetAllEventsDetails] = useState({});
    async function getEventsDetails(hospital_id, eventId) {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        // bodyFormData.append('pin', pinNumInp);
        // bodyFormData.append('phone', phoneNumInp);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
        }

        const response = await axios.get(`http://130.7.1.27/mayo/public/event/details/${hospital_id}/${eventId}/${Cookies.get('i18next')}`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    SetAllEventsDetails(response.data.Edata);
                    // console.log('all services details ' + response.data.Edata);


                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    useEffect(() => {
        getEventsDetails('1', event_id);

    }, [])
    return <>
        <div className="page-content">

            {/* <!--page title start--> */}
            <PageTitle title={allEventsDetails.title} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/facilities-events`} prevPage={t('Facilities Events')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/cairo/facilities-events/${allEventsDetails.title}/${allEventsDetails.id}`} currentPage={allEventsDetails.title} />
            {/* <PageTitle title='Facilities' anotherTitle='Event ' currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/facilities-events`} currentPage='Facilities Event' /> */}
            {/* <!--page title end--> */}
            <section>
                <div class="container">
                    <div class="row">

                        <div class="col-lg-6 col-md-12">
                            <div class="post-image">
                                {allEventsDetails.image ? <img class="img-fluid w-100" src={allEventsDetails.image} alt="" /> : <img class="img-fluid w-100" src="/images/events/coming-soon-_result.webp" alt="" />}
                            </div>

                        </div>
                        <div className="col-lg-6">
                            {/* <div class="widget">
                                <h5 class="widget-title">Recent Events</h5>
                                <div class="recent-post">
                                    <ul class="list-unstyled">
                                        <li class="mb-3">
                                            <div class="recent-post-thumb">
                                                <img class="img-fluid" src="../../../images/blog/blog-thumb/01.jpg" alt="" />
                                            </div>
                                            <div class="recent-post-desc"> <a href="#">Family Day</a>
                                                <span><i class="fas fa-calendar-alt text-theme me-1"></i> April 23, 2019</span>
                                            </div>
                                        </li>
                                        <li class="mb-3">
                                            <div class="recent-post-thumb">
                                                <img class="img-fluid" src="../../../images/blog/blog-thumb/02.jpg" alt="" />
                                            </div>
                                            <div class="recent-post-desc"> <a href="#">Chrestimas Day</a>
                                                <span><i class="fas fa-calendar-alt text-theme me-1"></i> April 23, 2019</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="recent-post-thumb">
                                                <img class="img-fluid" src="../../../images/blog/blog-thumb/03.jpg" alt="" />
                                            </div>
                                            <div class="recent-post-desc"> <a href="#">World Cancer
                                                Day</a>
                                                <span><i class="fas fa-calendar-alt text-theme me-1"></i> April 23, 2019</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                            <div class="box-shadow p-5 d-flex flex-column align-items-center">
                                <div class="post-desc">
                                    <div class="post-title">
                                        <h4>{allEventsDetails.title}</h4>
                                    </div>
                                    <div class="post-meta">
                                        <ul class="list-inline">
                                            <div className="row justify-content-between">
                                                <div className="col-md-6 border-end">
                                                    <li>{t('Start Date')}</li>
                                                    <li>{allEventsDetails.start_date}</li>
                                                </div>
                                                <div className="col-md-6">

                                                    <li>{t('End Date')}</li>

                                                    <li>{allEventsDetails.end_date}</li>
                                                </div>
                                            </div>

                                        </ul>
                                    </div>
                                    <p class="mb-3 line-h-3" dangerouslySetInnerHTML={{ __html: allEventsDetails.overveiw }}></p>
                                </div>


                                <button className='btn btn-theme btn-sm appoint-btn mt-sm-0 consultation rounded-top rounded-bottom' onClick={() => { openModal(allEventsDetails.id) }}>{t('Registration')}</button>

                            </div>
                        </div>

                    </div>
                </div>
            </section >
            <Modal show={eventModal} onHide={eventModalClose} id="patienteventModal" backdrop="static">
                <Modal.Header closeButton className='d-flex justify-content-between align-items-center'>
                    <img className="" src='../../../images/cropped-9952-img-32x32.jpg' alt="" />

                    <Modal.Title style={{ color: "#4ab242" }}>{t('Registration')}</Modal.Title>

                </Modal.Header>
                <Modal.Body >


                    <Form onSubmit={submitForm} className='row justify-content-center align-items-end flex-column'>
                        <Form.Group
                            className=" col-md-6 mx-auto ms-3 mb-5"
                            controlId="ControlText"
                        >
                            <Form.Control type="text" name="name" value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder={t("Full Name")}
                            />
                            <Form.Control type="number" name="phone" value={phoneNum} onChange={(e) => setPhoneNum(e.target.value)} placeholder={t("Phone Number")}
                            />
                            <Form.Control type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t("Email")}
                            />
                        </Form.Group>
                        {/* {pinError ? <small className='text-danger mt-3 col-md-8 mx-auto text-center mb-3'>{pinError}</small> : ''} */}

                        <Button className='col-md-3 me-3 btn btn-theme btn-sm appoint-btn mt-5 mt-sm-0 rounded-3 ' type="submit" >
                            {t('Register')}
                        </Button>


                    </Form>



                </Modal.Body>
                {/* <Modal.Footer>
                <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={eventModalClose}>
                    Close
                </Button>
              
            </Modal.Footer> */}
            </Modal>

            <Modal
                size="lg"
                backdrop="static"
                style={customStyles}
                aria-labelledby="contained-modal-title-vcenter"
                centered show={showForm} onHide={handleFormClose} className="top-0 ">

                <Modal.Header closeButton>
                    <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                    <Modal.Title style={{ color: "#4ab242" }}>{t('Confirmation Message')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="team-member text-center">
                        <div className="team-description pt-0">
                            <h5 className="my-5"><Link>{submitMsg}</Link></h5>

                        </div>



                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleFormClose}>
                        {t('Close')}
                    </Button>

                </Modal.Footer>

            </Modal>
        </div >

    </>
}

import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import PageTitle from '../Utilites/PageTitle';
import { Modal, ModalHeader, ModalBody, CardImg } from 'reactstrap';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import axios from 'axios';
import { Container, Row, Col } from 'react-grid-system';
import DynamicGallery from '../Gallery/DynamicGallery';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';
export default function MediaCenterPhotos() {
    // translation 
    const { t } = useTranslation("MediaCenter");
    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    const { pathname } = useLocation();
    const mediaItems = [
        { id: 1, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/01.jpg' },
        { id: 2, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/02.jpg' },
        { id: 3, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/03.jpg' },
        { id: 4, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/04.jpg' },
        { id: 5, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/05.jpg' },
        { id: 6, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/06.jpg' },
        { id: 7, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/07.jpg' },
        { id: 8, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/08.jpg' },
        // Add more media items as needed
    ];

    const mediaNewYear = [
        { id: 1, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/01.jpg' },
        { id: 2, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/02.jpg' },
        { id: 3, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/03.jpg' },
        { id: 9, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/large/hand-doctor-reassuring.webp' },

        // Add more media items as needed
    ];
    const mediaChild = [
        { id: 3, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/03.jpg' },
        { id: 1, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/01.jpg' },
        { id: 4, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/04.jpg' },
        { id: 5, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/05.jpg' },
        { id: 7, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/07.jpg' },
        { id: 8, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/08.jpg' },
        { id: 2, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/02.jpg' },
        { id: 9, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/large/hand-doctor-reassuring.webp' },

        // Add more media items as needed
    ];

    const mediaExcon = [
        { id: 1, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/01.jpg' },
        { id: 5, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/05.jpg' },
        { id: 6, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/06.jpg' },
        { id: 7, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/07.jpg' },
        { id: 8, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/08.jpg' },
        { id: 9, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/large/hand-doctor-reassuring.webp' },
        { id: 3, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/03.jpg' },
        { id: 1, title: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.', src: '../../images/gallery/01.jpg' },

    ];

    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openSlider = (index) => {
        setSelectedImageIndex(index);
        setIsModalOpen(true);
    };

    const closeSlider = () => {
        setSelectedImageIndex(0);
        setIsModalOpen(false);
    };


    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    // get media details

    const { media_id } = useParams();
    const [allMediaDetails, setAllMediaDetails] = useState({});
    const [allMediaDetailsImages, setAllMediaDetailsImages] = useState([]);
    const [allMediaDetailsVideos, setAllMediaDetailsVideos] = useState([]);
    // const [allMediaDetailsMsg, setAllMediaDetailsMsg] = useState('');
    const images = allMediaDetailsImages.map((item) => ({
        original: item.path,
        thumbnail: item.path,

    }));
    async function getMediaDetails(hospital_id, mediaId) {
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),

        }

        const response = await axios.get(`http://130.7.1.27/mayo/public/media/details/${hospital_id}/${mediaId}/${Cookies.get('i18next')}`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    // setCheckPhoneNum(response.data.Emsg);
                    setAllMediaDetails(response.data.Edata)
                    setAllMediaDetailsImages(response.data.Edata.images)
                    setAllMediaDetailsVideos(response.data.Edata.videos)


                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }
    useEffect(() => {
        getMediaDetails('1', media_id)




    }, [])



    return <>


        <PageTitle title={t('Gallery')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/media-center `} prevPage={t('Media Center')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/media-center/gallery`} currentPage={t('Gallery')} />
        {/* <!--page title end--> */}

        <div className="page-content media-container" >

            {/* <!--gallery start--> */}

            <section className="overflow-hidden">

                {/* <Container>
                    <Row className="mt-4">
                        {mediaItems.map((item, index) => (
                            <Col key={item.id} md={4} sm={6} xs={12} className="mb-4">
                                <Card onClick={() => openSlider(index)}>
                                    <CardImg top src={item.src} alt={item.title} />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Modal isOpen={isModalOpen} toggle={closeSlider} size="xl" >
                        <ModalHeader toggle={closeSlider}></ModalHeader>
                        <ModalBody>
                            <ImageGallery
                                items={images}
                                showFullscreenButton={false}
                                showPlayButton={false}
                                startIndex={selectedImageIndex}
                                showNav={true}
                                showThumbnails={false}
                            />
                        </ModalBody>
                    </Modal>
                </Container> */}


                <div className="container">
                    <div className="row align-items-center justify-content-between tab">
                        <div className="col-lg-6 col-md-12">
                            <div className="section-title">
                                <h6>{allMediaDetails.famous_name}</h6>

                                <h2 className="title">
                                    {t('Latest')} <span>{t('mdia')}</span>
                                </h2>
                                {/* <p className="mb-0">
                                    Hectolab Provide Great Services Exercitation ullamcorper suscipitorens lobortis nisl ut aliquip ex ea commodo,
                                    Exercitation ullamcorper suscipitorens Ut elit tellus.
                                </p> */}
                            </div>
                        </div>
                        {allMediaDetailsVideos.length > 0 ? <>
                            <div className="col-lg-4 col-md-12 text-lg-end">
                                <nav>
                                    <div class="nav nav-tabs w-100" id="nav-tab-2" role="tablist">

                                        <Link class=" nav-item nav-link active" id="nav-tab5" data-bs-toggle="tab" data-bs-target="#tab2-1" type="button" role="tab" aria-selected="true" style={{ fontSize: '13px' }} onClick={scrollToTop}>
                                            {t('Photos')}</Link>

                                        <Link class=" nav-item nav-link " id="nav-tab6" data-bs-toggle="tab" data-bs-target="#tab2-2" type="button" role="tab" aria-selected="false" style={{ fontSize: '13px' }} onClick={scrollToTop}>{t('Videos')} </Link>



                                    </div>
                                </nav>
                                {/* <div className="portfolio-filter">
                                <button data-filter="" className="is-checked">
                                    All Events
                                </button>
                                <button data-filter=".cat1">New Year</button>
                                <button data-filter=".cat2">Child Day</button>
                                <button data-filter=".cat3">Excon</button>
                            </div> */}
                            </div>
                        </> : ''}
                    </div>
                </div>
                <Container className='p-0'>
                    <Row>

                        <div class="tab-content w-100 p-0">
                            <div role="tabpanel" class="tab-pane fade show active " id="tab2-1">
                                {/* <div class="service-images">
                                    <img class="img-fluid w-100" src="../../images/partners/JCI.-copy.jpg" alt="" />
                                </div> */}
                                <div className="row">
                                    {allMediaDetailsImages?.map((item, index) => (
                                        <div class="col-lg-4 col-md-12 p-0 gallery-item " key={index} onClick={() => openSlider(index)}>
                                            <img
                                                src={item.path}
                                                class="w-100 shadow-1-strong rounded"
                                                alt="gallery"
                                            />

                                            <div class="gallery-hover">
                                                <div class="gallery-icon">
                                                    <Link class="popup popup-img"> <i class="flaticon-eye"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>



                                    ))}
                                </div>



                            </div>

                            <div role="tabpanel" class="tab-pane fade row ms-0" id="tab2-2">
                                <Row className="row justify-content-center">
                                    {allMediaDetailsVideos?.map((item, index) => (
                                        <Col key={index} xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} className="p-2 cursor-pointer">
                                            <div className="grid-item team-images border-0">
                                                <iframe width="560" height="500" src={item.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

                                            </div>


                                        </Col>
                                    ))}
                                </Row>
                            </div>
                            <Modal
                                isOpen={isModalOpen}
                                onRequestClose={closeSlider}
                                className="modal-content modal-media-center bg-transparent position-relative"
                                overlayClassName="modal-overlay"
                            >
                                <button className="close-button slider-btn d-flex justify-content-start position-absolute align-items-center border-0" onClick={closeSlider}><i class="fa fa-window-close fw-bolder" aria-hidden="true"></i>
                                </button>
                                <ImageGallery
                                    items={images}
                                    showFullscreenButton={true}
                                    showPlayButton={true}
                                    startIndex={selectedImageIndex}
                                    showNav={true}
                                    showThumbnails={true}
                                />
                            </Modal>
                        </div>


                        {/* <div className="col-lg-12 col-md-12"> */}



                        {/* ))} */}
                        {/* </Gallery>
        </div> */}
                    </Row>
                </Container>



            </section>




            {/* <!--gallery end--> */}
        </div >

        <div class="gallery" id="gallery">
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,care" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,studied" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,substance" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,choose" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,past" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,lamp" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,yet" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,eight" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,crew" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,event" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,instrument" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,practical" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,pass" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,bigger" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,number" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,feature" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,line" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,railroad" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,pride" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,too" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,bottle" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,base" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,cell" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,bag" alt="" /></div>
            </div>
            <div class="gallery-item">
                <div class="content"><img src="https://source.unsplash.com/random/?tech,card" alt="" /></div>
            </div>
        </div>
    </>
}

import axios from "axios";
import React, { createContext, useState } from "react";
// import { LocalizationContext } from "./LocalizationContext";
import { useParams } from "react-router-dom";

export let GetCategoryDetailsContext = createContext('');

function GetCategoryDetailsContextProvider(props) {

    // let { i18n } = useContext(LocalizationContext);

    const [symptomDetails, setSymptomDetails] = useState([]);
    const [articleDetails, setArticleDetails] = useState([]);
    const [diseaseDetails, setDiseaseDetails] = useState([]);
    const [firstAidDetails, setFirstAidDetails] = useState([]);
    const [recipeDetails, setRecipeDetails] = useState([]);
    const [testProcedureDetails, setTestProcedureDetails] = useState([]);
    const [videosDetails, setVideosDetails] = useState([]);
    const [slideShowDetails, setSlideShowDetails] = useState([]);
    const [answerDetails, setAnswerDetails] = useState([]);


    let { id, contentType } = useParams();
    // console.log(id);

    async function getCategoryDetails(id, callback) {
        let { data } = await axios.get(`http://130.7.1.27/mayo/public/get_details/${id}/${document.getElementsByTagName("html")[0].getAttribute("lang")}`);
        callback(data.Edata);
        //   console.log(data.Edata);
    }

    // useEffect(() => {
    //     getCategoryDetails(id, setVideosDetails);
    //     getCategoryDetails(id, setSymptomDetails);
    //     getCategoryDetails(id, setArticleDetails);
    //     getCategoryDetails(id, setDiseaseDetails);
    //     getCategoryDetails(id, setFirstAidDetails);
    //     getCategoryDetails(id, setRecipeDetails);
    //     getCategoryDetails(id, setTestProcedureDetails);
    //     getCategoryDetails(id, setAnswerDetails);

    // }, []);
    return <GetCategoryDetailsContext.Provider value={{
        symptomDetails,
        setSymptomDetails,
        articleDetails,
        setArticleDetails,
        diseaseDetails,
        setDiseaseDetails,
        firstAidDetails,
        setFirstAidDetails,
        recipeDetails,
        setRecipeDetails,
        testProcedureDetails,
        setTestProcedureDetails,
        videosDetails,
        setVideosDetails,
        slideShowDetails,
        setSlideShowDetails,
        answerDetails,
        setAnswerDetails,
        id,
        contentType,
        getCategoryDetails,
    }}>
        {props.children}
    </GetCategoryDetailsContext.Provider>

}
export default GetCategoryDetailsContextProvider;
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import PageTitle from '../Utilites/PageTitle';
import axios from 'axios';
import Pagination from 'react-js-pagination';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../Context/LocalizationContext';

export default function News() {
    // translation 
    const { t } = useTranslation("News");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation
    const { pathname } = useLocation();
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    // pagination
    let [lastPageOfSearch, setLastPageOfSearch] = useState()

    let [perPage, setPerPage] = useState();
    let [total, setTotal] = useState();
    let [currentPage, setCurrentPage] = useState(1)
    const handlePageChange = (pageNumber) => {
        // const pageNumber = e;
        setCurrentPage(pageNumber);
        scrollToTop();
    };
    // news section


    const [allNews, setAllNews] = useState([]);
    const [allNewsMsg, setAllNewsMsg] = useState('');

    async function getNews(hospital_id) {
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object

        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),

        }

        const response = await axios.get(`http://130.7.1.27/mayo/public/news/list/${Cookies.get('i18next')}?hospital_id=${hospital_id}&page=${currentPage}&in_home=1`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    // setCheckPhoneNum(response.data.Emsg);
                    setAllNews(response.data.Edata.data)
                    setAllNewsMsg('');
                    setPerPage(response.data.Edata.per_page);
                    setTotal(response.data.Edata.total);
                    setLastPageOfSearch(response.data.Edata.last_page)
                } else {
                    setAllNewsMsg(t('There is No News'));

                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }
    useEffect(() => {
        getNews('1');
    }, [currentPage]);
    return <>
        <div className="page-content">

            {/* <!--blog start--> */}
            {/* <!--page title start--> */}
            <PageTitle title={t('News')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/news`} currentPage={t('News')} />

            {/* <!--page title end--> */}
            <section className='pt-2'>
                <div className="container">
                    <div class="row text-center">
                        <div class="col-xl-8 col-lg-10 col-md-12 mx-auto">
                            <div class="section-title">
                                <h6>{t('News')}</h6>
                                <h2 class="title">{t("What's")} <span>{t('News')}</span></h2>
                            </div>
                        </div>
                    </div>
                    <div class="row gy-3">
                        {allNews.map((news, index) => <>
                            <div class="col-md-4" key={index}>
                                <div class="item">
                                    <div class="post">
                                        <div class="post-image">
                                            {news.image ? <img class="img-fluid w-100 h-100" src={news.image} alt={news.title} /> :
                                                <img class="img-fluid w-100 h-100" src="/images/blog/02.jpg" alt="" />
                                            }
                                        </div>
                                        <div class="post-desc">
                                            <div class="post-meta">
                                                <ul class="list-inline">
                                                    {/* <li>23 April, 2019</li> */}
                                                    {/*  */}
                                                </ul>
                                            </div>
                                            <div class="post-title">
                                                <h5><Link onClick={scrollToTop} to="">{news.title}</Link></h5>
                                            </div>
                                            <p dangerouslySetInnerHTML={{ __html: news.shortnote }} style={{ aspectRatio: 5 / 2 }}></p> <Link onClick={scrollToTop} class="post-btn" to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/news/${news.title}/${news.id}`}>{t('Read More')}
                                                {i18n.language === 'en' ? <i class="ms-2 fas fa-long-arrow-alt-right"></i> : <i class="ms-2 fas fa-long-arrow-alt-left"></i>}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </>)}

                    </div>
                    {lastPageOfSearch > 1 ? <>
                        < div className="row justify-content-center text-center mt-3" id='pagination'>
                            < Pagination
                                activePage={currentPage}
                                itemsCountPerPage={perPage}
                                totalItemsCount={total}
                                pageRangeDisplayed={5}
                                hideLastPages={true}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div></> : ''}

                </div>
            </section >
        </div >
    </>
}

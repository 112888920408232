import axios from "axios";
import React, { createContext, useState } from "react";
// import { GetCategoriesContext } from "./GetCategories";
export let CategorySearchDataContext = createContext('');

function CategorySearchDataContextProvider(props) {

    const [searchData, setSearchData] = useState([]);
    // const [search, setSearch] = useState([]);
    // let { setVideos, setSymptom, setArticle, setFirstAid, getCategoryByChars, setRecipe, setTestProcedure, setDisease } = useContext(GetCategoriesContext);



    async function CategorySearchData(e, category) {
        e.preventDefault()
        if (e.target.value) {
            let { data } = await axios.post(`http://130.7.1.27/mayo/public/search_docs?search=${e.target.value}&category=${category}&lang=${document.getElementsByTagName("html")[0].getAttribute("lang")}`);
            setSearchData(data.Edata);

            // console.log('DETAILS >>> ' + category);
            // console.log(data.Edata);
        }
    }

    return <>
        <CategorySearchDataContext.Provider value={{
            searchData,
            setSearchData,
            CategorySearchData,

        }}>
            {props.children}
        </CategorySearchDataContext.Provider>
    </>
}
export default CategorySearchDataContextProvider;
import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
// import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import { LocalizationContext } from '../../Context/LocalizationContext';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Time from '../../Times';
import Select from 'react-select';
import axios from 'axios';
import { useEffect } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import { useRef } from 'react';
import { PatientPortalPinInpContext } from '../../Context/Authentication/PatientPortalPinInpContext';
import Api from '../../Components/Api/Api';
import { useContext } from 'react';
import { PatientPortalBaseUrlContext } from '../../Context/PatientPortalBaseUrlContext';
import Cookies from 'js-cookie';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { AuthContext } from '../../Context/Authentication/AuthContext';
import { SaveUserDataContext } from '../../Context/Authentication/SaveUserDataContext';
import { LocalizationContext } from '../../Context/LocalizationContext';
import Joi from 'joi';
import Form from 'react-bootstrap/Form';
import Loader from '../Loader/Loader';
import { Document, Page, pdfjs, Text } from 'react-pdf';
import { saveAs } from 'file-saver';
import PatientPortalChangePasswordModale from '../../Context/Authentication/PatientPortalChangePasswordModale';
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function PatientPortalVisits(props) {
    // translation 
    const { t } = useTranslation("PatientPortal");
    // let {handleLanguageChange } = useContext(LocalizationContext)
    // translation 
    const [numPdfPages, setNumPdfPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const onDocumentPdfLoadSuccess = ({ numPages }) => {
        setNumPdfPages(numPages);
    };

    const renderPdfPages = () => {
        const pages = [];
        for (let pageNumber = 1; pageNumber <= numPdfPages; pageNumber++) {
            pages.push(
                <Page key={pageNumber} pageNumber={pageNumber} className="w-100 h-100" />
            );
        }
        return pages;
    };

    // let { baseUrl, setBaseUrl } = useContext(PatientPortalBaseUrlContext);
    // const { pinInp, setPinInp, vitalSummary, getsummary, visitsSummary, labSummary, radSummary, cardiologySummary } = useContext(PatientPortalPinInpContext);

    let [visitsSummary, setVisitsSummary] = useState([]);
    let [labSummary, setLabSummary] = useState([]);
    let [radSummary, setRadSummary] = useState([]);
    let [cardiologySummary, setCardiologySummary] = useState([]);
    let [vitalSummary, setvitalSummary] = useState([]);

    // change password modale import

    const [portalChangePassword, setPortalChangePassword] = useState(false);

    const handlePortalChangePasswordClose = () => {
        setPortalChangePassword(false);
        setOldPasswordInp('');
        setNewPasswordInp('');
        setConfirmationPasswordInp('');
    };

    // change password function

    const portalChangePasswordClose = () => {
        setPortalChangePassword(false);
        setCodeError(false);
        setFormData({ code: '' });
        setCodeInp('')

    }
    const [changePasswordError, setChangePasswordError] = useState(false);
    // const [passwordError, setPasswordError] = useState('');/
    const isStrongPassword = (password) => {
        // Password rules: at least 8 characters, one uppercase letter, one lowercase letter, one digit, and one special character
        // const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])[A-Za-z0-9]{8,16}$/;
        const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[$!#%*()_\-@]).{8,}$/;


        return passwordRegex.test(password);
    };
    const handleChangePasswordSubmit = (e) => {
        e.preventDefault();
        if (newPasswordInp !== confirmationPasswordInp) {
            setChangePasswordError(t('New password and confirm password must match.'));
        } else if (newPasswordInp === oldPasswordInp) {
            setChangePasswordError(t('New password must be different from the old password.'));
        } else if (!isStrongPassword(newPasswordInp)) {
            setChangePasswordError(t('The password must be at least 8 characters long.,At least one digit (0-9).,At least one uppercase letter (A-Z) and At least one special character of the following [$, !, #, %, *, (, ), _, -, or @].'));
        } else {
            changePassword()
        }
    }
    const [oldPasswordInp, setOldPasswordInp] = useState('');
    const [newPasswordInp, setNewPasswordInp] = useState('');
    const [confirmationPasswordInp, setConfirmationPasswordInp] = useState('');
    async function changePassword() {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('old_password', oldPasswordInp);
        bodyFormData.append('new_password', newPasswordInp);
        // bodyFormData.append('confirmation_password', confirmationPasswordInp);

        const headers = {
            'Content-Type': 'multipart/form-data',
            'fingerprint': localStorage.getItem('fingerPrint'),
            'token': localStorage.getItem('patientPortalToken'),
        };
        await axios.post(`${baseUrl}/changepassword`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {

                    // localStorage.setItem("patientPortalToken", response.data.Edata.token);
                    // console.log('userdata ' + userData);
                    handlePortalChangePasswordClose();
                    navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal`)
                    getsummary();

                    console.log(response.data.Emsg);
                    setChangePasswordError(false);
                    setCodeError(false);
                    setPortalsPhone(false);
                    setPortals(false);
                    setPortalsPin(false);
                    setIsLoading(false);
                    // portalClose();
                    // portalPhoneClose();
                    document.getElementById('patientPortalModale').classList.add('d-none');
                    document.getElementById('patientportalPinModale').classList.add('d-none');
                    document.getElementById('patientportalPhoneModale').classList.add('d-none');
                    document.getElementById('normal-nav').classList.add('d-none');
                    document.getElementById('patient-portal-nav').classList.remove('d-none');
                    document.getElementById('alex-nav').classList.add('d-none');
                    document.getElementById('cairo-nav').classList.add('d-none');
                    document.getElementById('referTo').classList.remove('d-none');





                } else {

                    setChangePasswordError(response.data.Emsg);
                    setCodeError(response.data.message);
                    setIsLoading(true);
                    setPortalsPhone(false);
                    setPortalChangePassword(true)
                    setPortalsPin(false);
                    setPortals(false);
                    setPinError(response.data.Emsg)
                    // setCodeInp('');



                }
                // console.log('phoooooone' + phoneNumX5Inp);

            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });

        console.log('phoooooone' + phoneNumX5Inp);


    }
    // get summary
    async function getsummary() {
        // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('pin', localStorage.getItem('pinNumber'));

        const headers = {

            'Content-Type': 'multipart/form-data',
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
            'token': localStorage.getItem('patientPortalToken'),
        };
        await axios.post(`${baseUrl}/patientportal/getsummery`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    console.log(response.data.Edata.Visits);
                    setVisitsSummary(response.data.Edata.Visits);
                    setLabSummary(response.data.Edata.Lab);
                    setRadSummary(response.data.Edata.Rad);
                    setCardiologySummary(response.data.Edata.Cardiology);
                    setvitalSummary(response.data.Edata.vital);
                } else if (response.data.Ecode === 1407) {
                    setPortalChangePassword(true)

                }

            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });

        // calcBmi();
    }
    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    // const customStyles = {
    //     content: {
    //         top: '0',
    //         left: '50%',
    //         right: 'auto',
    //         bottom: 'auto',
    //         marginRight: '-50%',
    //         // transform: 'translate(-50%, -50%)',
    //     },
    // };

    // const [expiredMsg, setExpiredMsg] = useState('');

    // if open portal from url

    useEffect(() => {
        if (localStorage.getItem('patientPortalToken') !== null) {
            getsummary();
        } else {
            // portals();

            navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}`); // Replace '/your-link' with the desired line
            setPortals(true)

        }
    }, [])


    let { baseUrl, setBaseUrl } = useContext(PatientPortalBaseUrlContext)
    const { pinInp, setPinInp } = useContext(PatientPortalPinInpContext);
    const { userData, setUserData } = useContext(AuthContext)
    const { saveUserData } = useContext(SaveUserDataContext);
    /// To disable a button while waiting for an OTP (One-Time Password) to be sent to a phone number,
    // and then enable it once the message is sent, 
    const [isSending, setIsSending] = useState(false); // State to track whether the message is being sent
    function logout() {
        localStorage.removeItem("patientPortalToken");
        localStorage.removeItem("pinNumber");
        navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}`);
        closeNavbar();
    }

    const handleSendOTP = () => {
        setIsSending(true);
        checkNumber();

        // Simulating an asynchronous API call to send the OTP message
        setTimeout(() => {
            // After a delay, when the OTP message is sent
            setIsSending(false);

            // Additional logic for handling the OTP message being sent
        }, 60000); //only one minute
    };
    // end disable
    let input1Ref = useRef(null);
    let input2Ref = useRef(null);
    let input3Ref = useRef(null);
    let input4Ref = useRef(null);
    let combinedInputRef = useRef(null);
    const [combinedValue, setCombinedValue] = useState('');

    const handleKeyDown = (event, ref) => {
        if (event.key === 'Backspace' && ref && ref.current) {
            const previousRef = getPreviousRef(ref);
            if (previousRef) {
                event.preventDefault(); // Prevents the browser from navigating back
                previousRef.current.focus();
                // previousRef.current.value = '';
            }
        } else if (event.key !== 'Backspace' && event.key !== 'ArrowLeft' && ref && ref.current && ref.current.value.length >= 1) {
            const nextRef = getNextRef(ref);
            if (nextRef) {
                // event.preventDefault(); // Prevents the browser from moving the cursor
                nextRef.current.focus();
            }
        }
        // handleInputChange();
    };

    const getNextRef = (currentRef) => {
        if (currentRef === input1Ref) {
            return input2Ref;
        } else if (currentRef === input2Ref) {
            return input3Ref;
        } else if (currentRef === input3Ref) {
            return input4Ref;
        }
        return input4Ref; // No next input field
    };

    const getPreviousRef = (currentRef) => {
        if (currentRef === input2Ref) {
            input2Ref.current.value = '';
            return input1Ref;
        } else if (currentRef === input3Ref) {
            input3Ref.current.value = '';

            return input2Ref;
        } else if (currentRef === input4Ref) {
            input4Ref.current.value = '';

            return input3Ref;
        }
        input1Ref.current.value = '';
        return input1Ref; // No previous input field

    };
    let [phoneNumX5Inp, setPhoneNumX5Inp] = useState('')
    // const combinedInputRef = useRef(null);
    // const [combinedValue, setCombinedValue] = useState('');
    const handleInputChange = () => {

        // const { name, value } = e.target;
        // const mergedValue = phoneNumX5Inp + value;

        // setPhoneNumX5Inp(mergedValue);
        const input1Value = input1Ref.current.value;
        const input2Value = input2Ref.current.value;
        const input3Value = input3Ref.current.value;
        const input4Value = input4Ref.current.value;

        const phoneNumX5Inp = `${input1Value}${input2Value}${input3Value}${input4Value}`;
        setPhoneNumX5Inp(phoneNumX5Inp);

        if (combinedInputRef.current) {
            combinedInputRef.current.value = phoneNumX5Inp;
        }

        if (input1Value.length > 0) {
            // input2Ref.current.focus();
            return input2Ref;
        } else if (input2Value.length > 0) {
            // input3Ref.current.focus();
            return input3Ref;

        } else if (input3Value.length > 0) {
            // input4Ref.current.focus();
            return input4Ref;
        }

        // const myUserPhone = { ...formPhoneData };
        // myUserPhone.numX1 = e.target.value;
        // myUserPhone.numX2 = e.target.value;
        // myUserPhone.numX3 = e.target.value;
        // myUserPhone.numX4 = e.target.value;

        // setPinInp(myUserPhone.pin);

        // console.log(pinInp);
        // setPhoneNumX1Inp(myUserPhone.numX1);
        // setPhoneNumX2Inp(myUserPhone.numX2);
        // setPhoneNumX3Inp(myUserPhone.numX3);
        // setPhoneNumX4Inp(myUserPhone.numX4);
        // const allNum = phoneNumX1Inp + phoneNumX2Inp + phoneNumX3Inp + phoneNumX4Inp;
        // console.log(allNum);
        // setPhoneNumX5Inp(allNum);
        // setPhoneNumX1Inp(myUser.numX1)
        // setPhoneNumX2Inp(myUser.numX2)
        // setPhoneNumX3Inp(myUser.numX3)
        // setPhoneNumX4Inp(myUser.numX4)
        // console.log(phoneNumX1Inp);
        // console.log(phoneNumX2Inp);
        // console.log(phoneNumX3Inp);
        // console.log(phoneNumX4Inp);
        // console.log(phoneNumX5Inp);
        console.log(phoneNumX5Inp);
        // }
    };


    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({ pin: '' });
    const [formPhoneData, setFormPhoneData] = useState({ numX1: '', numX2: '', numX3: '', numX4: '' });
    const [formCodeData, setFormCodeData] = useState({ code: '' });


    const handleNextStep = () => {
        // if (pinError === true) {
        //     setStep(1);

        // } else {
        //     setStep(step + 1);

        if (pinError) {
            portals()
            // CheckPin();

        } else {
            portalsPin();
            CheckPin();

        }
    };

    const handlePreviousStep = () => {
        setStep(step - 1);
    };



    const typistRef = useRef(null);
    const navigate = useNavigate();
    let { lang } = useParams();
    const { pathname } = useLocation();
    // window.alert(`${lang}`);

    let { i18n, handleLanguageChange } = useContext(LocalizationContext)
    const changeLanguage = (lang, dir) => {
        document.getElementsByTagName("html")[0].setAttribute("lang", lang);
        document.getElementsByTagName("html")[0].setAttribute("dir", dir);
    }

    // let { lang } = useParams();
    // console.log(lang);


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalShow, setModalShow] = React.useState(false);

    const [complain, setComplains] = useState(false);
    const complainClose = () => setComplains(false);
    const complains = () => setComplains(true);

    const customStyles = {
        content: {
            top: '0',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
        },
    };
    // const history = useHistory();
    const [portal, setPortals] = useState(false);
    const portalClose = () => {
        setPortals(false);
        setPinError(false);
        // setStep(1);
        setFormData({ pin: '' });
        setPinInp('')
    }

    const portals = () => {
        if (localStorage.getItem("patientPortalToken") !== null) {
            navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal`); // Replace '/your-link' with the desired link
            getsummary();
        } else {
            navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}`); // Replace '/your-link' with the desired link

            setFormData({ pin: '' });
            setPinInp('')
            setPinError(false);
            setPortals(true)
        }




    };
    // const token = localStorage.getItem('patientPortalToken');

    const portalsNavigate = () => {

        // navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal`); // Replace '/your-link' with the desired link


    }

    const [portalPin, setPortalsPin] = useState(false);
    const portalPinClose = () => {
        setPortalsPin(false);
        setPhoneError(false);
        setFormPhoneData({ numX1: '', numX2: '', numX3: '', numX4: '' });
        setPhoneNumX5Inp('')
        // setStep(1);
        // setFormData({ pin: '', num1: '', num2: '', num3: '', num4: '', num5: '', num6: '', num7: '', numX1: '', numX2: '', numX3: '', numX4: ''});
    }

    const portalsPin = () => {
        setPhoneError(false);
        setFormPhoneData({ numX1: '', numX2: '', numX3: '', numX4: '' });
        setPhoneNumX5Inp('')
        setPortalsPin(true);

    }

    // const [codeError, setCodeError] = useState(false);

    const [portalPhone, setPortalsPhone] = useState(false);
    const portalPhoneClose = () => {
        setPortalsPhone(false);
        setCodeError(false);
        setFormData({ code: '' });
        setCodeInp('')

    }

    // axios for check pin
    const [patientPhoneFirst, setPatientPhoneFirst] = useState();
    const [patientPhoneLast, setPatientPhoneLast] = useState();
    const [pinError, setPinError] = useState(false);
    // const [pinInp, setPinInp] = useState({});
    const [phoneInp, setPhoneInp] = useState();
    //new 
    const [errorList, setErrorList] = useState([]);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // const [pin, setPin] = useState({
    //     pin: ''
    // });

    const [phoneNumX1Inp, setPhoneNumX1Inp] = useState('');
    const [phoneNumX2Inp, setPhoneNumX2Inp] = useState('');
    const [phoneNumX3Inp, setPhoneNumX3Inp] = useState('');
    const [phoneNumX4Inp, setPhoneNumX4Inp] = useState('');


    const handleChange = (e) => {
        // const { name, value } = e.target;

        // setFormData((prevData) => ({
        //    ...prevData,

        // }));
        let myUser = { ...formData };
        myUser.pin = e.target.value

        setPinInp(myUser.pin);

        console.log(pinInp);

    };
    // const handlePhoneChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    //     setPhoneInp(value);
    //     console.log('phoooooone' + phoneInp);
    // };


    // validate pin number
    function validationPinSchema() {
        let scheme = Joi.object({
            pinInp: Joi.number().integer().positive().required().min(0).max(10)
        });
        return scheme.validate(pinInp, { abortEarly: false });
        //  /   console.log(pinInp);

    }
    async function CheckPin() {
        // Request body
        // const requestBody = {
        //     'pin': 472506
        // }

        const bodyFormData = new FormData();

        // Append data to the FormData object
        bodyFormData.append('pin', pinInp);
        console.log(pinInp);

        const headers = {

            'Content-Type': 'multipart/form-data',
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
            // 'token': localStorage.getItem('patientPortalToken'),
        };
        // Make the API request with the request body

        // headers removed
        // {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //         'fingerprint': localStorage.getItem('fingerPrint'),

        //     },
        // }

        await axios.post(`${baseUrl}/checkpin`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    setPatientPhoneFirst(response.data.Edata.firstd)
                    setPatientPhoneLast(response.data.Edata.lastd)
                    console.log(response.data.Emsg);
                    console.log(response.data.Edata.firstd);
                    console.log(response.data.Edata.lastd);
                    setPinError(false);
                    setPortalsPhone(false);
                    setPortals(false);
                    setPortalsPin(true);
                    // setIsLoading(false);localStorage.setItem('pinNumber', pinInp);
                    // localStorage.setItem('pinNumber', localStorage.getItem('pinNumber'));
                    localStorage.setItem('pinNumber', pinInp);
                } else {
                    // setIsLoading(true);
                    setPortalsPhone(false);
                    setPortalsPin(false);
                    setPortals(true);
                    setPinError(response.data.Emsg)
                    // pinInp()
                    setPinInp('')


                }
            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });
        // let { data } = await axios.post(`${baseUrl}/checkpin`);
        // callback(data.Edata);
        // // setLoading(false);
        // // console.log('videos by chars');

        // console.log(data.Edata);
    }


    function submitLoginForm(e) {
        e.preventDefault();

        // setIsLoading(true);

        let validation = validationPinSchema();
        if (pinError) {
            // setIsLoading(false);
            setErrorList(validation.error.details);

        } else {

            CheckPin();
            // setIsLoading(false);

        }
        // if (pinInp) {
        //     CheckPin();
        // }
    }

    // validate phone number
    function validationPhoneSchema() {
        let scheme = Joi.object({
            phoneNumX1Inp: Joi.number().integer().positive().required().min(1).max(1),
            phoneNumX2Inp: Joi.number().integer().positive().required().min(1).max(1),
            phoneNumX3Inp: Joi.number().integer().positive().required().min(1).max(1),
            phoneNumX4Inp: Joi.number().integer().positive().required().min(1).max(1),
        });
        console.log(phoneNumX5Inp);
        return scheme.validate(phoneNumX1Inp, phoneNumX2Inp, phoneNumX3Inp, phoneNumX4Inp, { abortEarly: false });


    }
    function submitPhoneNumberForm(e) {
        e.preventDefault();

        // setIsLoading(true);

        let validation = validationPhoneSchema();
        if (phoneError) {
            // setIsLoading(false);
            setPortalsPhone(false);
            setPortalsPin(true);
            setErrorList(validation.error.details);

        } else {

            checkNumber();
            // setIsLoading(false);

        }
        // if (phoneNumX5Inp) {
        //     checkNumber();
        // }
    }
    let [phoneError, setPhoneError] = useState(false);
    async function checkNumber() {
        // Request body
        // event.preventDefault(); // Prevents the browser from navigating back

        // if (event.target.value) {
        const headers = {

            'Content-Type': 'multipart/form-data',
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
            // 'token': localStorage.getItem('patientPortalToken'),
        };
        const bodyFormData = new FormData();

        // Append data to the FormData object
        bodyFormData.append('pin', pinInp);
        bodyFormData.append('mnumber', phoneNumX5Inp);

        await axios.post(`${baseUrl}/checknumber`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {

                    console.log(response.data.Emsg);
                    setPhoneError(false);
                    setPortalsPhone(true);
                    setPortals(false);
                    setPortalsPin(false);
                    // setIsLoading(false);



                } else {
                    setPhoneError(response.data.Emsg);

                    // setIsLoading(true);
                    setPortalsPhone(false);
                    setPortalsPin(true);
                    setPortals(false);
                    setPinError(response.data.Emsg)
                    // pinInp()
                    // setPinInp({})
                    setPhoneNumX5Inp('');



                }
                console.log('phoooooone' + phoneNumX5Inp);

            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });

        console.log('phoooooone' + phoneNumX5Inp);

        // }
    }

    //  code function 
    const [codeInp, setCodeInp] = useState({});

    const handleCodeChange = (e) => {
        // const { name, value } = e.target;

        // setFormData((prevData) => ({
        //    ...prevData,

        // }));
        let myUserCode = { ...formCodeData };
        myUserCode.code = e.target.value

        setCodeInp(myUserCode.code);

        console.log(codeInp);

    };
    // useEffect(() => {

    // }, []);
    const [codeError, setCodeError] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (codeError) {
            // setIsLoading(false);
            setPortalsPin(false);
            setPortals(false);
            setPortalsPhone(true);
            // setErrorList(validation.error.details);

        } else {

            checkCode();
            // setIsLoading(false);

        }
        // if (codeInp) {
        //     checkCode();
        // }
        // Handle form submission

        // <Navigate to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal/patient_portal_home`} />
    };

    async function checkCode() {
        const bodyFormData = new FormData();
        const phone = `${patientPhoneFirst}${phoneNumX5Inp}${patientPhoneLast}`;
        // Append data to the FormData object
        bodyFormData.append('pin', pinInp);
        bodyFormData.append('otpcode', codeInp);
        bodyFormData.append('phone', phone);
        console.log(phone);
        const headers = {
            'Content-Type': 'multipart/form-data',
            'fingerprint': localStorage.getItem('fingerPrint'),
        };
        await axios.post(`${baseUrl}/checkcode`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    if (response.data.Edata.user.password_reset === 1) {
                        changePassword();
                        localStorage.setItem("patientPortalToken", response.data.Edata.token);
                        console.log('userdata ' + userData);
                    }
                    else {

                        localStorage.setItem("patientPortalToken", response.data.Edata.token);
                        console.log('userdata ' + userData);

                        navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal`)
                        getsummary();
                        handlePortalChangePasswordClose();
                        console.log(response.data.message);
                        setCodeError(false);
                        setPortalsPhone(false);
                        setPortals(false);
                        setPortalsPin(false);
                        setIsLoading(false);

                        // portalClose();
                        // portalPhoneClose();
                        document.getElementById('patientPortalModale').classList.add('d-none');
                        document.getElementById('patientportalPinModale').classList.add('d-none');
                        document.getElementById('patientportalPhoneModale').classList.add('d-none');
                        document.getElementById('normal-nav').classList.add('d-none');
                        document.getElementById('patient-portal-nav').classList.remove('d-none');
                        document.getElementById('alex-nav').classList.add('d-none');
                        document.getElementById('cairo-nav').classList.add('d-none');
                        document.getElementById('referTo').classList.remove('d-none');
                        // console.log(formData);
                        // saveUserData();

                    }
                } else if (response.data.Ecode === 401) {
                    navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}`)

                } else {

                    setPhoneError(response.data.Emsg);
                    setCodeError(response.data.message);
                    setIsLoading(true);
                    setPortalsPhone(true);
                    setPortalsPin(false);
                    setPortals(false);
                    setPinError(response.data.Emsg)
                    // setCodeInp('');



                }
                // console.log('phoooooone' + phoneNumX5Inp);

            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });

        console.log('phoooooone' + phoneNumX5Inp);


    }
    // async function checkCode() {
    //     const bodyFormData = new FormData();
    //     const phone = `${patientPhoneFirst}${phoneNumX5Inp}${patientPhoneLast}`;

    //     bodyFormData.append('pin', pinInp);
    //     bodyFormData.append('otpcode', codeInp);
    //     bodyFormData.append('phone', phone);
    //     console.log(phone);
    //     const headers = {

    //         'Content-Type': 'multipart/form-data',
    //         'lang': Cookies.get('i18next'),
    //         'fingerprint': localStorage.getItem('fingerPrint'),
    //         'token': localStorage.getItem('patientPortalToken'),
    //     };
    //     await axios.post(`${baseUrl}/checkcode`, bodyFormData, { headers })
    //         .then(response => {

    //             if (response.data.Ecode === 200) {
    //                 localStorage.setItem("patientPortalToken", response.data.Edata.token);
    //                 console.log('userdata ' + userData);

    //                 navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}/patient-portal`)
    //                 getsummary();

    //                 console.log(response.data.Emsg);
    //                 setCodeError(false);
    //                 setPortalsPhone(false);
    //                 setPortals(false);
    //                 setPortalsPin(false);

    //                 document.getElementById('patientPortalModale').classList.add('d-none');
    //                 document.getElementById('patientportalPinModale').classList.add('d-none');
    //                 document.getElementById('patientportalPhoneModale').classList.add('d-none');
    //                 document.getElementById('normal-nav').classList.add('d-none');
    //                 document.getElementById('patient-portal-nav').classList.remove('d-none');
    //                 document.getElementById('alex-nav').classList.add('d-none');
    //                 document.getElementById('cairo-nav').classList.add('d-none');
    //                 document.getElementById('referTo').classList.remove('d-none');


    //             } else if (response.data.Ecode === 401) {
    //                 navigate(`${pathname.split('/')[0]}/${Cookies.get('i18next')}`)

    //             } else {

    //                 setPhoneError(response.data.Emsg);
    //                 setCodeError(response.data.Emsg);

    //                 setPortalsPhone(true);
    //                 setPortalsPin(false);
    //                 setPortals(false);
    //                 setPinError(response.data.Emsg)




    //             }


    //         })
    //         .catch(error => {


    //             console.error(error);
    //         });

    //     console.log('phoooooone' + phoneNumX5Inp);


    // }

    // function confirmPhoneNumber() {
    //     setPortalsPhone(true)
    //     checkNumber();
    // }

    const token = localStorage.getItem('patientPortalToken');
    useEffect(() => {
        // CheckPin();
        // console.log(localStorage.getItem('fingerPrint'));

        // Remove data after one hour
        const timeoutId = setTimeout(() => {
            if (token) {
                localStorage.removeItem('patientPortalToken');
            }
        }, 60 * 60 * 1000); // 1 hour in milliseconds

        // Clean up the timeout when the component unmounts or when the data is removed manually
        return () => clearTimeout(timeoutId);

    }, [token])


    const [isPDFReady, setIsPDFReady] = useState(false);

    const handlePDFLoad = () => {
        setIsPDFReady(true);
    };
    // profile portal

    // let [vitalSummary, setvitalSummary] = useState([]);


    // async function getsummary() {
    //     // let { data } = await axios.get(`${baseUrl}/patientportal/getsummery?pin=${pinInp}`);
    //     const bodyFormData = new FormData();
    //     // Append data to the FormData object
    //     bodyFormData.append('pin', localStorage.getItem('pinNumber'));

    //     const headers = {
    //         'Content-Type': 'multipart/form-data',

    //         'fingerprint': localStorage.getItem('fingerPrint'),
    //         'token': localStorage.getItem('patientPortalToken'),
    //     };
    //     await axios.post(`${baseUrl}/patientportal/getsummery`, bodyFormData ,{headers} ,{headers})
    //         .then(response => {
    //             // Handle the response
    //             setvitalSummary(response.data.Edata.vital);
    //             console.log(response.data.Edata.vital);
    //         })
    //         .catch(error => {

    //             // Handle any errors
    //             console.error(error);
    //         });

    //     // calcBmi();
    // }

    // useEffect(() => {
    //     if (portal === true) {
    //         getsummary();
    //     }
    // }, [portals])

    // close nav when click a link

    const [isNavbarOpen, setIsNavbarOpen] = useState(false);


    const toggleNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };
    const closeNavbar = () => {
        setIsNavbarOpen(false);
    };


    // end portal login






    useEffect(() => {
        calcBmi();

    }, [vitalSummary])
    //  bmi calculate for vital sign
    const [bmi, setBmi] = useState(0);
    const [message, setMessage] = useState('')

    let calcBmi = () => {
        //prevent submitting to the server
        // event.preventDefault()

        let bmi = (vitalSummary.weight / ((0.01 * vitalSummary.height) * (0.01 * vitalSummary.height)))
        setBmi(Number(bmi.toFixed(1)))
        console.log(bmi);
        // Logic for message

        if (bmi < 18.5) {
            setMessage(t('You are underweight'))
        } else if (bmi >= 18.5 && bmi < 24.9) {
            setMessage(t('You are a Normal weight'))
        } else if (bmi >= 25 && bmi < 29.9) {
            setMessage(t('You are a overweight'))
        } else {
            setMessage(t('obesity'))
        }

    }

    //  get all visits 
    const titleRef = useRef(null);
    // let [allVisits, setAllVisits] = useState([]);
    let [groupedVisits, setGroupedVisits] = useState({});
    const [focusedItem, setFocusedItem] = useState(null);
    const focusedItemRef = useRef(null);

    async function getAllVisits(type) {
        const typeT = type;
        setFocusedItem(typeT)
        console.log('focusedItem ' + focusedItem);
        // setAllLab([]);
        setGroupedLabs({})
        setGroupedCardiology({})
        setGroupedRadiology({})
        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('pin', localStorage.getItem('pinNumber'));

        const headers = {

            'Content-Type': 'multipart/form-data',
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
            'token': localStorage.getItem('patientPortalToken'),
        };
        await axios.post(`${baseUrl}/patientportal/details/visit`, bodyFormData, { headers })
            .then(response => {
                // Handle the response

                const allVisits = response.data.Edata.visits;
                const result = allVisits.reduce(function (r, a) {
                    r[a.department_name] = r[a.department_name] || [];
                    r[a.department_name].push(a);
                    return r;
                }, Object.create(null));
                // result = Array.from(new Set(labDepartment));

                // result = Object.keys(result).map((key) => console.log(key))

                setGroupedVisits(result);
                console.log(Object.keys(groupedVisits));
                //     console.log(Object.keys(groupedVisits).map((key) => console.log(key)));
                // titleRef.current.scrollIntoView({ behavior: "smooth" });

                // document.getElementById('pdf-view').classList.add('d-none');
                // document.getElementById('pdf-lab-view').classList.add('d-none');
                // document.getElementById('pdf-cardiology-view').classList.add('d-none');
                // document.getElementById('pdf-radiology-view').classList.add('d-none');


            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });
    }


    // get all labs

    // let [allLab, setAllLab] = useState([]);
    let [groupedLabs, setGroupedLabs] = useState({});

    // let [allLabDepartment, setAllLabDepartment] = useState([]);
    // let [filteredLabs, setFilteredLabs] = useState([]);
    // const uniqueDepartmentNames = []
    const filteredLabs = []
    async function getAllLab(type) {
        // setAllVisits([]);
        // setAllCardiology([]);
        const typeT = type;
        setFocusedItem(typeT)
        setGroupedVisits({})
        setGroupedCardiology({})
        setGroupedRadiology({})
        // let { data } = await axios.get(`${baseUrl}/patientportal/details/lab?pin=${pinInp}`);
        // console.log(data.Edata.labs);
        // // titleRef.current.scrollIntoView({ behavior: "smooth" });
        // // setAllLab(data.Edata.labs);
        // const allLab = data.Edata.labs;
        // const result = allLab.reduce(function (r, a) {
        //     r[a.department_name] = r[a.department_name] || [];
        //     r[a.department_name].push(a);
        //     return r;
        // }, Object.create(null));


        // setGroupedLabs(result);
        // console.log(Object.keys(groupedLabs));


        // document.getElementById('pdf-view').classList.add('d-none');
        // document.getElementById('pdf-lab-view').classList.add('d-none');
        // document.getElementById('pdf-cardiology-view').classList.add('d-none');

        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('pin', localStorage.getItem('pinNumber'));

        const headers = {
            'lang': Cookies.get('i18next'),
            'Content-Type': 'multipart/form-data',
            'fingerprint': localStorage.getItem('fingerPrint'),
            'token': localStorage.getItem('patientPortalToken'),
        };
        await axios.post(`${baseUrl}/patientportal/details/lab`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                console.log(response.data.Edata.labs);
                // titleRef.current.scrollIntoView({ behavior: "smooth" });
                // setAllLab(data.Edata.labs);
                const allLab = response.data.Edata.labs;
                const result = allLab.reduce(function (r, a) {
                    r[a.department_name] = r[a.department_name] || [];
                    r[a.department_name].push(a);
                    return r;
                }, Object.create(null));


                setGroupedLabs(result);
                console.log(Object.keys(groupedLabs));


                // document.getElementById('pdf-view').classList.add('d-none');
                // document.getElementById('pdf-lab-view').classList.add('d-none');
                // document.getElementById('pdf-cardiology-view').classList.add('d-none');
                // document.getElementById('pdf-radiology-view').classList.add('d-none');


            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });


    }

    // get all cardiology

    // let [allCardiology, setAllCardiology] = useState([]);
    let [groupedCardiology, setGroupedCardiology] = useState({});

    async function getAllCardiology() {
        // setAllLab([]);
        // setAllVisits([]);
        setGroupedVisits({});
        setGroupedLabs({});
        setGroupedRadiology({});
        // let { data } = await axios.get(`${baseUrl}/patientportal/details/cardiology?pin=${pinInp}`);
        // console.log(data.Edata.labs);
        // // titleRef.current.scrollIntoView({ behavior: "smooth" });
        // // setAllCardiology(data.Edata.labs);
        // const allCardiology = data.Edata.labs;
        // const result = allCardiology.reduce(function (r, a) {
        //     r[a.department_name] = r[a.department_name] || [];
        //     r[a.department_name].push(a);
        //     return r;
        // }, Object.create(null));
        // // result = Array.from(new Set(labDepartment));

        // // result = Object.keys(result).map((key) => console.log(key))

        // setGroupedCardiology(result);
        // console.log(Object.keys(groupedCardiology));

        // document.getElementById('pdf-view').classList.add('d-none');
        // document.getElementById('pdf-lab-view').classList.add('d-none');
        // document.getElementById('pdf-cardiology-view').classList.add('d-none');


        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('pin', localStorage.getItem('pinNumber'));

        const headers = {
            'lang': Cookies.get('i18next'),
            'Content-Type': 'multipart/form-data',
            'fingerprint': localStorage.getItem('fingerPrint'),
            'token': localStorage.getItem('patientPortalToken'),
        };
        await axios.post(`${baseUrl}/patientportal/details/cardiology`, bodyFormData, { headers })
            .then(response => {
                // Handle the response
                console.log(response.data.Edata.labs);
                // titleRef.current.scrollIntoView({ behavior: "smooth" });
                // setAllCardiology(data.Edata.labs);
                const allCardiology = response.data.Edata.labs;
                const result = allCardiology.reduce(function (r, a) {
                    r[a.department_name] = r[a.department_name] || [];
                    r[a.department_name].push(a);
                    return r;
                }, Object.create(null));
                // result = Array.from(new Set(labDepartment));

                // result = Object.keys(result).map((key) => console.log(key))

                setGroupedCardiology(result);
                console.log(Object.keys(groupedCardiology));

                document.getElementById('pdf-view').classList.add('d-none');
                document.getElementById('pdf-lab-view').classList.add('d-none');
                document.getElementById('pdf-radiology-view').classList.add('d-none');
                document.getElementById('pdf-cardiology-view').classList.add('d-none');
            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });
    }

    let [groupedRadiology, setGroupedRadiology] = useState({});

    // const [studyIncId, setStudyIncId] = useState();

    async function getAllRadology() {
        // const typeT = type;
        // setFocusedItem(typeT)
        // console.log('focusedItem ' + focusedItem);
        // setAllLab([]);
        setGroupedLabs({})
        setGroupedCardiology({})
        setGroupedVisits({});

        const bodyFormData = new FormData();
        // Append data to the FormData object
        bodyFormData.append('pin', localStorage.getItem('pinNumber'));

        const headers = {
            'lang': Cookies.get('i18next'),
            'Content-Type': 'multipart/form-data',
            'fingerprint': localStorage.getItem('fingerPrint'),
            'token': localStorage.getItem('patientPortalToken'),
        };
        await axios.post(`${baseUrl}/patientportal/details/rad`, bodyFormData, { headers })
            .then(response => {
                // Handle the response

                const allRad = response.data.Edata;
                const result = allRad.reduce(function (r, a) {

                    r[a.date] = r[a.date] || [];
                    r[a.date].push(a);
                    return r;

                }, Object.create(null));
                // result = Array.from(new Set(labDepartment));

                // result = Object.keys(result).map((key) => console.log(key))

                setGroupedRadiology(result);

                // const r = allRad.map(res =>);
                // setStudyIncId(result.studyincid);
                // console.log(studyIncId);
                console.log(Object.keys(groupedRadiology));
                //     console.log(Object.keys(groupedVisits).map((key) => console.log(key)));
                // titleRef.current.scrollIntoView({ behavior: "smooth" });

                // document.getElementById('pdf-view').classList.add('d-none');
                // document.getElementById('pdf-lab-view').classList.add('d-none');
                // document.getElementById('pdf-cardiology-view').classList.add('d-none');
                // document.getElementById('pdf-radiology-view').classList.add('d-none');


            })
            .catch(error => {

                // Handle any errors
                console.error(error);
            });
    }

    // get visits report 
    const [pdfData, setPdfData] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [filename, setFilename] = useState('');
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };


    async function getVisitsReport(allVisit) {
        // Request body

        const bodyFormData = new FormData();

        // Append data to the FormData object
        bodyFormData.append('pin', localStorage.getItem('pinNumber'));
        bodyFormData.append('visitid', allVisit.visitid);
        bodyFormData.append('branch', allVisit.branch);
        const headers = {

            'Content-Type': 'multipart/form-data',
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
            'token': localStorage.getItem('patientPortalToken'),
        };
        console.log('visit id ' + allVisit.visitid);

        await axios.post(`http://130.7.1.27/mayo_test/public/patientportal/visits/getlink`, bodyFormData, { headers })
            .then(response => {
                // const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                // const pdfUrl = URL.createObjectURL(pdfBlob);
                const hash = response.data.Edata.hash;
                console.log(response.data);
                console.log(hash);
                setPdfData(`http://130.7.1.27/mayo_test/public/patientportal/visits/getpdf/` + `${pinInp}` + `/` + allVisit.visitid + `/` + allVisit.branch + `/` + hash);
                console.log('pdf file url ' + pdfData);
                setIsLoading(false);
                // console.log(response.data.Edata.hash);

                // document.getElementById('pdf-view').classList.remove('d-none');

                // const contentDisposition = response.headers['content-disposition'];
                // const match = contentDisposition.match(/filename="(.+)"/);
                // // const match = contentDisposition.match(/filename\*?=['"]?([^'";]+)['"]?/);
                // if (match && match[1]) {
                //     const decodedFilename = decodeURI(match[1]);
                //     setFilename(decodedFilename);
                //     document.getElementById('pdf-view').classList.remove('d-none');

                //     console.log(filename);
                // }
                document.getElementById('pdf-view').classList.remove('d-none');
                document.getElementById('pdf-lab-view').classList.add('d-none');
                document.getElementById('pdf-cardiology-view').classList.add('d-none');
                document.getElementById('pdf-radiology-view').classList.add('d-none');
                scrollToReport();
            })
            .catch(error => {
                setIsLoading(false);

                console.error('Error fetching PDF:', error);
            });


    }


    // get cardiology report 
    const [pdfCardiologyData, setPdfCardiologyData] = useState(null);

    async function getCardiologyReport(allCardiology) {
        // Request body

        const bodyFormData = new FormData();

        // Append data to the FormData object
        bodyFormData.append('pin', localStorage.getItem('pinNumber'));
        bodyFormData.append('orderid', allCardiology.orderid);
        bodyFormData.append('test', allCardiology.TestId);
        bodyFormData.append('branch', allCardiology.branch);

        console.log('orderid' + allCardiology.orderid);
        console.log('orderid' + allCardiology.TestId);
        console.log('orderid' + allCardiology.branch);
        const headers = {

            'Content-Type': 'multipart/form-data',
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
            'token': localStorage.getItem('patientPortalToken'),
        };
        await axios.post(`${baseUrl}/patientportal/labs/getlink`, bodyFormData, { headers })
            .then(response => {
                // const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                // const pdfCardiologyUrl = URL.createObjectURL(pdfBlob);
                // setPdfData(pdfCardiologyUrl);
                // console.log('pdf file url ' + pdfCardiologyUrl);
                // console.log(response.data);
                const hash = response.data.Edata.hash;
                console.log(response.data);
                console.log(hash);

                setPdfCardiologyData(`${baseUrl}/patientportal/labs/getpdf/` + `${pinInp}` + `/` + allCardiology.orderid + `/` + allCardiology.TestId + `/` + allCardiology.branch + `/` + hash);
                console.log('pdf file url ' + pdfCardiologyData);
                // document.getElementById('pdf-view').classList.remove('d-none');
                // document.getElementsByTagName('html')[0].style.overflow = 'hidden';
                // document.getElementsByTagName('html')[0].classList.add('overflow-auto');
                setIsLoading(false);

                // const contentDisposition = response.headers['content-disposition'];
                // const match = contentDisposition.match(/filename="(.+)"/);
                // // const match = contentDisposition.match(/filename\*?=['"]?([^'";]+)['"]?/);
                // if (match && match[1]) {
                //     const decodedFilename = decodeURI(match[1]);
                //     setFilename(decodedFilename);
                //     document.getElementById('pdf-view').classList.remove('d-none');

                //     console.log(filename);
                // }
                document.getElementById('pdf-view').classList.add('d-none');
                document.getElementById('pdf-lab-view').classList.add('d-none');
                document.getElementById('pdf-radiology-view').classList.add('d-none');
                document.getElementById('pdf-cardiology-view').classList.remove('d-none');
                scrollToReport();
            })
            .catch(error => {
                // document.getElementsByTagName('html')[0].classList.add('overflow-auto');

                // document.getElementsByTagName('html')[0].style.overflow = 'auto';

                setIsLoading(false);

                console.error('Error fetching PDF:', error);
            });


    }


    // get cardiology report 
    const [pdfRadiologyData, setPdfRadiologyData] = useState(null);
    async function getRadiologyReport(allRadiology) {
        // Request body

        const bodyFormData = new FormData();

        // Append data to the FormData object
        bodyFormData.append('pin', localStorage.getItem('pinNumber'));
        bodyFormData.append('studyincid', allRadiology.studyincid);
        // bodyFormData.append('orderid', allRadiology.orderid);
        // bodyFormData.append('test', allRadiology.TestId);
        // bodyFormData.append('branch', allRadiology.branch);

        // console.log('orderid' + allRadiology.orderid);
        // console.log('orderid' + allRadiology.TestId);
        // console.log('orderid' + allRadiology.branch);
        const headers = {

            'Content-Type': 'multipart/form-data',
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
            'token': localStorage.getItem('patientPortalToken'),
        };
        await axios.post(`${baseUrl}/${allRadiology.branch}/pax_image/Viewer.aspx/GetReportEncryptedQuery/${allRadiology.studyincid}`, bodyFormData, { headers })
            .then(response => {
                // const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                // const pdfCardiologyUrl = URL.createObjectURL(pdfBlob);
                // setPdfData(pdfCardiologyUrl);
                // console.log('pdf file url ' + pdfCardiologyUrl);
                // console.log(response.data);
                // const hash = response.data.Edata.hash;
                console.log('rad report ' + response.data);
                // console.log(hash);

                setPdfRadiologyData(response.data);
                console.log('pdf file url ' + pdfRadiologyData);
                setIsLoading(false);

                // document.getElementById('pdf-view').classList.remove('d-none');

                // const contentDisposition = response.headers['content-disposition'];
                // const match = contentDisposition.match(/filename="(.+)"/);
                // // const match = contentDisposition.match(/filename\*?=['"]?([^'";]+)['"]?/);
                // if (match && match[1]) {
                //     const decodedFilename = decodeURI(match[1]);
                //     setFilename(decodedFilename);
                //     document.getElementById('pdf-view').classList.remove('d-none');

                //     console.log(filename);
                // }
                document.getElementById('pdf-view').classList.add('d-none');
                document.getElementById('pdf-lab-view').classList.add('d-none');
                document.getElementById('pdf-cardiology-view').classList.add('d-none');
                document.getElementById('pdf-radiology-view').classList.remove('d-none');
                scrollToReport();
            })
            .catch(error => {
                setIsLoading(false);

                console.error('Error fetching PDF:', error);
            });


    }


    // get lab report 
    const [pdfLabData, setPdfLabData] = useState(null);

    async function getLabReport(allLab) {
        // Request body

        const bodyFormData = new FormData();

        // Append data to the FormData object
        bodyFormData.append('pin', localStorage.getItem('pinNumber'));
        bodyFormData.append('orderid', allLab.orderid);
        bodyFormData.append('test', allLab.TestId);
        bodyFormData.append('branch', allLab.branch);

        console.log('orderid' + allLab.orderid);
        const headers = {
            'Content-Type': 'multipart/form-data',
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
            'token': localStorage.getItem('patientPortalToken'),
        };
        await axios.post(`http://130.7.1.27/mayo_test/public/patientportal/labs/getlink`, bodyFormData, { headers })
            .then(response => {
                // const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                // const pdfLabUrl = URL.createObjectURL(pdfBlob);
                // setPdfData(pdfLabUrl);
                // console.log('pdf file url ' + pdfLabUrl);
                // console.log(response.data);
                const hash = response.data.Edata.hash;
                console.log(response.data);
                console.log(hash);
                setPdfLabData(`http://130.7.1.27/mayo_test/public/patientportal/labs/getpdf/` + `${pinInp}` + `/` + allLab.orderid + `/` + allLab.TestId + `/` + allLab.branch + `/` + hash);
                console.log('pdf file url ' + pdfLabData);
                // document.getElementById('pdf-view').classList.remove('d-none');
                setIsLoading(false);
                // const pdfUrl = 'path_to_your_pdf_file.pdvf';
                const pageNumber = 1;
                // const contentDisposition = response.headers['content-disposition'];
                // const match = contentDisposition.match(/filename="(.+)"/);
                // // const match = contentDisposition.match(/filename\*?=['"]?([^'";]+)['"]?/);
                // if (match && match[1]) {
                //     const decodedFilename = decodeURI(match[1]);
                //     setFilename(decodedFilename);
                //     document.getElementById('pdf-view').classList.remove('d-none');

                //     console.log(filename);
                // }
                document.getElementById('pdf-view').classList.add('d-none');
                document.getElementById('pdf-lab-view').classList.remove('d-none');
                document.getElementById('pdf-cardiology-view').classList.add('d-none');
                document.getElementById('pdf-radiology-view').classList.add('d-none');
                scrollToReport();

            })
            .catch(error => {
                setIsLoading(false);

                console.error('Error fetching PDF:', error);
            });


    }

    const [expandedItems, setExpandedItems] = useState([]);

    const toggleList = (itemId) => {
        if (expandedItems.includes(itemId)) {
            setExpandedItems(expandedItems.filter((id) => id !== itemId));
        } else {
            setExpandedItems([...expandedItems, itemId]);
        }
    };
    const renderArrowIcon = (itemId) => {
        if (expandedItems.includes(itemId)) {
            return (


                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                >
                    <path d="M7 14l5-5 5 5z" />
                </svg>
            );
        } else {
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                >
                    <path d="M7 10l5 5 5-5z" />
                </svg>
            );
        }
    };

    const renderList = (items) => {
        return items.map((item) => (
            <li key={item.id}>
                <div className="item" onClick={() => toggleList(item.id)}>
                    {renderArrowIcon(item.id)}
                    <span
                        style={{
                            fontWeight: expandedItems.includes(item.id) ? 'bold' : 'normal',
                            color: expandedItems.includes(item.id) ? '#4ab242' : 'black',
                        }}
                    >
                        {item.title}
                    </span>
                </div>
                {expandedItems.includes(item.id) && item.children && (
                    <ul className="sub-list">{renderList(item.children)}</ul>
                )}
            </li>
        ));
    };

    const [isCollapsed, setIsCollapsed] = useState(true);

    // const toggleCollapse = () => {
    //     setIsCollapsed(!isCollapsed);
    // };
    // const [date, setDate] = useState(new Date())
    // const [showTime, setShowTime] = useState(false)
    // const options = [
    //     { value: 'Dr.Hany ahmed', label: 'Hany ahmed' },
    //     { value: 'Dr.ali hassan', label: 'ali hassan' }
    // ]
    // const ClinicOptions = [

    //     { value: 'Beta', label: 'Beta', style: { backgroundColor: 'red' } },
    //     { value: 'HBS Test', label: 'HBS Test' },
    //     { value: 'Orthopaedics', label: 'Orthopaedics' },
    //     { value: 'Neurology', label: 'Neurology' },
    //     { value: 'Pharmacy', label: 'Pharmacy' },
    // ]
    // const [activeIndex, setActiveIndex] = useState(-1);
    // const handleClick = (index) => {
    //     setActiveIndex(index === activeIndex ? -1 : index);
    // };

    // function CustomToggle({ children, eventKey }) {
    //     const decoratedOnClick = useAccordionButton(eventKey, () =>
    //         console.log('totally custom!'),
    //     );
    //     return (<button
    //         type="button"
    //         style={{ backgroundColor: 'pink' }}
    //         onClick={decoratedOnClick}
    //     >
    //         {children}
    //     </button>)
    // }
    // let { t, i18n } = useContext(LocalizationContext)
    // const items = [
    //     {
    //         title: "Section 1",
    //         content: "This is the content of section 1"
    //     },
    //     {
    //         title: "Section 2",
    //         content: "This is the content of section 2"
    //     },
    //     {
    //         title: "Section 3",
    //         content: "This is the content of section 3"
    //     }
    // ]

    const [isOpen, setIsOpen] = useState({});

    const toggleCollapse = (itemId) => {
        setIsOpen((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId]
        }));
    };

    const [toggleOpen, setToggleOpen] = useState({});

    const toggleTwoCollapse = (itemId) => {
        setToggleOpen((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId]
        }));
    };


    // useEffect(() => {

    //     // window.scrollTo({ focusedItemRef, behavior: 'smooth' });
    //     // const ref = useRef(null);

    //     const scrollToLastFruit = () => {
    //         if (focusedItem) {
    //             if (focusedItemRef.current) {
    //                 const lastChildElement = focusedItemRef.current?.lastElementChild;
    //                 lastChildElement?.scrollIntoView({ behavior: 'smooth' });
    //             };
    //             // focusedItemRef.current.scrollTo({ behavior: 'smooth' });
    //             // focusedItemRef.current.focus();
    //         }
    //     }
    // }, [focusedItem]);

    const scrollToLastElement = () => {
        // if (focusedItem) {
        if (focusedItemRef.current) {
            focusedItemRef.current.scrollIntoView({ behavior: 'smooth', top: '0' });
        }

    }
    const focusedItemItemReport = useRef(null);
    const scrollToReport = () => {
        // if (focusedItem) {
        if (focusedItemRef.current) {
            focusedItemRef.current.scrollIntoView({ behavior: 'smooth', top: '0' });
        }

    }
    return <>

        {/* <!--page title start--> */}
        <div className="collapse-container rounded-3">
            {/* <button className="toggle-button" onClick={toggleCollapse}>
                {isCollapsed ? <>
                    <div className="container d-flex justify-content-between  align-items-center">
                        <div className='d-flex justify-content-between px-5 align-items-center'>
                            <h5 className='text-center pe-3 m-0'>
                                <img src="../../images/blog/blog-thumb/01.jpg" title=' ' className='rounded-circle ' />
                            </h5>
                            <p className='h4 text-info text-center d-block'>MR. Ahmed Ali</p>

                        </div>
                        <img src='../../images/service/search.png' className='pe-5' /> </div></> : <div className="container d-flex justify-content-between  align-items-center">
                    <div className='d-flex justify-content-between px-5 align-items-center'>
                        <h5 className='text-center pe-3 m-0'>
                            <img src="../../images/blog/blog-thumb/01.jpg" title=' ' className='rounded-circle ' />
                        </h5>
                        <p className='h4 text-info text-center d-block'>MR. Ahmed Ali</p>

                    </div><img src='../../images/service/search.png' className='pe-5' /></div>}
            </button> */}
            {/* <div className={`content ${isCollapsed ? 'collapsed' : 'expanded'}`}> */}

            <section className="py-2 overflow-hidden grey-bg">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        {/* <div className="col-lg-2">
                                <div className="overflow-auto">
                                    <h5 className='mb-2 text-center'>
                                        <img src="../../images/blog/blog-thumb/01.jpg" title=' ' className='rounded-circle ' />
                                    </h5>
                                    <h5 className='h4 text-info text-center d-block'>MR. Ahmed Ali</h5>

                                   
                                    <div className='text-center'>
                                        <span className='text-muted border-end pe-3'>28 Years</span>
                                        <span className='text-muted ps-3'>Male</span>
                                    </div>
                                </div>
                            </div> */}
                        <div className='col-md-12 d-flex ps-5 align-items-center'>
                            <h5 className='text-center pe-3 m-0'>
                                {vitalSummary.sex === '1' ? <img className="img-fluid rounded-circle w-75" src="/images/blog/blog-thumb/profile-female.png" alt="" /> : <img className="img-fluid rounded-circle w-75" src="/images/blog/blog-thumb/profile.png" alt="" />}

                            </h5>
                            <div className='text-center row flex-column justify-content-center'>
                                <p className='h4 text-info text-center d-block'>{vitalSummary.patient_name}</p>
                                <div>
                                    <span className='text-muted border-end pe-3'>{vitalSummary.age}</span>
                                    <span className='text-muted ps-3'>{vitalSummary.sex === '1' ? t('Female') : t('Male')}</span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-12 text-lg-end mt-3 mt-lg-0 ">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb d-flex w-100 justify-content-evenly align-items-center">
                                    <li className="breadcrumb-item">From </li>

                                    <div class="col-sm-4">
                                        <input type="date" class="form-control rounded-3 border form-control-height" />
                                    </div>
                                    <li className="breadcrumb-item">To </li>


                                    <div class="col-sm-4">
                                        <input type="date" class="form-control rounded-3 border form-control-height" />
                                    </div>
                                
                                </ol>
                            </nav>
                        </div> */}
                    </div>
                </div>
            </section>
            <div className="container">

                <div className="row">


                    {/* <div class="col-md-6 my-5 text-center"> */}



                    <Modal   {...props}
                        size="lg"
                        style={customStyles}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered show={show} onHide={handleClose} className="top-0 ">

                        <Modal.Header closeButton>
                            <img className="" src='/images/cropped-9952-img-32x32.jpg' alt="" />

                            <Modal.Title style={{ color: "#4ab242" }}>{t('Patient Portal')}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="team-member text-center">
                                {/* <div className="team-images">
                <img className="img-fluid" src="images/team/04.jpg" alt="" />
            </div> */}
                                <div className="team-description">
                                    <h5 className="mb-4"><Link>{t('Your Session Has Been Expired!')} </Link></h5>
                                    <p className='lead'>{t('Please')} <Link className='text-decoration-underline' to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}`}>{t('login')}</Link> {t('Again')}</p>
                                </div>


                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0' variant="secondary" onClick={handleClose}>
                                {t('Close')}
                            </Button>

                        </Modal.Footer>

                    </Modal>
                </div>
                {/* </div> */}
            </div>
            {/* </div > */}
        </div >
        {/* <!--page title end--> */}


        < section className='pt-3' >
            <div className="container">

                <div className="row justify-content-center align-items-center">



                    <div className="col-lg-4 col-md-12 mt-3 mt-lg-0">


                        <div className="featured-step rounded-2 h-50 position-relative pt-0 mt-2 left-card">
                            <div className='d-flex justify-content-evenly align-items-start position-sticky top-title row'>
                                <div className="text-center w-100">
                                    <h5 className='pt-2 mt-2'>{t('Visits')}</h5>

                                    <div className='row p-0'>
                                        <span className='pe-2 top-0 position-sticky top-title col-sm-4'>
                                            <img src="/images/service/doctor.png" title='Visits icon' className='icon-featured' />

                                        </span>
                                        <div className="mb-3 text-center col-sm-8 m-0 p-0 overflow-auto featured-title">
                                            <div className='position-sticky bg-white top-title py-2'>
                                            </div>
                                            {visitsSummary.length > 0 ? <>
                                                <div className="featured-desc text-start ">

                                                    <ul className='list-unstyled ps-3'>
                                                        {visitsSummary.map((visit, index) => <>
                                                            <li className='text-muted mb-2 visits-list' key={index} onClick={() => getAllVisits(visit.name)}>
                                                                <span class="badge badge-sm bg-shadow-green"></span>

                                                                <span className='fw-bolder ps-2 span-detials' onClick={scrollToLastElement}>{visit.name} : </span>
                                                                <VisibilitySensor partialVisibility >
                                                                    {({ isVisible }) => (
                                                                        <span className='count-up ps-2'>

                                                                            {isVisible ? <CountUp end={visit.tot} /> : visit.tot}
                                                                        </span>
                                                                    )}
                                                                </VisibilitySensor>
                                                            </li >
                                                        </>)}
                                                        {/* <li className='text-muted mb-2 visits-list'>
                                                        <span class="badge badge-sm bg-shadow-green"></span>

                                                        <span className='fw-bolder ps-2 span-detials'>Orthopedic : </span>
                                                        <VisibilitySensor partialVisibility >
                                                            {({ isVisible }) => (
                                                                <span className='count-up ps-2'>

                                                                    {isVisible ? <CountUp end={6} /> : 6}
                                                                </span>
                                                            )}
                                                        </VisibilitySensor>
                                                    </li>
                                                    <li className='text-muted mb-2 visits-list'>
                                                        <span class="badge badge-sm bg-shadow-green"></span>

                                                        <span className='fw-bolder ps-2 span-detials'>Internal Medicine : </span>
                                                        <VisibilitySensor partialVisibility >
                                                            {({ isVisible }) => (
                                                                <span className='count-up ps-2'>

                                                                    {isVisible ? <CountUp end={24} /> : 24}
                                                                </span>
                                                            )}
                                                        </VisibilitySensor>
                                                    </li>
                                                    <li className='text-muted mb-2 visits-list'>
                                                        <span class="badge badge-sm bg-shadow-green"></span>

                                                        <span className='fw-bolder ps-2 span-detials'>Cardiology : </span>
                                                        <VisibilitySensor partialVisibility >
                                                            {({ isVisible }) => (
                                                                <span className='count-up ps-2'>

                                                                    {isVisible ? <CountUp end={35} /> : 35}
                                                                </span>
                                                            )}
                                                        </VisibilitySensor>
                                                    </li> */}
                                                    </ul>
                                                </div>
                                            </> : <><span className='fw-bolder span-detials'>{t('No Data Available !')}</span> <p className='fw-bolder span-detials'>{t('It seems that there is no Visits')}</p></>}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="featured-step rounded-2 h-50 position-relative pt-0 mt-2 left-two-card">
                            <div className='d-flex justify-content-evenly align-items-start position-sticky top-title row'>
                                <div className="text-center w-100">
                                    <h5 className='pt-2 mt-2'>{t('Lab')}</h5>

                                    <div className='row p-0'>
                                        <span className='pe-2 top-0 position-sticky top-title col-sm-4'>
                                            <img src="/images/service/microscope.png" title='Lab icon' className='icon-featured' />

                                        </span>
                                        <div className="mb-3 text-center col-sm-8 m-0 p-0 overflow-auto featured-title">
                                            <div className='position-sticky bg-white top-title py-2'>
                                            </div>
                                            {labSummary.length > 0 ? <>

                                                <div className="featured-desc text-start ">

                                                    <ul className='list-unstyled ps-3'>
                                                        {labSummary.map((lab, index) => <>
                                                            <li className='text-muted mb-2 visits-list' key={index} onClick={() => getAllLab(lab.name)}>
                                                                <span class="badge badge-sm bg-shadow-blue"></span>
                                                                <span className='fw-bolder ps-2 span-detials' onClick={scrollToLastElement}>{lab.name} : </span>
                                                                <VisibilitySensor partialVisibility >
                                                                    {({ isVisible }) => (
                                                                        <span className='count-up ps-2'>

                                                                            {isVisible ? <CountUp end={lab.tot} /> : lab.tot}
                                                                        </span>
                                                                    )}
                                                                </VisibilitySensor>
                                                            </li>
                                                        </>)}

                                                        {/* <li className='text-muted mb-2 visits-list'>
                                                        <span class="badge badge-sm bg-shadow-blue"></span>
                                                        <span className='fw-bolder ps-2 span-detials'>Orthopedic : </span>
                                                        <VisibilitySensor partialVisibility >
                                                            {({ isVisible }) => (
                                                                <span className='count-up ps-2'>

                                                                    {isVisible ? <CountUp end={6} /> : 6}
                                                                </span>
                                                            )}
                                                        </VisibilitySensor>
                                                    </li>
                                                    <li className='text-muted mb-2 visits-list'>
                                                        <span class="badge badge-sm bg-shadow-blue"></span>
                                                        <span className='fw-bolder ps-2 span-detials'>Internal Medicine : </span>
                                                        <VisibilitySensor partialVisibility >
                                                            {({ isVisible }) => (
                                                                <span className='count-up ps-2'>

                                                                    {isVisible ? <CountUp end={24} /> : 24}
                                                                </span>
                                                            )}
                                                        </VisibilitySensor>
                                                    </li>
                                                    <li className='text-muted mb-2 visits-list'>
                                                        <span class="badge badge-sm bg-shadow-blue"></span>
                                                        <span className='fw-bolder ps-2 span-detials'>Cardiology : </span>
                                                        <VisibilitySensor partialVisibility >
                                                            {({ isVisible }) => (
                                                                <span className='count-up ps-2'>

                                                                    {isVisible ? <CountUp end={35} /> : 35}
                                                                </span>
                                                            )}
                                                        </VisibilitySensor>
                                                    </li> */}
                                                    </ul>
                                                </div>
                                            </> : <><span className='fw-bolder span-detials'>{t('No Data Available !')}</span> <p className='fw-bolder span-detials'>{t('It seems that there is no Lab')}</p></>}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="col-lg-3 col-md-12">

                        <div className="featured-step rounded-2 overflow-auto mt-2 py-5 bottom-card">
                            <h5 className="mb-3 text-center pt-2">{t('Patient Assessment')}</h5>
                            <div className="featured-desc d-flex justify-content-center align-items-center">
                                <span className='pe-2'>
                                    <img src="/images/service/png-2.png" title=' ' />
                                </span>
                                {Object.keys(vitalSummary).length > 0 ? <>

                                    {/* <p class="m-0"><span class="badge badge-sm bg-Tacha"></span></p> */}
                                    <ul className='list-unstyled'>
                                        <li className='text-muted mb-2 visits-list'>
                                            <span class="badge badge-sm bg-shadow-green"></span>
                                            <span className='fw-bolder ps-2 span-detials'>{t('Height')} : </span>{parseInt(vitalSummary.height)} {t('CM')}</li>
                                        <li className='text-muted mb-2 visits-list'>
                                            <span class="badge badge-sm bg-lightgreen"></span>
                                            <span className='fw-bolder ps-2 span-detials'>{t('Weight')} : </span>{parseInt(vitalSummary.weight)} {t('KG')}</li>
                                        <li className='text-muted mb-2 visits-list vital-bmi' thelittledetails={message}>
                                            <span class="badge badge-sm bg-lightorange"></span>
                                            <span className='fw-bolder ps-2 span-detials'>{t('BMI')} : </span> {Number(bmi)}</li>
                                    </ul>
                                </> : <><span className='fw-bolder span-detials text-center'>{t('No Data Available !')} <br /> {t('It seems that there is no Patient Assessment')}</span></>}

                            </div>

                        </div>

                    </div>
                    <div className="col-lg-4 col-md-12 mt-3 mt-lg-0">

                        <div className="featured-step rounded-2 h-50 position-relative pt-0 mt-2 right-card">
                            <div className='d-flex justify-content-evenly align-items-start position-sticky top-title row'>
                                <div className="text-center w-100">
                                    <h5 className='pt-2 mt-2'>{t('Cardiology')}</h5>

                                    <div className='row p-0'>
                                        <span className='pe-2 top-0 position-sticky top-title col-sm-4'>
                                            <img src="/images/service/heart.png" title='Cardiology icon' className='icon-featured' />

                                        </span>
                                        <div className="mb-3 text-center col-sm-8 m-0 p-0 overflow-auto featured-title">
                                            <div className='position-sticky bg-white top-title py-2'>
                                            </div>

                                            {cardiologySummary.length > 0 ? <>
                                                <div className="featured-desc text-start ">

                                                    <ul className='list-unstyled ps-3'>
                                                        {cardiologySummary.map((cardiology, index) => <>

                                                            <li className='text-muted mb-2 visits-list' key={index} onClick={getAllCardiology}>
                                                                <span class="badge badge-sm bg-shadow-pink"></span>

                                                                <span className='fw-bolder ps-2 span-detials' onClick={scrollToLastElement}>{cardiology.name} : </span>
                                                                <VisibilitySensor partialVisibility >
                                                                    {({ isVisible }) => (
                                                                        <span className='count-up ps-2'>

                                                                            {isVisible ? <CountUp end={cardiology.tot} /> : cardiology.tot}
                                                                        </span>
                                                                    )}
                                                                </VisibilitySensor>
                                                            </li>
                                                        </>)}
                                                        {/* <li className='text-muted mb-2 visits-list'>
                                                        <span class="badge badge-sm bg-shadow-green"></span>

                                                        <span className='fw-bolder ps-2 span-detials'>Orthopedic : </span>
                                                        <VisibilitySensor partialVisibility >
                                                            {({ isVisible }) => (
                                                                <span className='count-up ps-2'>

                                                                    {isVisible ? <CountUp end={6} /> : 6}
                                                                </span>
                                                            )}
                                                        </VisibilitySensor>
                                                    </li>
                                                    <li className='text-muted mb-2 visits-list'>
                                                        <span class="badge badge-sm bg-shadow-green"></span>

                                                        <span className='fw-bolder ps-2 span-detials'>Internal Medicine : </span>
                                                        <VisibilitySensor partialVisibility >
                                                            {({ isVisible }) => (
                                                                <span className='count-up ps-2'>

                                                                    {isVisible ? <CountUp end={24} /> : 24}
                                                                </span>
                                                            )}
                                                        </VisibilitySensor>
                                                    </li>
                                                    <li className='text-muted mb-2 visits-list'>
                                                        <span class="badge badge-sm bg-shadow-green"></span>

                                                        <span className='fw-bolder ps-2 span-detials'>Cardiology : </span>
                                                        <VisibilitySensor partialVisibility >
                                                            {({ isVisible }) => (
                                                                <span className='count-up ps-2'>

                                                                    {isVisible ? <CountUp end={35} /> : 35}
                                                                </span>
                                                            )}
                                                        </VisibilitySensor>
                                                    </li> */}
                                                    </ul>
                                                </div>
                                            </> : <><span className='fw-bolder span-detials'>{t('No Data Available !')}</span> <p className='fw-bolder span-detials'>{t('It seems that there is no cardiology')}</p></>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="featured-step rounded-2 h-50 position-relative pt-0 mt-2 right-two-card">
                            <div className='d-flex justify-content-evenly align-items-start position-sticky top-title row'>
                                <div className="text-center w-100">
                                    <h5 className='pt-2 mt-2'>{t('Radiology')}</h5>

                                    <div className='row p-0'>
                                        <span className='pe-2 top-0 position-sticky top-title col-sm-4'>
                                            <img src="/images/service/ct-scan.png" title='Radiology icon' className='icon-featured' />

                                        </span>
                                        <div className="mb-3 text-center col-sm-8 m-0 p-0 overflow-auto featured-title">
                                            <div className='position-sticky bg-white top-title py-2'>
                                            </div>
                                            {radSummary.length > 0 ? <>
                                                <div className="featured-desc text-start ">

                                                    <ul className='list-unstyled ps-3'>
                                                        {radSummary.map((radiology, index) => <>
                                                            <li className='text-muted mb-2 visits-list' key={index} onClick={() => getAllRadology()}>
                                                                <span class="badge badge-sm bg-shadow-gray"></span>

                                                                <span className='fw-bolder ps-2 span-detials' onClick={scrollToLastElement}>{radiology.name} : </span>
                                                                <VisibilitySensor partialVisibility >
                                                                    {({ isVisible }) => (
                                                                        <span className='count-up ps-2'>

                                                                            {isVisible ? <CountUp end={radiology.tot} /> : radiology.tot}
                                                                        </span>
                                                                    )}
                                                                </VisibilitySensor>
                                                            </li>
                                                        </>)}
                                                        {/* <li className='text-muted mb-2 visits-list'>
                                                        <span class="badge badge-sm bg-shadow-green"></span>

                                                        <span className='fw-bolder ps-2 span-detials'>Orthopedic : </span>
                                                        <VisibilitySensor partialVisibility >
                                                            {({ isVisible }) => (
                                                                <span className='count-up ps-2'>

                                                                    {isVisible ? <CountUp end={6} /> : 6}
                                                                </span>
                                                            )}
                                                        </VisibilitySensor>
                                                    </li>
                                                    <li className='text-muted mb-2 visits-list'>
                                                        <span class="badge badge-sm bg-shadow-green"></span>

                                                        <span className='fw-bolder ps-2 span-detials'>Internal Medicine : </span>
                                                        <VisibilitySensor partialVisibility >
                                                            {({ isVisible }) => (
                                                                <span className='count-up ps-2'>

                                                                    {isVisible ? <CountUp end={24} /> : 24}
                                                                </span>
                                                            )}
                                                        </VisibilitySensor>
                                                    </li>
                                                    <li className='text-muted mb-2 visits-list'>
                                                        <span class="badge badge-sm bg-shadow-green"></span>

                                                        <span className='fw-bolder ps-2 span-detials'>Cardiology : </span>
                                                        <VisibilitySensor partialVisibility >
                                                            {({ isVisible }) => (
                                                                <span className='count-up ps-2'>

                                                                    {isVisible ? <CountUp end={35} /> : 35}
                                                                </span>
                                                            )}
                                                        </VisibilitySensor>
                                                    </li> */}
                                                    </ul>
                                                </div>
                                            </> : <><span className='fw-bolder span-detials'>{t('No Data Available !')}</span> <p className='fw-bolder span-detials'>{t('It seems that there is no Radiology')}</p></>}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                {/* <Tabs className="row">
                    <div className="col-lg-4 col-md-12 sidebar mt-5 mt-lg-0">


                        <div className="tab">
                          
                            <div className="tab-content" style={{ padding: "30px 30px 30px 0" }} id="nav-tabContent">
                                <div role="tabpanel" className="tab-pane fade show active" id="tab1-1">

                                    <div className="widget mb-5" style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", borderRadius: "4px" }}>
                                        <Accordion>
                                            <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="0">
                                                <Accordion.Header>Accordion Item #1</Accordion.Header>
                                                <Accordion.Body>

                                                    <Accordion>
                                                        <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="0">
                                                            <Accordion.Header>Accordion Item #1</Accordion.Header>
                                                            <Accordion.Body>
                                                                <Accordion>
                                                                    <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="0">

                                                                        <Accordion.Header>Accordion Item #1</Accordion.Header>

                                                                        <TabList className="list-unstyled" style={{ cursor: 'pointer' }} >
                                                                            <Tab className="border-none" style={{ outline: "none" }}>
                                                                                <Accordion.Body>
                                                                                    Lorem
                                                                                </Accordion.Body>
                                                                            </Tab>
                                                                            <Tab className="border-none" style={{ outline: "none" }}>
                                                                                <Accordion.Body>
                                                                                    Lorem
                                                                                </Accordion.Body>
                                                                            </Tab>
                                                                        </TabList>



                                                                    </Accordion.Item>
                                                                    <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="1">
                                                                        <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            Lorem 2
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="1">
                                                            <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                            <Accordion.Body>
                                                                Lorem 3
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="1">
                                                <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                <Accordion.Body>
                                                    Lorem 4
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                                <div role="tabpanel" className="tab-pane fade" id="tab1-2">

                                    <div className="widget mb-5" style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", borderRadius: "4px" }}>
                                        <Accordion>
                                            <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="0">
                                                <Accordion.Header>Accordion Item #1</Accordion.Header>
                                                <Accordion.Body>

                                                    <Accordion>
                                                        <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="0">
                                                            <Accordion.Header>Accordion Item #1</Accordion.Header>
                                                            <Accordion.Body>
                                                                <Accordion>
                                                                    <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="0">

                                                                        <Accordion.Header>Accordion Item #1</Accordion.Header>

                                                                        <TabList className="list-unstyled" style={{ cursor: 'pointer' }} >
                                                                            <Tab className="border-none" style={{ outline: "none" }}>
                                                                                <Accordion.Body>
                                                                                    Lorem
                                                                                </Accordion.Body>
                                                                            </Tab>
                                                                            <Tab className="border-none" style={{ outline: "none" }}>
                                                                                <Accordion.Body>
                                                                                    Lorem
                                                                                </Accordion.Body>
                                                                            </Tab>
                                                                        </TabList>



                                                                    </Accordion.Item>
                                                                    <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="1">
                                                                        <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            Lorem 5
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="1">
                                                            <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                            <Accordion.Body>
                                                                Lorem 6
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="1">
                                                <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                <Accordion.Body>
                                                    Lorem 7
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                                <div role="tabpanel" className="tab-pane fade" id="tab1-3">

                                    <div className="widget mb-5" style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", borderRadius: "4px" }}>
                                        <Accordion>
                                            <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="0">
                                                <Accordion.Header>Accordion Item #1</Accordion.Header>
                                                <Accordion.Body>

                                                    <Accordion>
                                                        <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="0">
                                                            <Accordion.Header>Accordion Item #1</Accordion.Header>
                                                            <Accordion.Body>
                                                                <Accordion>
                                                                    <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="0">

                                                                        <Accordion.Header>Accordion Item #1</Accordion.Header>

                                                                        <TabList className="list-unstyled" style={{ cursor: 'pointer' }} >
                                                                            <Tab className="border-none" style={{ outline: "none" }}>
                                                                                <Accordion.Body>
                                                                                    Lorem
                                                                                </Accordion.Body>
                                                                            </Tab>
                                                                            <Tab className="border-none" style={{ outline: "none" }}>
                                                                                <Accordion.Body>
                                                                                    Lorem
                                                                                </Accordion.Body>
                                                                            </Tab>
                                                                        </TabList>



                                                                    </Accordion.Item>
                                                                    <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="1">
                                                                        <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            Lorem 8
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="1">
                                                            <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                            <Accordion.Body>
                                                                Lorem 9
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="1">
                                                <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                <Accordion.Body>
                                                    Lorem 10
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>

                                <div role="tabpanel" className="tab-pane fade" id="tab1-4">

                                    <div className="widget mb-5" style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", borderRadius: "4px" }}>
                                        <Accordion>
                                            <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="0">
                                                <Accordion.Header>Accordion Item #1</Accordion.Header>
                                                <Accordion.Body>

                                                    <Accordion>
                                                        <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="0">
                                                            <Accordion.Header>Accordion Item #1</Accordion.Header>
                                                            <Accordion.Body>
                                                                <Accordion>
                                                                    <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="0">

                                                                        <Accordion.Header>Accordion Item #1</Accordion.Header>

                                                                        <TabList className="list-unstyled" style={{ cursor: 'pointer' }} >
                                                                            <Tab className="border-none" style={{ outline: "none" }}>
                                                                                <Accordion.Body>
                                                                                    Lorem
                                                                                </Accordion.Body>
                                                                            </Tab>
                                                                            <Tab className="border-none" style={{ outline: "none" }}>
                                                                                <Accordion.Body>
                                                                                    Lorem
                                                                                </Accordion.Body>
                                                                            </Tab>
                                                                        </TabList>



                                                                    </Accordion.Item>
                                                                    <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="1">
                                                                        <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            Lorem 11
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="1">
                                                            <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                            <Accordion.Body>
                                                                Lorem 12
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item style={{ backgroundColor: "#fff", border: "1px solid #f0f0f0", marginBottom: " 0" }} eventKey="1">
                                                <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                <Accordion.Body>
                                                    Lorem 13
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div className="col-lg-8 col-md-12">


                        <TabPanel>
                            <div className="about-2 mt-3 rounded-2">
                                <div className='team-member style-2' style={{ marginTop: "0", }}>
                                    <div className="team-images bg-new rounded-lg" style={{
                                        display: "flex", background: "#4ab242",
                                        color: "#ffffff",
                                        padding: "15px",
                                        justifyContent: "center",
                                        marginBottom: "0",
                                        alignItems: "center"
                                    }}>
                                        <img src='/images/team/01.jpg' alt='Dr Name' title='Dr Name' className='img-fluid' style={{ margin: "0", width: "100px" }} />
                                        <h3 className="font-w-6 text-uppercase bg-transparent"> DR. Amr Ibrahim</h3>

                                    </div>
                                </div>
                                <ul className="list-unstyled working-hours text-white" style={{ padding: "0", backgroundImage: "url('/images/about/03.jpg')" }} data-bg-img="/images/about/03.jpg" data-overlay="8">
                                    <div style={{ width: "100%" }}>
                                        <Calendar onChange={setDate} className="w-100 p-5 h-auto" value={date} onClickDay={() => setShowTime(true)} />
                                    </div>

                                   
                                </ul>
                            </div>
                            <div className="service-details mt-4 d-none">
                                <h2 className="title">Pediatrics <span className='count-up ps-2'>Depertment</span></h2>
                                <p className="lead">llamcorper suscipit lobortis nisl ut aliquip ex ea commodo non habent claritatem
                                    insitamconsequat duis autem facilisis at vero eros nibh euismod tincidunt ut laoreet vel eum
                                    iriure Excepturi vero minima impedit aliquam id.</p>
                                <p className="mb-0">consectetur adipisicing elit. Totam mollitia incidunt vero cupiditate obcaecati
                                    iusto tempora unde! Numquam officiis, quae adipisci quam laudantium nulla modi. adipisci quam
                                    laudantium nulla modi. Totam mollitia incidunt vero cupiditate obcaecati</p>
                            </div>
                        </TabPanel>
                        <TabPanel>



                            <div className="left-side mt-3">
                                <div>
                                    <div className="service-images">
                                        <img className="img-fluid w-100" src="/images/service/large/01.jpg" alt="" />
                                    </div>

                                </div>
                            </div>
                        </TabPanel>

                    </div>
                </Tabs> */}

            </div >



            {Object.keys(groupedVisits).length > 0 || Object.keys(groupedLabs).length > 0 || Object.keys(groupedCardiology).length > 0 || Object.keys(groupedRadiology).length > 0 ? <>
                <div class="container mt-5 overflow-auto" ref={focusedItemRef}>
                    <div className="row justify-content-between">
                        <nav class="sidebar sidebar-offcanvas col-lg-4 col-md-5 overflow-auto" id="sidebar">
                            <ul class="nav row w-100">

                                <li class="pt-2 patient-history-title">
                                    <Link class="nav-link d-flex align-items-center justify-content-center" to="">
                                        {/* <img class="sidebar-brand-logo" src="/../assets/images/logo.svg" alt="" /> */}
                                        <img class="sidebar-brand-logomini pe-3" src="/images/cropped-9952-img-32x32.jpg" alt="" />
                                        {Object.keys(groupedVisits).length > 0 ? <span class="text-black fs-4 font-weight-light pt-1">{t('Patient Visits')}</span> : ''}
                                        {Object.keys(groupedLabs).length > 0 ? <span class="text-black fs-4 font-weight-light pt-1">{t('Lab Investigation')}</span> : ''}
                                        {Object.keys(groupedCardiology).length > 0 ? <span class="text-black fs-4 font-weight-light pt-1">{t('Cardiology Investigation')}</span> : ''}
                                        {Object.keys(groupedRadiology).length > 0 ? <span class="text-black fs-4 font-weight-light pt-1">{t('Radiology Investigation')}</span> : ''}
                                    </Link>

                                </li>
                                {/* <div className="collapse-container"> */}
                                {/* <button className="toggle-button" onClick={toggleCollapse}> */}
                                {/* {isCollapsed ? <> */}


                                {Object.keys(groupedVisits)?.map((department, index) => <>
                                    <React.Fragment key={index}>

                                        <li className="nav-item fw-bolder" >
                                            <Link
                                                className="nav-link p-0"
                                                data-bs-toggle="collapse"
                                                to=""
                                                aria-expanded={isOpen[index]}
                                                aria-controls="ui-basic"
                                                onClick={() => toggleCollapse(index)}
                                            >
                                                <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                                                <span className="menu-title text-muted fs-6">{department}</span>
                                                <i className="menu-arrow"></i>
                                                {/* {groupedVisits[department].map((groupedVisit, index) => (
                                                    <span className="branch text-capitalize" key={index}>{groupedVisit.branch}</span>
                                                ))} */}
                                            </Link>
                                        </li>
                                        <div className={isOpen[index] ? "collapse show w-100" : "collapse w-100"} id="ui-basic">
                                            <ul className="nav sub-menu justify-content-center">
                                                {groupedVisits[department].map((groupedVisit, index) => (
                                                    <li className="nav-item report-item" key={index} onClick={() => getVisitsReport(groupedVisit)}>
                                                        <Link
                                                            className="nav-link p-0"
                                                            to=''
                                                            data-bs-toggle="collapse"
                                                            aria-expanded={toggleOpen[index]}
                                                            aria-controls="ui-basic"

                                                        >
                                                            {groupedVisit.visitdate}
                                                        </Link>
                                                        <span>{groupedVisit.doc_name}</span>
                                                        <span className="branch text-capitalize" key={index}>{groupedVisit.branch}</span>

                                                    </li>
                                                ))}
                                                {/* <div className={toggleOpen[index] ? "collapse show" : "collapse"} id="ui-basic">
                                                    <ul className="nav flex-column sub-menu">
                                                        <li className="nav-item collapse-nav-item">
                                                            <Link
                                                                className="nav-link p-0"
                                                                to=""
                                                                data-bs-toggle="collapse"
                                                                aria-expanded={isOpen[index]}
                                                                aria-controls="ui-basic"
                                                            >
                                                                {allVisit.doc_name}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                </>)}



                                {Object.keys(groupedLabs)?.map((department, index) => <>
                                    <React.Fragment key={index}>
                                        <li className="nav-item fw-bolder">
                                            <Link
                                                className="nav-link p-0"
                                                data-bs-toggle="collapse"
                                                to=""
                                                aria-expanded={isOpen[index]}
                                                aria-controls="ui-basic"
                                                onClick={() => toggleCollapse(index)}
                                            >
                                                <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                                                <span className="menu-title text-muted fs-6">{department}</span>
                                                <i className="menu-arrow"></i>

                                                {/* {groupedLabs[department].map((groupedLab, index) => (
                                                    <span className="branch text-capitalize" key={index}>{groupedLab.branch}</span>
                                                ))} */}
                                            </Link>
                                        </li>
                                        <div className={isOpen[index] ? "collapse show w-100" : "collapse w-100"} id="ui-basic">
                                            <ul className="nav sub-menu justify-content-center">
                                                {groupedLabs[department].map((groupedLab, index) => (
                                                    <li className="nav-item report-item" key={index} onClick={() => getLabReport(groupedLab)}>
                                                        <Link
                                                            className="nav-link p-0"
                                                            to=""
                                                            data-bs-toggle="collapse"
                                                            aria-expanded={toggleOpen[index]}
                                                            aria-controls="ui-basic"
                                                        >
                                                            {groupedLab.testdate}
                                                        </Link>
                                                        <span>{groupedLab.TestName}</span>
                                                        <span className="branch text-capitalize" key={index}>{groupedLab.branch}</span>

                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                </>)}

                                {Object.keys(groupedCardiology)?.map((department, index) => <>
                                    <React.Fragment key={index}>
                                        <li className="nav-item fw-bolder">
                                            <Link
                                                className="nav-link p-0"
                                                data-bs-toggle="collapse"
                                                to=""
                                                aria-expanded={isOpen[index]}
                                                aria-controls="ui-basic"
                                                onClick={() => toggleCollapse(index)}
                                            >
                                                <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                                                <span className="menu-title text-muted fs-6">{department}</span>
                                                <i className="menu-arrow"></i>
                                                {/* {groupedCardiology[department].map((groupedCard, index) => (
                                                    <span className="branch text-capitalize" key={index}>{groupedCard.branch}</span>
                                                ))} */}
                                            </Link>
                                        </li>
                                        <div className={isOpen[index] ? "collapse show w-100" : "collapse w-100"} id="ui-basic">
                                            <ul className="nav sub-menu justify-content-center">
                                                {groupedCardiology[department].map((groupedCard, index) => (

                                                    <li className="nav-item report-item" key={index} onClick={() => getCardiologyReport(groupedCard)}>
                                                        <Link
                                                            className="nav-link p-0"
                                                            to=""
                                                            data-bs-toggle="collapse"
                                                            aria-expanded={toggleOpen[index]}
                                                            aria-controls="ui-basic"

                                                        >
                                                            {groupedCard.testdate}
                                                        </Link>
                                                        <span> {groupedCard.DoctorName}</span>
                                                        <span className="branch text-capitalize" key={index}>{groupedCard.branch}</span>

                                                    </li>
                                                ))}
                                                {/* <div className={toggleOpen[index] ? "collapse show" : "collapse"} id="ui-basic">
                                                    <ul className="nav flex-column sub-menu">
                                                        <li className="nav-item collapse-nav-item">
                                                            <Link
                                                                className="nav-link p-0"
                                                                to=""
                                                                data-bs-toggle="collapse"
                                                                aria-expanded={isOpen[index]}
                                                                aria-controls="ui-basic"
                                                            >
                                                                {cardiology.DoctorName}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                </>)}

                                {Object.keys(groupedRadiology)?.map((date, index) => <>
                                    <React.Fragment key={index}>
                                        <li className="nav-item fw-bolder">
                                            <Link
                                                className="nav-link p-0"
                                                data-bs-toggle="collapse"
                                                to=""
                                                aria-expanded={isOpen[index]}
                                                aria-controls="ui-basic"
                                                onClick={() => toggleCollapse(index)}
                                            >
                                                <i className="mdi mdi-crosshairs-gps menu-icon"></i>
                                                <span className="menu-title text-muted fs-6">{date}</span>
                                                <i className="menu-arrow"></i>
                                                {/* {groupedRadiology[department].map((groupedCard, index) => (
                                                    <span className="branch text-capitalize" key={index}>{groupedCard.branch}</span>
                                                ))} */}
                                            </Link>
                                        </li>
                                        <div className={isOpen[index] ? "collapse show w-100" : "collapse w-100"} id="ui-basic">
                                            <ul className="nav sub-menu justify-content-center">
                                                {groupedRadiology[date].map((groupedRad, index) => (

                                                    <li className="nav-item report-item" key={index} onClick={() => getRadiologyReport(groupedRad)}>
                                                        <Link
                                                            className="nav-link p-0"
                                                            to=""
                                                            data-bs-toggle="collapse"
                                                            aria-expanded={toggleOpen[index]}
                                                            aria-controls="ui-basic"

                                                        >
                                                            {groupedRad.doctor}
                                                        </Link>
                                                        <span> {groupedRad.procedure_name}</span>
                                                        <span className="branch text-capitalize" key={index}>{groupedRad.branch}</span>

                                                    </li>
                                                ))}
                                                {/* <div className={toggleOpen[index] ? "collapse show" : "collapse"} id="ui-basic">
                                                    <ul className="nav flex-column sub-menu">
                                                        <li className="nav-item collapse-nav-item">
                                                            <Link
                                                                className="nav-link p-0"
                                                                to=""
                                                                data-bs-toggle="collapse"
                                                                aria-expanded={isOpen[index]}
                                                                aria-controls="ui-basic"
                                                            >
                                                                {cardiology.DoctorName}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                </>)}
                                {/* <li class="nav-item">
                                    <Link class="nav-link p-0" data-bs-toggle="collapse" to="" aria-expanded="false" aria-controls="ui-basic">
                                        <i class="mdi mdi-crosshairs-gps menu-icon"></i>
                                        <span class="menu-title text-muted fs-6">Internal Medicine</span>
                                        <i class="menu-arrow"></i>
                                        <span className='branch'>Alex</span>
                                    </Link>
                                    <div class="collapse" id="ui-basic">
                                        <ul class="nav flex-column sub-menu">
                                            <li class="nav-item">
                                                <Link class="nav-link p-0" to="">Dr. Mohamed Samy</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link p-0" to="">22 - 10 - 2023</Link>
                                            </li>

                                        </ul>
                                    </div>
                                </li> */}
                                {/* </> : <>
                                    <li class="nav-item">
                                        <Link class="nav-link p-0" data-bs-toggle="collapse" to="" aria-expanded="false" aria-controls="ui-basic">
                                            <i class="mdi mdi-crosshairs-gps menu-icon"></i>
                                            <span class="menu-title text-muted fs-6">Internal Medicine</span>
                                            <i class="menu-arrow"></i>
                                            <span className='branch'>Alex</span>
                                        </Link>
                                        <div class="collapse" id="ui-basic">
                                            <ul class="nav flex-column sub-menu">
                                                <li class="nav-item">
                                                    <Link class="nav-link" to="">Dr. Mohamed Samy</Link>
                                                </li>
                                                <li class="nav-item">
                                                    <Link class="nav-link" to="">22 - 10 - 2023</Link>
                                                </li>
                                                <li class="nav-item">
                                                    <Link class="nav-link" to="">Typography</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li></> */}
                                {/* } */}
                                {/* </button> */}
                                {/* </div> */}
                                {/* <li class="nav-item">
                                    <Link class="nav-link p-0" data-bs-toggle="collapse" to="" aria-expanded="false" aria-controls="ui-basic">
                                        <i class="mdi mdi-crosshairs-gps menu-icon"></i>
                                        <span class="menu-title text-muted fs-6">Cardiology</span>
                                        <i class="menu-arrow"></i>
                                        <span className='branch'>Cairo</span>
                                    </Link>
                                    <div class="collapse" id="ui-basic">
                                        <ul class="nav flex-column sub-menu">
                                            <li class="nav-item">
                                                <Link class="nav-link" to="">Dr. Mohamed Samy</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" to="">22 - 10 - 2023</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" to="">Typography</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link p-0" data-bs-toggle="collapse" to="" aria-expanded="false" aria-controls="ui-basic">
                                        <i class="mdi mdi-crosshairs-gps menu-icon"></i>
                                        <span class="menu-title text-muted fs-6">Internal Medicine</span>
                                        <i class="menu-arrow"></i>
                                        <span className='branch'>Alex</span>
                                    </Link>
                                    <div class="collapse" id="ui-basic">
                                        <ul class="nav flex-column sub-menu">
                                            <li class="nav-item">
                                                <Link class="nav-link" to="">Dr. Mohamed Samy</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" to="">22 - 10 - 2023</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" to="">Typography</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link p-0" data-bs-toggle="collapse" to="" aria-expanded="false" aria-controls="ui-basic">
                                        <i class="mdi mdi-crosshairs-gps menu-icon"></i>
                                        <span class="menu-title text-muted fs-6">Dermatology</span>
                                        <i class="menu-arrow"></i>
                                        <span className='branch'>Alex</span>
                                    </Link>
                                    <div class="collapse" id="ui-basic">
                                        <ul class="nav flex-column sub-menu">
                                            <li class="nav-item">
                                                <Link class="nav-link" to="">Dr. Mohamed Samy</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" to="">22 - 10 - 2023</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" to="">Typography</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link p-0" data-bs-toggle="collapse" to="" aria-expanded="false" aria-controls="ui-basic">
                                        <i class="mdi mdi-crosshairs-gps menu-icon"></i>
                                        <span class="menu-title text-muted fs-6">Internal Medicine</span>
                                        <i class="menu-arrow"></i>
                                        <span className='branch'>Cairo</span>
                                    </Link>
                                    <div class="collapse" id="ui-basic">
                                        <ul class="nav flex-column sub-menu">
                                            <li class="nav-item">
                                                <Link class="nav-link" to="">Dr. Mohamed Samy</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" to="">22 - 10 - 2023</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" to="">Typography</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link p-0" data-bs-toggle="collapse" to="" aria-expanded="false" aria-controls="ui-basic">
                                        <i class="mdi mdi-crosshairs-gps menu-icon"></i>
                                        <span class="menu-title text-muted fs-6">Internal Medicine</span>
                                        <i class="menu-arrow"></i>
                                        <span className='branch'>Alex</span>
                                    </Link>
                                    <div class="collapse" id="ui-basic">
                                        <ul class="nav flex-column sub-menu">
                                            <li class="nav-item">
                                                <Link class="nav-link" to="">Dr. Mohamed Samy</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" to="">22 - 10 - 2023</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" to="">Typography</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li> */}
                            </ul>
                        </nav>

                        <div className='col-md-8 col-md-7 mt-5 mt-md-0 p-0 ps-3' ref={focusedItemItemReport}>

                            <div className='d-none text-center w-100' style={{ height: '500px' }} id='pdf-view'>
                                {isLoading ? (
                                    <div className="container" style={{ width: '800px', height: '500px' }}>
                                        <div class="row justify-content-center align-items-center w-100 h-100">

                                            <div className="col-12 text-center">

                                                <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <a href={pdfData} target='_blank' className='reportPdf btn btn-sm w-100' >
                                            <i class="fa-solid fa-download fs-5 pe-1"></i>   {t('Download PDF')}
                                        </a>

                                        <Document file={pdfData} className='px-3' onLoadSuccess={onDocumentPdfLoadSuccess} >
                                            {renderPdfPages()}
                                        </Document>

                                        {/* // <iframe src={pdfData} width="800" height="500"></iframe> */}
                                    </>
                                )}
                            </div>
                            <div className='d-none text-center w-100' style={{ height: '500px' }} id='pdf-lab-view'>
                                {isLoading ? (
                                    <div className="container" style={{ width: '800px', height: '500px', background: '#e0e0e0' }} >
                                        <div class="row justify-content-center align-items-center w-100 h-100">

                                            <div className="col-12 text-center">

                                                <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (<>

                                    <a href={pdfLabData} target='_blank' className='reportPdf btn btn-sm w-100' >
                                        <i class="fa-solid fa-download fs-5 pe-1"></i>   {t('Download PDF')}
                                    </a>

                                    <Document file={pdfLabData} className='px-3' onLoadSuccess={onDocumentPdfLoadSuccess} >
                                        {renderPdfPages()}
                                    </Document>
                                </>

                                    // <iframe src={pdfLabData} width="800" height="500"></iframe>
                                )}
                            </div>
                            <div className='d-none text-center w-100' style={{ height: '500px' }} id='pdf-cardiology-view'>
                                {isLoading ? (
                                    <div className="container" style={{ width: '800px', height: '500px' }}>
                                        <div class="row justify-content-center align-items-center w-100 h-100">

                                            <div className="col-12 text-center">

                                                <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <a href={pdfCardiologyData} target='_blank' className='reportPdf btn btn-sm w-100' >
                                            <i class="fa-solid fa-download fs-5 pe-1"></i>   {t('Download PDF')}
                                        </a>

                                        <Document file={pdfCardiologyData} className='px-3' onLoadSuccess={onDocumentPdfLoadSuccess} >
                                            {renderPdfPages()}
                                        </Document>
                                        {/* <iframe src={pdfCardiologyData} width="800" height="500"></iframe> */}
                                    </>
                                )}
                            </div>
                            {isLoading ? (
                                <div className="container" style={{ width: '800px', height: '500px' }}>
                                    <div class="row justify-content-center align-items-center w-100 h-100">

                                        <div className="col-12 text-center">

                                            <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='d-none' id='pdf-radiology-view' dangerouslySetInnerHTML={{ __html: pdfRadiologyData }}>
                                    {/* <iframe src={pdfRadiologyData} width="800" height="500"></iframe> */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </> : ''}


        </section >

        <Modal show={portal} onHide={portalClose} id="patientPortalModale" backdrop="static">
            <Modal.Header closeButton className='d-flex justify-content-between align-items-center'>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title style={{ color: "#4ab242" }}>{t('Patients Portal')}</Modal.Title>

            </Modal.Header>
            <Modal.Body >


                {pinError ? <div className="portal-error text-white my-2">{t('ERROR!')} {pinError}</div> : ''}

                <Form className='row justify-content-center align-items-end flex-column' onSubmit={submitLoginForm}>
                    <Form.Group
                        className=" col-md-6 mx-auto ms-3 mb-5"
                        controlId="ControlText"
                    >

                        <Form.Control type="text" name="pin" value={pinInp} onChange={handleChange} placeholder={t("PIN Number")}
                        />

                    </Form.Group>


                    <Button className='col-md-3 me-3 btn btn-theme btn-sm appoint-btn mt-5 mt-sm-0 rounded-3 ' type="submit" >
                        {t('Next')}
                    </Button>


                </Form>



            </Modal.Body>

        </Modal>

        <Modal show={portalPin} onHide={portalPinClose} id="patientportalPinModale" backdrop="static">
            <Modal.Header closeButton className='d-flex justify-content-between align-items-center'>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title style={{ color: "#4ab242" }}>{t('Patients Portal')}</Modal.Title>

            </Modal.Header>
            <Modal.Body >

                {phoneError ? <div className="portal-error text-white my-2">{t('ERROR!')} {phoneError}</div> : ''}

                <Form className='row justify-content-center' onSubmit={submitPhoneNumberForm}>
                    <p className='text-center lead'>{t('please enter your phone number to Send SMS with Your Password')}</p>

                    <Form.Group
                        className="mb-5 col-md-12 otp-container" dir='ltr'
                        controlId="ControlText"
                    >

                        <span className="otp-input me-2">{patientPhoneFirst}</span>
                        <Form.Control className="otp-input border-bottom" placeholder='x' type="text" maxLength="1" defaultValue={formPhoneData.numX1} name="numX1" ref={input1Ref}
                            onKeyDown={(event) => handleKeyDown(event, input1Ref)}
                            onChange={handleInputChange} />
                        <Form.Control className="otp-input border-bottom" placeholder='x' type="text" maxLength="1" defaultValue={formPhoneData.numX2} name="numX2" ref={input2Ref}
                            onKeyDown={(event) => handleKeyDown(event, input2Ref)}
                            onChange={handleInputChange} />
                        <Form.Control className="otp-input border-bottom" placeholder='x' type="text" maxLength="1" defaultValue={formPhoneData.numX3} name="numX3" ref={input3Ref}
                            onKeyDown={(event) => handleKeyDown(event, input3Ref)}
                            onChange={handleInputChange} />
                        <Form.Control className="otp-input border-bottom" placeholder='x' type="text" maxLength="1" defaultValue={formPhoneData.numX4} name="numX4" ref={input4Ref}
                            onKeyDown={(event) => handleKeyDown(event, input4Ref)}
                            onChange={handleInputChange} />
                        <Form.Control type='hidden'
                            ref={combinedInputRef}
                            value={phoneNumX5Inp}
                            onChange={() => { }}
                        />

                        <span className="otp-input ms-2">{patientPhoneLast}</span>

                    </Form.Group>
                    <div className='me-3 d-flex justify-content-end'>


                        <Button className='col-md-3 btn btn-theme btn-sm appoint-btn mt-5 mt-sm-0 rounded-3' type="submit" >
                            {t('Confirm')}
                        </Button>

                    </div>

                </Form>


            </Modal.Body>

        </Modal>

        <Modal show={portalPhone} onHide={portalPhoneClose} id="patientportalPhoneModale" backdrop="static">
            <Modal.Header closeButton className='d-flex justify-content-between align-items-center'>
                <img className="" src='../../images/cropped-9952-img-32x32.jpg' alt="" />

                <Modal.Title style={{ color: "#4ab242" }}>{t('Patients Portal')}</Modal.Title>

            </Modal.Header>
            <Modal.Body >

                {/* <Form className='row justify-content-center'>

                                                        <Form.Group
                                                            className="mb-3 col-md-6"
                                                            controlId="ControlText"
                                                        >

                                                            <Form.Control type="text" placeholder="PIN Number"
                                                            />
                                                        </Form.Group>
                                                    </Form> */}
                {codeError ? <div className="portal-error text-white my-2">{t('ERROR!')} {codeError}</div> : ''}

                <Form className='row justify-content-center align-items-center flex-column' onSubmit={handleSubmit}>
                    <p className='text-center lead'>{t('please enter your Password')}</p>

                    <Form.Group
                        className="mb-5 col-md-6 ms-3"
                        controlId="ControlText"
                    >

                        <Form.Control type='text' minLength={8} maxLength={16} name="code" value={formData.code} onChange={handleCodeChange} placeholder={t('Password')}
                        />
                    </Form.Group>
                    <div className='me-3 d-flex justify-content-center align-items-center'>
                        <Button className='col-md-5 btn btn-theme btn-sm appoint-btn mt-5 mt-sm-0 rounded-3' type="submit" >
                            {t('Confirm')}
                        </Button>
                        <small className='col-md-5 text-theme small text-center text-decoration-underline mt-5 mt-sm-0 rounded-3' type="button" onClick={handleSendOTP} disabled={isSending}>
                            {isSending ? t('Sending...') : t('Forget Password')}

                        </small>

                    </div>

                </Form>


            </Modal.Body>
            {/* <Modal.Footer>
                                                    <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={portalClose}>
                                                        Close
                                                    </Button> */}
            {/* <Button className='btn btn-theme btn-sm appoint-btn mt-3 mt-sm-0 rounded-3' onClick={navbar}>
                                                        Next
                                                    </Button> */}
            {/* </Modal.Footer> */}
        </Modal>
        <PatientPortalChangePasswordModale
            show={portalChangePassword}
            onHide={handlePortalChangePasswordClose}
            changePasswordError={changePasswordError} handleChangePasswordSubmit={handleChangePasswordSubmit} oldPasswordInp={oldPasswordInp} setOldPasswordInp={setOldPasswordInp} newPasswordInp={newPasswordInp} setNewPasswordInp={setNewPasswordInp} confirmationPasswordInp={confirmationPasswordInp} setConfirmationPasswordInp={setConfirmationPasswordInp}
        // Pass other props as needed
        />

    </>
}

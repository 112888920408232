import React, { useState, useContext, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageTitle from '../../../Utilites/PageTitle';
import Select from 'react-select';
import { GetDepartmentsOptionsContext } from '../../../../Context/GetDepartmentsOptions';
import axios from 'axios';
import Pagination from 'react-js-pagination';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from '../../../../Context/LocalizationContext';


export default function Specialists() {
    // translation 
    const { t } = useTranslation("AlexSpecialists");
    let { handleLanguageChange, i18n } = useContext(LocalizationContext)
    // translation
    const { pathname } = useLocation();
    let { GetDepartmentsSelectOptions, DepartmentsOptions } = useContext(GetDepartmentsOptionsContext);
    function scrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    const ClinicOptions = [

        { value: 'Anesthesia', label: 'Anesthesia', style: { backgroundColor: 'red' } },
        { value: 'Cardiology', label: 'Cardiology' },
        { value: 'Chest and Pulmonology', label: 'Chest and Pulmonology' },
        { value: 'Cardiothoracic Surgery', label: 'Cardiothoracic Surgery' },
        { value: 'Dental and Maxillofacial', label: 'Dental and Maxillofacial' },
        { value: 'Dermatology', label: 'Dermatology' },
        { value: 'Diabetes and Endocrinologys', label: 'Diabetes and Endocrinologys' },
        { value: 'General surgery', label: 'General surgery' },
        { value: 'Gastroenterology and Hepatology', label: 'Gastroenterology and Hepatology' },
        { value: 'Obstetrics and Gynecology', label: 'Obstetrics and Gynecology' },
        { value: 'Internal Medicine', label: 'Internal Medicine' },
        { value: 'Kidney Transplantation', label: 'Kidney Transplantation' },
        { value: 'Laboratory', label: 'Laboratory' },
        { value: 'Liver Transplantation', label: 'Liver Transplantation' },
        { value: 'Neurology', label: 'Neurology' },
        { value: 'Nephrology', label: 'Nephrology' },
        { value: 'Ophthalmology', label: 'Ophthalmology' },
        { value: 'Orthopedic', label: 'Orthopedic' },
        { value: 'Pain Management', label: 'Pain Management' },
        { value: 'Pediatric', label: 'Pediatric' },
        { value: 'Physiotherapy Department', label: 'Physiotherapy Department' },
        { value: 'Psychiatry', label: 'Psychiatry' },
        { value: 'Radiology', label: 'Radiology' },
        { value: 'Radiotherapy', label: 'Radiotherapy' },
        { value: 'Urology', label: 'Urology' },
        { value: 'Vascular Surgery', label: 'Vascular Surgery' },
    ]
    // const [allSpecialities, setAllSpecialities] = useState([
    //     { icon: '/images/service/anesthesia.png', img: '/images/service/ANESTHESIA.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Anesthesia', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/heart-small.png', img: '/images/service/cardiology.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/cardiology`, name: 'Cardiology', desc: 'To diagnose and treat cardiac conditions efficiently, SGH-Alex offers the most comprehensive range of state-of-art ' },
    //     { icon: '/images/service/pulmonology.png', img: '/images/service/Chest.webp', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/chest-and-pulmonology`, name: 'Chest and Pulmonology', desc: 'At Saudi German Hospital Alex, we recognize the paramount importance of breathing in sustaining human life.' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Cardiothoracic Surgery', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Dental and Maxillofacial', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Dermatology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Diabetes and Endocrinologys', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'General surgery', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Gastroenterology and Hepatology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Obstetrics and Gynecology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Internal Medicine', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Kidney Transplantation', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Laboratory', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Liver Transplantation', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Neurology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Nephrology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Ophthalmology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Orthopedic', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Pain Management', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Pediatric', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Physiotherapy Department', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Psychiatry', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Radiology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Radiotherapy', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Urology', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    //     { icon: '/images/service/anesthesia.png', img: '/images/bg/01.jpg', link: `${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`, name: 'Vascular Surgery', desc: 'The department of anesthesia provides state-of-the-art care for patients undergoing a variety of surgical procedure' },
    // ])


    const [visible, setVisible] = useState(6);
    const options = {
        margin: 30,
        responsiveClass: true,
        autoplay: true,
        smartSpeed: 1000,


    }

    function loadMore() {
        setVisible(visible + 6);
    }

    // const [searchData, setSearchData] = useState([]);
    // const [search, setSearch] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [searchResults, setSearchResults] = useState([]);



    const handleInputChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        performSearch(selectedOption);
    };

    const performSearch = (selectedOption) => {
        if (selectedOption) {
            const filteredResults = allSpecialities.filter(
                (specialities) => specialities.name === selectedOption.label
            );
            setSearchResults(filteredResults);
            document.querySelector('.specialities').classList.add('d-none');
        } else {
            setSearchResults([]);
            document.querySelector('.result').classList.add('d-none');

        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Backspace') {
            setSelectedOption(null);
            setSearchResults([]);
            document.querySelector('.specialities').classList.remove('d-none');

        }
    };

    useEffect(() => {

        GetDepartmentsSelectOptions()
    }, [])

    let [lastPageOfSearch, setLastPageOfSearch] = useState()

    let [perPage, setPerPage] = useState();
    let [total, setTotal] = useState();
    let [currentPage, setCurrentPage] = useState(1);
    const location = useLocation();
    const navigate = useNavigate();

    // Parse query parameter `page` from URL
    const queryParams = new URLSearchParams(location.search);
    const pageFromUrl = queryParams.get('page');
    useEffect(() => {
        if (pageFromUrl) {
            setCurrentPage(Number(pageFromUrl)); // Update currentPage based on query parameter
        }
    }, [location.search]); // Re-run the effect if the URL changes


    const handlePageChange = (pageNumber) => {
        // const pageNumber = e;

        setCurrentPage(pageNumber);
        navigate(`?page=${pageNumber}`); // Update URL to reflect page change
        scrollToTop();
    };
    // get department
    const [allSpecialities, setAllSpecialities] = useState([]);
    async function getSpecialities(hospital_id, currentPage) {
        const bodyFormData = new FormData();
        // Append data to the FormData object
        // bodyFormData.append('pin', pinNumInp);
        // bodyFormData.append('phone', phoneNumInp);
        const headers = {
            'lang': Cookies.get('i18next'),
            'fingerprint': localStorage.getItem('fingerPrint'),
        }

        const response = await axios.get(`http://130.7.1.27/mayo/public/department/list/${Cookies.get('i18next')}?hospital_id=${hospital_id}&page=${currentPage}`)
            .then(response => {
                // Handle the response
                if (response.data.Ecode === 200) {
                    setAllSpecialities(response.data.Edata.data);
                    setPerPage(response.data.Edata.per_page);
                    setTotal(response.data.Edata.total);
                    setLastPageOfSearch(response.data.Edata.last_page)
                    console.log('all speciality ' + response.data.Edata.data);


                }

            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }
    useEffect(() => {
        getSpecialities('2', currentPage);
    }, [currentPage])

    return <>
        {/* <!--page title start--> */}
        {i18n.language === 'en' ? <PageTitle title={t('Our')} anotherTitle={t('Specialties')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex`} prevPage={t('Alexandria')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties`} currentPage={t('Our Specialties')} /> : <PageTitle title={t('Our Specialties')} prevLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex`} prevPage={t('Alexandria')} currentLink={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties`} currentPage={t('Our Specialties')} />}

        {/* <!--page title end--> */}

        <div class="page-content">

            {/* <!--featured start--> */}

            {/* <!--pricing table style2 start--> */}

            <section>
                <div class="container">
                    {/* <div className="row jusify-content-center mb-5 pb-5">
                        <div className="col-md-6 mx-auto mb-5 theme-bg rounded-top-lg">
                            <div className="rounded-top-lg">
                                <div class="section-title m-0 p-3">
                                    <h6 class="h5 text-white"><span>Search By Department</span></h6>
                                </div>
                                <div className="row">
                                    <div className="messages"></div>

                                    <div className="row justify-content-center align-items-center">
                                        <div className="form-group col-sm-6" style={{ zIndex: 999 }}  >


                                            <Select placeholder="Select Department" value={selectedOption}
                                                onChange={handleInputChange} options={DepartmentsOptions} onKeyDown={handleKeyDown} styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: state.isFocused ? ' #4ab242' : ' hsl(0, 0%, 80%)',

                                                        outline: state.isFocused ? ' none' : 'none',
                                                    }),
                                                    option: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        backgroundColor: state.isFocused ? '#4ab242' : ' ',
                                                        color: state.isFocused ? '#fff' : '',

                                                    }),
                                                }} style={{ borderTop: "none", borderRight: "none", borderLeft: "none", }}>

                                            </Select>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div class="row gy-5 result">
                        {searchResults.map((speciality, index) => (
                            <div className='col-lg-4 col-md-4' key={index}>
                                <div class="price-table style-2 active" data-bg-img={speciality.img} style={{ backgroundImage: `url(${speciality.img})` }}>
                                    <div class="price-inner">
                                        <div class="featured-item text-center">


                                            <img src={speciality.icon} alt="icon" />

                                        </div>
                                        <div class="price-header">
                                            <h4 class="price-title">{speciality.name}</h4>
                                        </div>


                                        <div class="price-list">
                                            <ul class="list-unstyled">
                                                <p>{speciality.desc}</p>
                                            </ul>
                                        </div>
                                        <Link onClick={scrollToTop} to={speciality.link} className='btn btn-block btn-theme mt-4'>
                                            <span>Read More</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> */}


                    <div className="row specialities justify-content-center gy-3">
                        {allSpecialities.map((speciality, index) => (
                            <div className='col-lg-4 col-md-4' key={index}>
                                <div class="price-table style-2 active" data-bg-img='/images/cairo/specialitites/Specialties-for-all---min.webp' style={{ backgroundImage: `url('/images/cairo/specialitites/Specialties-for-all---min.webp')` }}>

                                    <div class="price-inner bg-transparent">
                                        <div class="featured-item text-center">


                                            {speciality.icon ? <img src={speciality.icon} alt="icon" height='65px' /> : <img src='/images/service/anesthesia.png' alt="icon" height='65px' />}


                                        </div>
                                        <div class="price-header">
                                            <h4 class="price-title">{speciality.famous_name}</h4>
                                        </div>


                                        <div class="price-list" style={{ aspectRatio: 5 / 2 }} >
                                            <ul class="list-unstyled">
                                                <p dangerouslySetInnerHTML={{ __html: speciality.brief.replace(/^(.{200}\S*).*$/, '$1') }}></p>
                                            </ul>
                                        </div>
                                        <Link onClick={scrollToTop} to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/${speciality.famous_name}/${speciality.id}`} className='btn btn-block btn-theme mt-4'>
                                            <span>{t('Read More')}</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>


                    {lastPageOfSearch > 1 ? <>
                        <div className="row justify-content-center text-center mt-3" id='pagination'>
                            < Pagination
                                activePage={currentPage}
                                itemsCountPerPage={perPage}
                                totalItemsCount={total}
                                pageRangeDisplayed={5}
                                hideLastPages={true}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div></> : ''}
                </div>
            </section >

            {/* <!--pricing table style2 end--> */}
            {/* <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/alex/specialties/anesthesia`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Anesthesia</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Cardiology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-drug"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Chest and Pulmonology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-blood-donation"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Cardiothoracic Surgery</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Dental and Maxillofacial</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-ambulance"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Dermatology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Diabetes and Endocrinology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>General surgery</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Gastroenterology and Hepatology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-ambulance"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Obstetrics and Gynecology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Internal Medicine</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Kidney Transplantation</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Laboratory</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-ambulance"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Liver Transplantation</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Neurology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Nephrology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Ophthalmology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-ambulance"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Orthopedic</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Pain Management</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Pediatric</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Physiotherapy Department
                                        </h5>
                                    </div>
                               
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-ambulance"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Psychiatry</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i className='flaticon-stethoscope'></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Radiology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor-1"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Radiotherapy</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-doctor"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Urology</h5>
                                    </div>
                                 
                                </div>
                            </Link>
                        </div>
                        <div class="col-lg-3 col-md-6 mt-5">
                            <Link to={`${pathname.split('/')[0]}/${Cookies.get('i18next')}/`}>
                                <div class="featured-item text-center bg-light py-5">
                                    <div class="featured-icon"> <i class="flaticon-ambulance"></i>
                                    </div>
                                    <div class="featured-title text-uppercase">
                                        <h5>Vascular Surgery
                                        </h5>
                                    </div>
                                 

                                </div>
                            </Link>
                        </div>

                    </div>

                </div>
            </section> */}

            {/* <!--featured end--> */}
        </div >
    </>
}
